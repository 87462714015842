import { createTheme, ThemeOptions } from '@material-ui/core/styles';
import { PaletteOptions } from '@material-ui/core/styles/createPalette';
import { DarkPalette } from './Palette';
import HelveticaWoff2Thin from '../assets/fonts/HelveticaNeueLTW01-35Thin.woff2';
import HelveticaWoff2Light from '../assets/fonts/HelveticaNeueLTW01-45Light.woff2';
import HelveticaWoff2Regular from '../assets/fonts/HelveticaNeueLTW01-55Roman.woff2';
import HelveticaWoff2Italic from '../assets/fonts/HelveticaNeueLTW01-56Italic.woff2';
import HelveticaWoff2Medium from '../assets/fonts/HelveticaNeueLTW01-65Medium.woff2';
import HelveticaWoff2Bold from '../assets/fonts/HelveticaNeueLTW01-75Bold.woff2';

const helveticaThin = {
  fontFamily: 'Helvetica Neue Thin',
  fontStyle: 'normal',
  fontWeight: 'normal',
  src: `
    local('Helvetica Neue Thin'),
    local('HelveticaNeue-Thin'),
    url(${HelveticaWoff2Thin}) format('woff2')
  `,
};
const helveticaLight = {
  fontFamily: 'Helvetica Neue Light',
  fontStyle: 'normal',
  fontWeight: 'normal',
  src: `
    local('Helvetica Neue Light'),
    local('HelveticaNeue-Light'),
    url(${HelveticaWoff2Light}) format('woff2')
  `,
};
const helveticaRegular = {
  fontFamily: 'Helvetica Neue Regular',
  fontStyle: 'normal',
  fontWeight: 'normal',
  src: `
    local('Helvetica Neue Regular'),
    local('HelveticaNeue-Regular'),
    url(${HelveticaWoff2Regular}) format('woff2')
  `,
};
const helveticaItalic = {
  fontFamily: 'Helvetica Neue Italic',
  fontStyle: 'normal',
  fontWeight: 'normal',
  src: `
    local('Helvetica Neue Italic'),
    local('HelveticaNeue-Italic'),
    url(${HelveticaWoff2Italic}) format('woff2')
  `,
};
const helveticaMedium = {
  fontFamily: 'Helvetica Neue Medium',
  fontStyle: 'normal',
  fontWeight: 'normal',
  src: `
    local('Helvetica Neue Medium'),
    local('HelveticaNeue-Medium'),
    url(${HelveticaWoff2Medium}) format('woff2')
  `,
};
const helveticaBold = {
  fontFamily: 'Helvetica Neue Bold',
  fontStyle: 'normal',
  fontWeight: 'normal',
  src: `
    local('Helvetica Neue Bold'),
    local('HelveticaNeue-Bold'),
    url(${HelveticaWoff2Bold}) format('woff2')
  `,
};
const defaultFlsOptions = (palette: PaletteOptions): ThemeOptions => {
  return createTheme({
    palette,
    typography: {
      fontSize: 16,
      fontFamily:
        'Helvetica Neue Thin, Helvetica Neue Light, Helvetica Neue Regular, Helvetica Neue Italic, Helvetica Neue Medium, Helvetica Neue Bold, sans-serif',
      //Framo large text
      h1: {
        fontFamily: 'Helvetica Neue Thin',
        fontSize: '2.5rem',
        lineHeight: '3rem',
      },
      h2: {
        fontFamily: 'Helvetica Neue Thin',
        fontSize: '2rem',
        lineHeight: '2.5rem',
      },
      //Framo body text
      body1: {
        fontFamily: 'Helvetica Neue Light',
        fontStyle: 'normal',
        fontSize: '1rem',
        lineHeight: '1.4rem',
      },

      //Framo body highlight text
      body2: {
        fontFamily: 'Helvetica Neue Medium',
        fontStyle: 'normal',
        fontSize: '1rem',
        lineHeight: '1.5rem',
      },
      //Framo detail text
      subtitle1: {
        fontFamily: 'Helvetica Neue Thin',
        fontSize: '0.875rem',
        lineHeight: '1.3rem',
      },
      //Framo label text
      subtitle2: {
        fontFamily: 'Helvetica Neue Medium',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '11px',
        lineHeight: '16px',
        textTransform: 'uppercase',
      },
    },
    props: {
      MuiButtonBase: {
        disableRipple: true,
      },
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '@font-face': [
            helveticaThin,
            helveticaLight,
            helveticaRegular,
            helveticaItalic,
            helveticaMedium,
            helveticaBold,
          ],
          html: {},
          body: {
            height: 'default',
          },
          '.MuiGridPanelFooter-root': {
            display: 'none!important',
          },
          '.MuiGridPanelContent-root': {
            '&::-webkit-scrollbar': {
              backgroundColor: ` ${palette.background?.default || ''} `,
              width: '8px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: ` ${palette.button?.normalBgd || ''} `,
              margin: '1px',
              borderRadius: '6px',
            },
          },

          '*': {
            scrollbarWidth: 'thin',
            scrollbarColor: ` ${palette.background?.paper || ''} ${
              palette.background?.default || ''
            }`,
            '&::-webkit-scrollbar': {
              backgroundColor: palette.background?.default,
              width: '8px',
              border: `1px solid ${palette.background?.paper}`,
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: palette.background?.paper,
              margin: '1px',
              borderRadius: '6px',
            },
          },
        },
      },
      MuiIconButton: {
        root: {
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
      MuiTab: {
        root: {
          fontFamily: 'Helvetica Neue Medium',
          fontStyle: 'normal',
          fontSize: '1rem',
          lineHeight: '1.5rem',
          fontWeight: 400,
        },
      },
      MuiAppBar: {
        colorPrimary: {
          backgroundColor: palette.background?.paper,
        },
      },
      MuiDivider: {
        root: {
          backgroundColor: palette.divider,
          marginTop: 1,
        },
      },
    },
  });
};
export const DarkTheme = defaultFlsOptions(DarkPalette);
