import React, { useState, useEffect } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { Formik, setNestedObjectValues, useFormikContext } from 'formik';
import { SamplePayload, useApiClient } from '../../api';
import { useSampleContext, useVesselContext } from '../../contexts';
import * as Yup from 'yup';
import { useLab, useListPreValues, useLoading } from '../../hooks';
import { LoadingActions, State } from '../../hooks/useLoading';
import { Actionbar, CreateSampleSidebar, SampleFormFields } from './pageComponents';
import { FlsModal } from '../../components/modal';
import { isEmpty } from 'ramda';
import { makeInputFieldValueNullIfEmpty } from '../../utils/inputfieldUtils';

const CustomerAndSampleInformationSchema = Yup.object().shape({
  expeditionDate: Yup.date().nullable().required('Required!').typeError('Invalid Format!'),
  sampleDate: Yup.date().nullable().typeError('Invalid Format!'),
  lastFilterChange: Yup.date().nullable().typeError('Invalid Format!'),
  installationName: Yup.string().required('Required!').typeError('Required!'),
  customerReference: Yup.string().nullable().min(2, 'Too Short!').max(30, 'Too Long!'),
  framoReference: Yup.string().nullable().min(2, 'Too Short!').max(30, 'Too Long!'),
  samplePurpose: Yup.string().nullable().nullable().required('Required!'),
  sampledBy: Yup.string().nullable().required('Required!'),
  samplePoint: Yup.string().nullable().required('Required!'),
  oilType: Yup.string().nullable().required('Required!'),
  imoNumber: Yup.string()
    .nullable()
    .length(7, 'Must be 7 digits')
    .matches(/^\d+$/, 'Can only contain digits!'),
  internalInvoiceOffice: Yup.string().nullable().required('Required!'),
  internalInvoiceType: Yup.string().nullable().required('Required!'),
  hpuSystem: Yup.string().nullable().required('Required!'),
  analysedBy: Yup.string().nullable().required('Required!'),
});

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(6),
    paddingTop: theme.spacing(3),
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  formContent: {
    maxWidth: '1824px',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: { flexDirection: 'column' },
  },
}));

const CreateSample = () => {
  const client = useApiClient();
  const { state: stateOnSave, dispatch: dispatchOnSave } = useLoading();
  const { fetchSample } = useSampleContext();
  const [allowOverwrite, setAllowOverwrite] = useState<boolean>(false);
  const history = useHistory();
  const { labId, lab } = useLab();

  return (
    <Formik
      initialValues={{
        id: undefined,
        shortId: undefined,
        externalId: undefined,
        labId: labId,
        ompDocNr: null,
        ompDocRno: 0,
        expeditionDate: null,
        customerReference: null,
        framoReference: null,
        samplePurpose: 'Oil Monitoring Program',
        sampledBy: 'Crew',
        samplePoint: 'Framo sample point',
        sampleDate: null,
        originalOilType: null,
        oilType: null,
        lastFilterChange: null,
        sampleBottleType: true,
        imoNumber: null,
        installationName: null,
        installationId: null,
        owner: null,
        manager: null,
        customer: null,
        framoSalesOrderNumber: null,
        internalInvoiceOffice: lab?.shortLabCode || 'NL',
        internalInvoiceType: 'L1',
        projectEngineer: null,
        hpuSystem: 'N/A',
        analysedBy: lab?.labCode || 'FLS',
        invalidSample: false,
        state: 'InProgress',
        flaggedForExternalTesting: false,
        forms: [],
      }}
      validationSchema={CustomerAndSampleInformationSchema}
      onSubmit={async (values: SamplePayload, { resetForm }) => {
        values.imoNumber = makeInputFieldValueNullIfEmpty(values.imoNumber);
        if (values.id) {
          try {
            await client.putSample(values);
            await fetchSample();
            setAllowOverwrite(false);
            dispatchOnSave({ type: 'SUCCESS' });
          } catch (error) {
            dispatchOnSave({
              type: 'ERROR',
              error: error?.message || 'Something went wrong',
              toast: 'Failed to insert values to database.',
            });
          }
        } else {
          try {
            const result = await client.postSample(values);
            dispatchOnSave({ type: 'SUCCESS' });
            setAllowOverwrite(false);
            history.push('/createSample/' + (result as any).id);
          } catch (error) {
            dispatchOnSave({
              type: 'ERROR',
              error: error?.message || 'Something went wrong',
              toast: 'Failed to insert values to database.',
            });
          }
        }
      }}
    >
      <InnerCreateSample
        stateOnSave={stateOnSave}
        dispatchOnSave={dispatchOnSave}
        allowOverwrite={allowOverwrite}
        setAllowOverwrite={setAllowOverwrite}
      />
    </Formik>
  );
};

interface InnerCreateSampleProps {
  stateOnSave: State<unknown>;
  dispatchOnSave: React.Dispatch<LoadingActions<unknown>>;
  allowOverwrite: boolean;
  setAllowOverwrite: React.Dispatch<React.SetStateAction<boolean>>;
}

const InnerCreateSample = (props: InnerCreateSampleProps) => {
  const { stateOnSave, dispatchOnSave, allowOverwrite, setAllowOverwrite } = props;
  const classes = useStyles();
  const { sample, setSampleId, clearSample } = useSampleContext();
  const { preValues } = useListPreValues();
  const { vessel, removeSelectedVessel } = useVesselContext();
  const { urlSampleId } = useParams<{ urlSampleId: string }>();
  const history = useHistory();
  const { lab } = useLab();
  const formik = useFormikContext<SamplePayload>();

  React.useEffect(() => {
    if (urlSampleId) {
      setSampleId(urlSampleId);
    } else {
      setAllowOverwrite(true);
    }
  }, [urlSampleId]);

  useEffect(() => {
    if (lab && sample && !sample?.ompDocNr) {
      formik.setFieldValue('analysedBy', lab.labCode);
      formik.setFieldValue('internalInvoiceOffice', lab.shortLabCode);
    }
  }, [lab?.labCode, sample?.ompDocNr]);

  React.useEffect(() => {
    if (sample) {
      Object.entries(sample).forEach(([key, value]) => {
        if (formik.values.hasOwnProperty(key)) {
          formik.setFieldValue(key, value);
        }
      });
    }
  }, [sample]);

  React.useEffect(() => {
    let canOverwrite = allowOverwrite;
    if (sample && !sample.installationId && !isEmpty(vessel)) {
      canOverwrite = true;
      setAllowOverwrite(true);
    }
    if (!isEmpty(vessel) && canOverwrite) {
      Object.entries(vessel).forEach(([key, value]) => {
        if (!vessel.partOfOmp && key === 'internalInvoiceOffice') {
          return;
        }
        if (formik.values.hasOwnProperty(key)) {
          formik.setFieldValue(key, value);
        } else {
          const existIfValid = formikMapper(key);
          if (existIfValid) {
            if (existIfValid === 'installationId') {
              formik.setFieldValue('framoSalesOrderNumber', value);
            }
            formik.setFieldValue(existIfValid, value);
          }
        }
      });
    } else {
      if (!sample?.id) {
        //If if no vessel is present or unselected, and sample is not registered, sever OMP connection to vessel without removing vessel info.
        formik.setFieldValue('installationId', null);
      }
    }
  }, [vessel]);

  const formikMapper = (key: string) => {
    switch (key) {
      case 'imo':
        return 'imoNumber';
      case 'name':
        return 'installationName';
      case 'orderNo':
        return 'installationId';
      default:
        return null;
    }
  };

  const handleSubmission = async (ev: any) => {
    dispatchOnSave({ type: 'REQUEST' });
    const validationErrors = await formik.validateForm();
    if (isEmpty(validationErrors)) {
      formik.handleSubmit(ev);
    } else {
      console.log(validationErrors);
      formik.setErrors(validationErrors);
      formik.setTouched(setNestedObjectValues(validationErrors, true));
      dispatchOnSave({
        type: 'ERROR',
        error: 'Validation Error',
        toast: 'Some fields have failed validation, action aborted',
      });
    }
  };

  const resetFields = () => {
    clearSample();
    setAllowOverwrite(true);
    setSampleId(undefined);
    removeSelectedVessel();
    formik.resetForm();
    history.push('/createSample');
  };

  const copyFieldsOverToNewSample = () => {
    clearSample();
    setAllowOverwrite(true);
    setSampleId(undefined);
    formik.setFieldValue('id', undefined);
    formik.setFieldValue('shortId', undefined);
    formik.setFieldValue('externalId', undefined);
    formik.setFieldValue('state', undefined);
    formik.setFieldValue('ompDocNr', null);
    formik.setFieldValue('ompDocRno', 0);
    history.push('/createSample');
  };

  return (
    <div className={classes.root}>
      <form
        className={classes.formContent}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Actionbar
              formik={formik}
              submissionAction={handleSubmission}
              saveState={stateOnSave?.state || ''}
              copyFieldsOverToNewSample={copyFieldsOverToNewSample}
              resetFields={resetFields}
              disabled={sample?.revised}
            />
          </Grid>
          <Grid item xs={12}>
            <div className={classes.contentContainer}>
              <CreateSampleSidebar
                formik={formik}
                setVesselOverwrite={setAllowOverwrite}
                disabled={sample?.revised}
              />
              <SampleFormFields formik={formik} preValues={preValues} disabled={sample?.revised} />
            </div>
          </Grid>
        </Grid>
        <FlsModal />
      </form>
    </div>
  );
};

export default CreateSample;
