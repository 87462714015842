import React, { useState } from 'react';
import {
  GridColDef,
  GridValueFormatterParams,
  GridValueGetterParams,
  GridCellParams,
} from '@material-ui/data-grid';
import { DatagridToolbar, Loadingbar, StatusCell } from '../../../components/datagrid';
import { FormStatus, ProcessStatusEnum } from '../../../api';
import dateFormat from '../../../utils/dateFormat';
import RowNavigationButtons from './RowNavigationButtons';
import FormStatusCell from '../../../components/datagrid/FormStatusCell';
import ProcessStatusCell from '../../../components/datagrid/ProcessStatusCell';
import { SampleDataGrid } from '../../../hooks/useListSample';
import FlsDataGrid from '../../../components/datagrid/FlsDataGrid';
import RowShortId from './RowShortId';

const inProgressColumns: GridColDef[] = [
  {
    field: 'expeditionDate',
    type: 'string',
    headerName: 'Expedition Date',
    flex: 0.5,
    minWidth: 84,
    filterable: false,
    valueFormatter: (params: GridValueFormatterParams) => {
      return dateFormat.formatDateShortFormat(params.value as string);
    },
  },
  {
    field: 'sampleDate',
    type: 'string',
    headerName: 'Sample Date',
    flex: 0.5,
    minWidth: 84,
    filterable: false,
    valueFormatter: (params: GridValueFormatterParams) => {
      return dateFormat.formatDateShortFormat(params.value as string);
    },
  },
  {
    hide: false,
    field: 'shortId',
    headerName: 'ID',
    flex: 0.4,
    renderCell: RowShortId,
    minWidth: 145,
    filterable: false,
  },
  {
    field: 'ompDocNr',
    type: 'string',
    headerName: 'Omp Document Nr.',
    filterable: false,
    minWidth: 180,
    hide: true,
    sortable: false,
  },
  {
    field: 'installationName',
    headerName: 'Installation',
    flex: 0.75,
    minWidth: 130,
    filterable: false,
  },
  {
    field: 'samplePurpose',
    type: 'string',
    headerName: 'Sample Purpose',
    filterable: false,
    hide: true,
    sortable: false,
  },
  {
    field: 'framoSalesOrderNumber',
    type: 'string',
    headerName: 'Sales Order Nr.',
    filterable: false,
    minWidth: 110,
    hide: true,
    sortable: false,
  },
  {
    field: 'internalInvoiceOffice',
    type: 'string',
    headerName: 'Internal I. Office',
    filterable: false,
    hide: true,
    sortable: false,
  },
  {
    field: 'internalInvoiceType',
    type: 'string',
    headerName: 'Internal I. Type',
    filterable: false,
    hide: true,
    sortable: false,
  },
  {
    field: 'partofOmp',
    type: 'string',
    headerName: 'Part of OMP',
    filterable: false,
    hide: true,
    sortable: false,
    valueFormatter: (params: GridValueFormatterParams) => {
      return params.value ? 'Yes' : 'No';
    },
  },
  {
    field: 'forms',
    headerName: 'Analysis Status',
    renderCell: FormStatusCell,
    filterable: false,
    disableReorder: true,
    sortable: false,
    hideSortIcons: true,
    flex: 0.8,
    minWidth: 246,
  },
  {
    field: 'overallFormStatus',
    headerName: 'Oil Condition',
    // eslint-disable-next-line react/display-name
    renderCell: (params: GridCellParams) => {
      const value = (params.row.overallFormStatus || 'Normal') as FormStatus;
      return <StatusCell label={value} status={value as FormStatus} showIcon={false} />;
    },
    flex: 0.4,
    minWidth: 100,
    filterable: false,
    disableReorder: true,
    sortable: true,
  },
  {
    field: 'purchaseOrder',
    headerName: 'Ext Lab PO',
    filterable: false,
    sortable: true,
    hide: true,
    valueGetter: (params: GridValueGetterParams) => {
      return params.row.externalLabSample?.purchaseOrder ?? '';
    },
  },
  {
    field: 'processState',
    headerName: 'Process Status',
    renderCell: ProcessStatusCell,
    valueGetter: (params: GridValueGetterParams) => {
      const sentToExternalLab = params.row.isSentToExternalLab as boolean;
      if (sentToExternalLab) {
        return ProcessStatusEnum[ProcessStatusEnum.SentToExternalTest];
      }
      return params.row.state as boolean;
    },
    flex: 0.6,
    minWidth: 161,
    filterable: false,
    disableReorder: true,
    disableColumnMenu: true,
    sortable: true,
  },
  {
    field: '',
    headerName: 'Actions',
    sortable: false,
    renderCell: RowNavigationButtons,
    editable: false,
    width: 230,
    resizable: false,
    filterable: false,
    disableColumnMenu: true,
    disableReorder: true,
    hideSortIcons: true,
  },
];

interface Props {
  datagrid: SampleDataGrid;
  onSearchChange: (searchString: string) => void;
  searchQuery: string;
}

const LOCAL_STORAGE_PROGRESS_KEY = 'in-progress-hide';

export default function DatagridInProgress(props: Props) {
  const {
    response,
    selectionModel,
    setSelectionModel,
    sortModel,
    setSortModel,
    pageSize,
    setPageSize,
    setPageNumber,
    totalEntries,
  } = props.datagrid;

  const [visibilityModel, setVisibilityModel] = useState<any>();
  const [columns, setColumns] = useState<GridColDef[]>(inProgressColumns);
  const [columnsButtonEl, setColumnsButtonEl] = React.useState(null);

  React.useEffect(() => {
    const storedData = localStorage.getItem(LOCAL_STORAGE_PROGRESS_KEY);
    let userVisibilityModel = {};
    if (storedData) {
      userVisibilityModel = JSON.parse(storedData);
      const updatedColumns = inProgressColumns.map((entry) => {
        if (userVisibilityModel.hasOwnProperty(entry.field)) {
          entry.hide = userVisibilityModel[entry.field];
        }
        return entry;
      });
      setVisibilityModel(userVisibilityModel);
      setColumns(updatedColumns);
    }
  }, []);

  const updateVisibilityModel = (fieldName: string, hideStatus: boolean) => {
    let updatedModel = visibilityModel;
    if (!updatedModel) {
      updatedModel = {};
    }
    updatedModel[fieldName] = hideStatus;
    const dataToStore = JSON.stringify(updatedModel);
    localStorage.setItem(LOCAL_STORAGE_PROGRESS_KEY, dataToStore);
    setVisibilityModel(updatedModel);
  };

  return (
    <FlsDataGrid
      onColumnVisibilityChange={(params) => {
        updateVisibilityModel(params.colDef.field, !params.isVisible);
      }}
      rows={response.data ? response.data : []}
      columns={columns}
      pagination
      pageSize={pageSize}
      rowCount={totalEntries}
      rowsPerPageOptions={[20, 50, 100]}
      onPageChange={(page: number) => setPageNumber(page + 1)}
      onPageSizeChange={(pageSize: number) => setPageSize(pageSize)}
      paginationMode='server'
      sortingMode='server'
      sortModel={sortModel}
      onSortModelChange={(model) => setSortModel(model)}
      loading={response.loading}
      error={response.error}
      checkboxSelection
      selectionModel={selectionModel}
      disableSelectionOnClick
      onSelectionModelChange={(newSelectionModel) => {
        setSelectionModel(newSelectionModel);
      }}
      components={{
        Toolbar: DatagridToolbar,
        LoadingOverlay: Loadingbar,
      }}
      componentsProps={{
        toolbar: {
          onSearchChange: props.onSearchChange,
          searchQuery: props.searchQuery,
          addNewLink: '/createSample',
          searchPlaceholder: 'Search by ID or Installation...',
          selectedSampleIds: Object.values(selectionModel).map(String),
          setColumnsButtonEl: setColumnsButtonEl,
        },
        panel: {
          anchorEl: columnsButtonEl,
        },
      }}
    />
  );
}
