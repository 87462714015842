import {
  Sample,
  Form,
  Lab,
  Record as FlsRecord,
  ListSampleResponse,
  SamplePayload,
  RetrievedRecord,
  WaterBulkPayload,
  ExternalTests,
  RegisterExternalTestsRequest,
  CalendarResponse,
  OrderExternalTestsRequest,
  FlsFile,
  JsonPatch,
  LatestRecordForSamplesPayload,
  LatestRecordForSample,
  PreValue,
  UpdateSampleFormRequest,
  VesselSampleInfo,
  VesselSummary,
  FinalizationRequest,
  PreValueTypes,
  ExternalLabOrderData,
  FormResultBuilder,
  FormFieldValueBuilder,
  User,
  Classifier,
  FormFieldValidationRule,
  LabPayload,
  ExternalLab,
  ClassThreshold,
  FormField,
  FormFieldPreValues,
  FormFieldValidationRulePayload,
  PagedUsersResponse,
  UserPayload,
  FormFieldPreValuesPayload,
  LatestOilTypeResponse,
  GetSamplesRequest,
} from '.';
import { HttpClient } from './useHttpClient';

export const createApiClient = (httpClient: HttpClient) => ({
  getSampleById: (id: string) => httpClient.current.get<Sample>(`v1/FlsSamples/${id}`),
  getSampleByIds: (request: GetSamplesRequest) =>
    httpClient.current.post<Sample[]>(`v1/FlsSamples/getByIds`, request),

  markDeletedSampleById: (id: string) =>
    httpClient.current.delete(`v1/FlsSamples/${id}/markdeleted`),

  unArchiveSampleById: (id: string) => httpClient.current.put(`v1/FlsSamples/${id}/unarchive`, {}),

  reviseSampleById: (sampleId: string) =>
    httpClient.current.post<Sample>(`v1/FlsSamples/revise`, { sampleId }),

  postSample: (sample: SamplePayload) => httpClient.current.post('v1/FlsSamples', sample),

  putSample: (sample: SamplePayload) => httpClient.current.put('v1/FlsSamples', sample),

  listSampleByLab: (
    labId: string,
    page: number,
    pageSize: number,
    currentPanel: 'InProgress' | 'Finalized' | 'SentToExternalLab',
    sortBy?: string | null,
    sortDirection?: string | null,
    filterValue?: string | null,
  ) =>
    httpClient.current.get<ListSampleResponse>(
      `v1/FlsSamples/${labId}/getByLab?page=${page}&pageSize=${pageSize}
      &sortBy=${sortBy}&sortDirection=${sortDirection}&filterValue=${filterValue}&currentPanel=${currentPanel}`,
    ),

  listCreateSampleOptions: () => httpClient.current.get<PreValue>('v1/FlsSamples/prevalues'),

  getSampleCountByLab: (labId: string) =>
    httpClient.current.get<number>(`v1/FlsSamples/${labId}/sample-count`),

  getFormById: (id: string) => httpClient.current.get<Form>(`v1/Forms/${id}`),

  getFormResultBuilders: (formId: string) =>
    httpClient.current.get<FormResultBuilder[]>(`v1/Forms/${formId}/results`),

  getFormFieldValueBuilders: () =>
    httpClient.current.get<FormFieldValueBuilder[]>(`v1/Forms/formFieldValueBuilders`),

  listForms: () => httpClient.current.get<Form[]>('v1/Forms'),

  getLabById: (id: string) => httpClient.current.get<Lab>(`v1/Labs/${id}`),

  postLab: (lab: LabPayload) => httpClient.current.post('v1/Labs', lab),
  getLabs: () => httpClient.current.get<Lab[]>(`v1/labs`),
  getLab: (labId: string) => httpClient.current.get<Lab>(`v1/labs/${labId}`),
  putLab: (lab: LabPayload) => httpClient.current.put(`v1/labs`, lab),
  deleteLab: (labId: string) => httpClient.current.delete(`v1/labs/${labId}`),
  getExternalLabs: () => httpClient.current.get<ExternalLab[]>(`v1/ExternalLabs`),
  getExternalLab: (id: string) => httpClient.current.get<ExternalLab>(`v1/ExternalLabs/${id}`),
  deleteExternalLab: (id: string) => httpClient.current.delete(`v1/ExternalLabs/${id}`),
  addExternalLab: (externalLab: ExternalLab) =>
    httpClient.current.post(`v1/ExternalLabs`, externalLab),
  updateExternalLab: (externalLab: ExternalLab) =>
    httpClient.current.put(`v1/ExternalLabs`, externalLab),

  getFormFieldPreValues: () =>
    httpClient.current.get<FormFieldPreValues[]>('v1/FormFieldPreValues'),
  getFormFieldPreValuesById: (id: string) =>
    httpClient.current.get<FormFieldPreValues>(`v1/FormFieldPreValues/${id}`),
  postFormFieldPreValues: (formFieldValidationRule: FormFieldPreValuesPayload) =>
    httpClient.current.post(`v1/FormFieldPreValues`, formFieldValidationRule),
  putFormFieldPreValues: (formFieldValidationRule: FormFieldPreValuesPayload) =>
    httpClient.current.put(`v1/FormFieldPreValues`, formFieldValidationRule),
  deleteFormFieldPreValues: (id: string) =>
    httpClient.current.delete(`v1/FormFieldPreValues/${id}`),

  getNewestRecordByFormId: (formId: string, sampleId: string) =>
    httpClient.current.get<RetrievedRecord>(
      `v1/Records/${sampleId}/forms/${formId}/records/latest`,
    ),

  getLatestRecordForSamples: (payload: LatestRecordForSamplesPayload) =>
    httpClient.current.get<LatestRecordForSample[]>(
      `v1/Records/GetLatestRecordForSamples`,
      payload,
    ),

  postRecord: (record: FlsRecord) => httpClient.current.post(`v1/Records`, record),

  postFileForParsing: (file: FormData) =>
    httpClient.current.post(`v1/Records/upload`, file, undefined, true),

  postBulkInsert: (payload: WaterBulkPayload) =>
    httpClient.current.post(`v1/Records/BulkInsert`, payload),

  getParsedFiles: (sampleId: string) =>
    httpClient.current.get<FlsFile[]>(`v1/flssamples/${sampleId}/files/results`),

  searchVessels: (query: string, options?: RequestInit) =>
    httpClient.current.get<VesselSummary[]>(`v1/Vessels/search?query=${query}`, undefined, options),

  getVessel: (orderNo: string) =>
    httpClient.current.get<VesselSampleInfo>(`v1/Vessels/sample-info/${orderNo}`),

  updateSampleForms: (request: UpdateSampleFormRequest) =>
    httpClient.current.put(`v1/flssamples/${request.id}/forms`, request),

  getExternalTests: () => httpClient.current.get<ExternalTests>(`v1/flssamples/external-tests`),

  registerExternalTests: (sampleId: string, request: RegisterExternalTestsRequest) =>
    httpClient.current.put(`v1/flssamples/${sampleId}/external-tests`, request),

  getCalendar: (orderNo: string, from: string, to: string) =>
    httpClient.current.get<CalendarResponse>(`v1/calendar/${orderNo}?from=${from}&to=${to}`),

  getLatestOilStatus: (orderNo: string, from: string, to: string) =>
    httpClient.current.get<LatestOilTypeResponse>(
      `v1/calendar/latestOilStatus/${orderNo}?from=${from}&to=${to}`,
    ),

  orderExternalTests: (request: OrderExternalTestsRequest) =>
    httpClient.current.post(`v1/flssamples/external-tests/order`, request),

  previewExternalTestsOrder: (request: OrderExternalTestsRequest) =>
    httpClient.current.post<Response>(`v1/flssamples/external-tests/order/preview`, request),

  getExternalLabOrderData: (labId: string) =>
    httpClient.current.get<ExternalLabOrderData>(
      `v1/labs/${labId}/flssamples/marked-for-external-testing`,
    ),
  getDocumentationFiles: (sampleId: string) =>
    httpClient.current.get<FlsFile[]>(`v1/flssamples/${sampleId}/files/documentation`),

  getOwnerEmails: (orderNo: string) =>
    httpClient.current.get<PreValueTypes[]>(`v1/Vessels/sample-info/owner-emails/${orderNo}`),

  uploadDocumentationFile: (sampleId: string, request: FormData) =>
    httpClient.current.post(
      `v1/flssamples/${sampleId}/files/documentation`,
      request,
      undefined,
      true,
    ),

  downloadCertificatePreview: (sampleId: string) =>
    httpClient.current.downloadFile(`v1/flssamples/${sampleId}/certificate/preview`),

  postFinalization: (sampleId: string, request: FinalizationRequest) =>
    httpClient.current.post(`v1/flssamples/${sampleId}/finalize`, request),

  downloadFile: (sampleId: string, filePath: string) =>
    httpClient.current.downloadFile(`v1/flssamples/${sampleId}/files/${filePath}`),

  //Any here to say that you can pass just the fields you need to update
  patchSample: (sampleId: string, fields: JsonPatch[]) =>
    httpClient.current.patch(`v1/flssamples/${sampleId}`, fields),

  deleteDocumentation: (sampleId: string, filePath: string) =>
    httpClient.current.delete(`v1/flssamples/${sampleId}/files/${filePath}`),

  getClassifiers: () => httpClient.current.get<Classifier[]>(`v1/classifier`),
  getClassifierById: (id: string) => httpClient.current.get<Classifier>(`v1/classifier/${id}`),
  updateClassifier: (request: Classifier) => httpClient.current.put(`v1/classifier`, request),

  getClassThresholds: () => httpClient.current.get<ClassThreshold[]>(`v1/classThreshold`),

  getFormFieldValidationRules: () =>
    httpClient.current.get<FormFieldValidationRule[]>(`v1/formFieldValidationRules`),
  getFormFieldValidationRuleById: (id: string) =>
    httpClient.current.get<FormFieldValidationRule>(`v1/formFieldValidationRules/${id}`),
  postFormFieldValidationRule: (formFieldValidationRule: FormFieldValidationRulePayload) =>
    httpClient.current.post(`v1/FormFieldValidationRules`, formFieldValidationRule),
  putFormFieldValidationRule: (formFieldValidationRule: FormFieldValidationRulePayload) =>
    httpClient.current.put(`v1/formFieldValidationRules`, formFieldValidationRule),
  deleteFormFieldValidationRule: (id: string) =>
    httpClient.current.delete(`v1/formFieldValidationRules/${id}`),

  getFormFields: () => httpClient.current.get<FormField[]>(`v1/formFields`),

  getUsers: (page: number, pageSize: number, filterValue?: string | null) =>
    httpClient.current.get<PagedUsersResponse>(
      `v1/users?page=${page}&pageSize=${pageSize}&filterValue=${filterValue}`,
    ),
  deleteUser: (userId: string) => httpClient.current.delete(`v1/users/${userId}`),
  getUser: (userId: string) => httpClient.current.get<User>(`v1/users/${userId}`),
  addUser: (user: UserPayload) => httpClient.current.post(`v1/users`, user),
  updateUser: (user: UserPayload) => httpClient.current.put(`v1/users`, user),
});

export type ApiClient = ReturnType<typeof createApiClient>;
