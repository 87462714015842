import React, { useEffect, useState } from 'react';
import AdminMenu from '../../../components/menus/AdminMenu';
import { ExternalLab, Classifier, Lab, LabPayload, useApiClient } from '../../../api';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useParams } from 'react-router-dom';
import { Formik, setNestedObjectValues, useFormikContext } from 'formik';
import { DebouncedTextField, FlsChip, FlsSelect } from '../../../components/inputs';
import * as Yup from 'yup';
import { FramoButtonWithLoading } from '../../../components/buttons';
import { isEmpty } from 'ramda';
import { useLoading } from '../../../hooks';
import { LoadingActions, State } from '../../../hooks/useLoading';
import { FlsCard } from '../../../components/card';
import { CircularProgress, MenuItem } from '@material-ui/core';
import { FlsLabel } from '../../../components/util';
import { ChipData } from '../../../models';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  actionBar: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(2),
  },
  childOverride: {
    height: '100%',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    paddingTop: theme.spacing(1),
  },
  dividerStyle: {
    margin: theme.spacing(0, 2),
  },
  fieldContainers: {
    padding: theme.spacing(1, 2),
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    width: '100%',

    '& > *': {
      flex: '1 0 21%',
      marginRight: theme.spacing(4),
    },
  },
  overrideChip: {
    flexWrap: 'nowrap',
    flexDirection: 'column',
    textAlign: 'left',
    boxShadow: 'none',
    alignItems: 'center',
    '& > *': {
      '& > *': {
        width: '260px',
        '& > *': {
          width: '100%',
          '& > *': {
            dispaly: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          },
        },
      },
    },
  },
}));

const ValidationSchema = Yup.object().shape({
  labName: Yup.string().required('Required').label('Lab Name'),
  contactEmail: Yup.string().required('Required').email().label('Contact Email'),
  senderEmail: Yup.string().required('Required').email().label('Sender Email'),
  labCode: Yup.string().required('Required').label('Lab Code'),
  shortLabCode: Yup.string().required('Required').length(2).label('Short Lab Code'),
  defaultExternalLabId: Yup.string().required('Required'),
});

const AdminLabsAddPage = () => {
  const client = useApiClient();
  const { state: stateOnSave, dispatch: dispatchOnSave } = useLoading();
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      <AdminMenu />
      <div className={classes.root}>
        <Formik
          initialValues={{
            id: undefined,
            labName: '',
            labCode: '',
            shortLabCode: '',
            streetAddress: '',
            zipCode: '',
            city: '',
            contactEmail: '',
            senderEmail: '',
            defaultExternalLabId: undefined,
            classifierIds: [],
          }}
          validationSchema={ValidationSchema}
          onSubmit={async (values: LabPayload) => {
            if (values.id) {
              try {
                dispatchOnSave({ type: 'REQUEST' });
                await client.putLab(values);
                dispatchOnSave({ type: 'SUCCESS', toast: 'Values updated' });
              } catch (error) {
                dispatchOnSave({
                  type: 'ERROR',
                  error: error?.message || 'Something went wrong',
                  toast: 'Failed to insert values to database.',
                });
              }
            } else {
              try {
                dispatchOnSave({ type: 'REQUEST' });
                const result = await client.postLab(values);
                dispatchOnSave({ type: 'SUCCESS' });
                history.push('/admin/labs/add/' + (result as any).id);
              } catch (error) {
                dispatchOnSave({
                  type: 'ERROR',
                  error: error?.message || 'Something went wrong',
                  toast: 'Failed to insert values to database.',
                });
              }
            }
          }}
        >
          <InnerAddLab stateOnSave={stateOnSave} dispatchOnSave={dispatchOnSave} />
        </Formik>
      </div>
    </>
  );
};

interface InnerAddLabProps {
  stateOnSave: State<unknown>;
  dispatchOnSave: React.Dispatch<LoadingActions<unknown>>;
}

const InnerAddLab = (props: InnerAddLabProps) => {
  const formik = useFormikContext<LabPayload>();
  const [lab, setLab] = useState<Lab>();
  const [allExternalLabs, setAllExternalLabs] = useState<ExternalLab[]>();
  const [classifiers, setClassifiers] = useState<Classifier[]>();
  const [chipData, setChipData] = useState<ChipData[]>();
  const { urlLabId } = useParams<{ urlLabId: string }>();
  const client = useApiClient();
  const classes = useStyles();

  useEffect(() => {
    const fetchExternalLabs = async () => {
      const externalLabs = await client.getExternalLabs();
      setAllExternalLabs(externalLabs);
    };

    const fetchClassifiers = async () => {
      const fetchedClassifiers = await client.getClassifiers();
      setClassifiers(fetchedClassifiers);
    };

    fetchExternalLabs();
    fetchClassifiers();
  }, []);

  const handleChipChange = (chips: ChipData[]) => {
    const clickedChips = chips
      ?.filter((chip) => chip.clicked)
      .map((chip) => {
        return chip.id;
      });

    formik.setFieldValue('classifierIds', clickedChips);
  };

  useEffect(() => {
    if (classifiers) {
      const newChipData = classifiers.map((value, index) => {
        return {
          key: Number(index),
          id: value.id,
          label: value.name,
          clicked: lab?.classifiers.map((x) => x.id).includes(value.id) || false,
          isMandatory: false,
        };
      });

      setChipData(newChipData);
      handleChipChange(newChipData);
    }
  }, [classifiers, lab]);

  useEffect(() => {
    const fetchLab = async () => {
      if (urlLabId) {
        const lab = await client.getLab(urlLabId);
        setLab(lab);
      }
    };

    fetchLab();
  }, [urlLabId]);

  React.useEffect(() => {
    if (lab) {
      Object.entries(lab).forEach(([key, value]) => {
        if (formik.values.hasOwnProperty(key)) {
          formik.setFieldValue(key, value);
        } else if (key === 'defaultExternalLab') {
          formik.setFieldValue('defaultExternalLabId', value?.id);
        }
      });
    }
  }, [lab]);

  const handleSubmit = async (ev: any) => {
    props.dispatchOnSave({ type: 'REQUEST' });
    const errors = await formik.validateForm();
    if (isEmpty(errors)) {
      formik.handleSubmit(ev);
    } else {
      console.error(errors);
      formik.setErrors(errors);
      formik.setTouched(setNestedObjectValues(errors, true));
      props.dispatchOnSave({
        type: 'ERROR',
        error: 'Validation Error',
        toast: 'Some fields have failed validation',
      });
    }
  };

  return (
    <div>
      <FlsCard overrideChildStyle={classes.childOverride} showDivider header='Lab'>
        <form onSubmit={handleSubmit}>
          <div id='firstbox' className={classes.fieldContainers}>
            <div className={classes.inputContainer}>
              <DebouncedTextField
                fullWidth
                displayHelper
                value={formik.values.labName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                id={'labName'}
                placeholder='Lab Name'
                helperText={formik.touched.labName && formik.errors.labName}
              />
            </div>
            <div className={classes.inputContainer}>
              <DebouncedTextField
                fullWidth
                displayHelper
                value={formik.values.labCode}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                id={'labCode'}
                placeholder='Lab Code'
                helperText={formik.touched.labCode && formik.errors.labCode}
              />
            </div>
            <div className={classes.inputContainer}>
              <DebouncedTextField
                fullWidth
                displayHelper
                value={formik.values.shortLabCode}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                id={'shortLabCode'}
                placeholder='Short Lab Code'
                helperText={formik.touched.shortLabCode && formik.errors.shortLabCode}
              />
            </div>
          </div>
          <div id='secondbox' className={classes.fieldContainers}>
            <div className={classes.inputContainer}>
              <DebouncedTextField
                fullWidth
                displayHelper
                value={formik.values.contactEmail}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                id={'contactEmail'}
                placeholder='Contact Email'
                helperText={formik.touched.contactEmail && formik.errors.contactEmail}
              />
            </div>
            <div className={classes.inputContainer}>
              <DebouncedTextField
                fullWidth
                displayHelper
                value={formik.values.senderEmail}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                id={'senderEmail'}
                placeholder='Sender Email'
                helperText={formik.touched.senderEmail && formik.errors.senderEmail}
              />
            </div>
          </div>
          <div id='thirdbox' className={classes.fieldContainers}>
            <div className={classes.inputContainer}>
              <DebouncedTextField
                fullWidth
                displayHelper
                value={formik.values.streetAddress}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                id={'streetAddress'}
                placeholder='Street Address'
                helperText={formik.touched.streetAddress && formik.errors.streetAddress}
              />
            </div>
            <div className={classes.inputContainer}>
              <DebouncedTextField
                fullWidth
                displayHelper
                value={formik.values.zipCode}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                id={'zipCode'}
                placeholder='Zip Code'
                helperText={formik.touched.zipCode && formik.errors.zipCode}
              />
            </div>
            <div className={classes.inputContainer}>
              <DebouncedTextField
                fullWidth
                displayHelper
                value={formik.values.city}
                onChange={(a) => {
                  formik.handleChange(a);
                }}
                onBlur={formik.handleBlur}
                id={'city'}
                placeholder='City'
                helperText={formik.touched.city && formik.errors.city}
              />
            </div>
          </div>
          <div id='fourthBox' className={classes.fieldContainers}>
            <div className={classes.inputContainer}>
              <FlsLabel textTransform='none'>CLASSIFYING VALUES</FlsLabel>
              {chipData ? (
                <FlsChip
                  className={classes.overrideChip}
                  chipData={chipData}
                  setChipData={(chips) => {
                    setChipData(chips);
                    handleChipChange(chips);
                  }}
                />
              ) : (
                <CircularProgress size={24} />
              )}
            </div>
            <div className={classes.inputContainer}>
              <FlsLabel htmlFor={'defaultExternalLabId'} textTransform='none'>
                DEFAULT EXTERNAL LAB
              </FlsLabel>
              <FlsSelect
                fullWidth
                value={formik.values.defaultExternalLabId || ''}
                onChange={(a) => {
                  formik.setFieldValue('defaultExternalLabId', a.target.value);
                }}
                id={'defaultExternalLabId'}
                helperText={
                  formik.touched.defaultExternalLabId && formik.errors.defaultExternalLabId
                }
              >
                {!allExternalLabs ? (
                  <MenuItem disabled>No options</MenuItem>
                ) : (
                  allExternalLabs?.map((externalLab) => (
                    <MenuItem key={externalLab.id} value={externalLab.id}>
                      {externalLab.name}
                    </MenuItem>
                  ))
                )}
              </FlsSelect>
            </div>
          </div>
        </form>
      </FlsCard>
      <div className={classes.actionBar}>
        <FramoButtonWithLoading
          buttonSize='sizeNormal'
          buttonStyle='styleFilled'
          type='button'
          handleClick={handleSubmit}
          submissionState={props.stateOnSave?.state}
        >
          {formik.values.id ? 'Save' : 'Register'}
        </FramoButtonWithLoading>
      </div>
    </div>
  );
};

export default AdminLabsAddPage;
