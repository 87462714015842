import React, { useEffect, useState } from 'react';
import AdminMenu from '../../../components/menus/AdminMenu';
import {
  FormFieldValidationRule,
  FormFieldValidationRulePayload,
  FormFieldValidationRuleType,
  FormField,
  Classifier,
  useApiClient,
} from '../../../api';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useParams } from 'react-router-dom';
import { Formik, setNestedObjectValues, useFormikContext } from 'formik';
import { DebouncedTextField, FlsSelect } from '../../../components/inputs';
import * as Yup from 'yup';
import { FramoButtonWithLoading } from '../../../components/buttons';
import { isEmpty } from 'ramda';
import { useLoading } from '../../../hooks';
import { LoadingActions, State } from '../../../hooks/useLoading';
import { FlsCard } from '../../../components/card';
import { Severity } from '../../../models';
import { MenuItem } from '@material-ui/core';
import { FlsLabel } from '../../../components/util';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  actionBar: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(2),
  },
  childOverride: {
    height: '100%',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    paddingTop: theme.spacing(1),
  },
  dividerStyle: {
    margin: theme.spacing(0, 2),
  },
  fieldContainers: {
    padding: theme.spacing(1, 2),
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    width: '100%',

    '& > *': {
      flex: '1 0 21%',
      marginRight: theme.spacing(4),
    },
  },
}));

const validSeverityValues: Severity[] = ['Normal', 'Marginal', 'Critical', 'Error'];

const ValidationSchema = Yup.object().shape({
  type: Yup.string()
    .oneOf(Object.values(FormFieldValidationRuleType))
    .required('Required')
    .label('Type'),
  value: Yup.number().nullable().label('Value'),
  regex: Yup.string().nullable().label('Regex'),
  errorText: Yup.string().nullable().label('Error Text'),
  severity: Yup.string().oneOf(validSeverityValues).required('Required').label('Severity'),
  showNotification: Yup.boolean().required('Required').label('Show Notifications'),
  formFieldId: Yup.string().required('Required'),
});

const AdminFormFieldValidationRulesAddPage = () => {
  const client = useApiClient();
  const { state: stateOnSave, dispatch: dispatchOnSave } = useLoading();
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      <AdminMenu />
      <div className={classes.root}>
        <Formik
          initialValues={{
            id: undefined,
            formFieldId: '',
            type: '',
            value: undefined,
            regex: '',
            errorText: '',
            severity: 'Normal',
            showNotification: false,
            classifierId: undefined,
          }}
          validationSchema={ValidationSchema}
          onSubmit={async (values: FormFieldValidationRulePayload) => {
            if (values.id) {
              try {
                dispatchOnSave({ type: 'REQUEST' });
                await client.putFormFieldValidationRule(values);
                dispatchOnSave({ type: 'SUCCESS', toast: 'Values updated' });
              } catch (error) {
                dispatchOnSave({
                  type: 'ERROR',
                  error: error?.message || 'Something went wrong',
                  toast: 'Failed to insert values to database.',
                });
              }
            } else {
              try {
                dispatchOnSave({ type: 'REQUEST' });
                const result = await client.postFormFieldValidationRule(values);
                dispatchOnSave({ type: 'SUCCESS' });
                history.push('/admin/formFieldValidationRules/add/' + (result as any).id);
              } catch (error) {
                dispatchOnSave({
                  type: 'ERROR',
                  error: error?.message || 'Something went wrong',
                  toast: 'Failed to insert values to database.',
                });
              }
            }
          }}
        >
          <InnerAddFormFieldValidationRule
            stateOnSave={stateOnSave}
            dispatchOnSave={dispatchOnSave}
          />
        </Formik>
      </div>
    </>
  );
};

interface InnerAddFormFieldValidationRuleProps {
  stateOnSave: State<unknown>;
  dispatchOnSave: React.Dispatch<LoadingActions<unknown>>;
}

const InnerAddFormFieldValidationRule = (props: InnerAddFormFieldValidationRuleProps) => {
  const formik = useFormikContext<FormFieldValidationRule>();
  const [formFieldValidationRule, setFormFieldValidationRule] = useState<FormFieldValidationRule>();
  const [formFields, setFormFiels] = useState<FormField[]>();
  const [classifiers, setClassifiers] = useState<Classifier[]>();
  const { urlFormFieldValidationRuleId } = useParams<{ urlFormFieldValidationRuleId: string }>();
  const client = useApiClient();
  const classes = useStyles();

  useEffect(() => {
    const fetchFormFieldValidationRule = async () => {
      if (urlFormFieldValidationRuleId) {
        const formFieldValidationRule = await client.getFormFieldValidationRuleById(
          urlFormFieldValidationRuleId,
        );
        setFormFieldValidationRule(formFieldValidationRule);
      }
    };

    fetchFormFieldValidationRule();
  }, [urlFormFieldValidationRuleId]);

  useEffect(() => {
    const fetchFormFields = async () => {
      const formFields = await client.getFormFields();
      const filteredFormFields = formFields.filter((x) => x.canHaveValidationRules);
      setFormFiels(filteredFormFields);
    };

    fetchFormFields();
  }, []);

  useEffect(() => {
    const fetchClassifiers = async () => {
      const classifiers = await client.getClassifiers();
      setClassifiers(classifiers);
    };

    fetchClassifiers();
  }, []);

  const handleSubmit = async (ev: any) => {
    props.dispatchOnSave({ type: 'REQUEST' });
    const errors = await formik.validateForm();
    if (isEmpty(errors)) {
      formik.handleSubmit(ev);
    } else {
      console.log(errors);
      formik.setErrors(errors);
      formik.setTouched(setNestedObjectValues(errors, true));
      props.dispatchOnSave({
        type: 'ERROR',
        error: 'Validation Error',
        toast: 'Some fields have failed validation',
      });
    }
  };

  React.useEffect(() => {
    if (formFieldValidationRule) {
      Object.entries(formFieldValidationRule).forEach(([key, value]) => {
        if (formik.values.hasOwnProperty(key)) {
          formik.setFieldValue(key, value);
        }
      });
    }
  }, [formFieldValidationRule]);

  return (
    <div>
      <FlsCard
        overrideChildStyle={classes.childOverride}
        showDivider
        header='FormFieldValidationRule'
      >
        <form onSubmit={handleSubmit}>
          <div id='firstbox' className={classes.fieldContainers}>
            <div className={classes.inputContainer}>
              <DebouncedTextField
                fullWidth
                displayHelper
                value={formik.values.type}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                id={'type'}
                placeholder='Type'
                helperText={formik.touched.type && formik.errors.type}
              />
            </div>
            <div className={classes.inputContainer}>
              <DebouncedTextField
                fullWidth
                displayHelper
                value={formik.values.value?.toString()}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                id={'value'}
                placeholder='Value'
                helperText={formik.touched.value && formik.errors.value}
              />
            </div>
            <div className={classes.inputContainer}>
              <DebouncedTextField
                fullWidth
                displayHelper
                value={formik.values.regex}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                id={'regex'}
                placeholder='Regex'
                helperText={formik.touched.regex && formik.errors.regex}
              />
            </div>
          </div>
          <div id='secondbox' className={classes.fieldContainers}>
            <div className={classes.inputContainer}>
              <DebouncedTextField
                fullWidth
                displayHelper
                value={formik.values.errorText}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                id={'errorText'}
                placeholder='Error Text'
                helperText={formik.touched.errorText && formik.errors.errorText}
              />
            </div>
            <div className={classes.inputContainer}>
              <DebouncedTextField
                fullWidth
                displayHelper
                value={formik.values.severity}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                id={'severity'}
                placeholder='Severity'
                helperText={formik.touched.severity && formik.errors.severity}
              />
            </div>
          </div>
          <div id='thirdbox' className={classes.fieldContainers}>
            <div className={classes.inputContainer}>
              <DebouncedTextField
                fullWidth
                displayHelper
                value={formik.values.showNotification.toString()}
                onChange={(shouldShowNotification) => {
                  formik.setFieldValue(
                    'showNotification',
                    shouldShowNotification.target.value.toLowerCase() === 'true',
                  );
                }}
                onBlur={formik.handleBlur}
                id={'showNotification'}
                placeholder='Show Notification'
                helperText={formik.touched.showNotification && formik.errors.showNotification}
              />
            </div>
            <div className={classes.inputContainer}>
              <FlsLabel htmlFor={'formFieldId'} textTransform='none'>
                FORM FIELD
              </FlsLabel>
              <FlsSelect
                disabled={formik.values.id !== undefined}
                fullWidth
                value={formik.values.formFieldId || ''}
                onChange={(a) => {
                  formik.setFieldValue('formFieldId', a.target.value);
                }}
                id={'formFieldId'}
                helperText={formik.touched.formFieldId && formik.errors.formFieldId}
              >
                {formFields?.length === 0 ? (
                  <MenuItem disabled>No options</MenuItem>
                ) : (
                  formFields?.map((formField) => (
                    <MenuItem key={formField.id} value={formField.id}>
                      {formField.label}
                    </MenuItem>
                  ))
                )}
              </FlsSelect>
            </div>
          </div>
          <div id='fourthBox' className={classes.fieldContainers}>
            <div className={classes.inputContainer}>
              <FlsLabel htmlFor={'classifierId'} textTransform='none'>
                CLASSIFIER
              </FlsLabel>
              <FlsSelect
                fullWidth
                disabled={formik.values.id !== undefined}
                value={formik.values.classifierId || ''}
                onChange={(a) => {
                  formik.setFieldValue('classifierId', a.target.value);
                }}
                id={'classifierId'}
                helperText={formik.touched.classifierId && formik.errors.classifierId}
              >
                {classifiers?.length === 0 ? (
                  <MenuItem disabled>No options</MenuItem>
                ) : (
                  classifiers?.map((classifier) => (
                    <MenuItem key={classifier.id} value={classifier.id}>
                      {classifier.name}
                    </MenuItem>
                  ))
                )}
              </FlsSelect>
            </div>
          </div>
        </form>
      </FlsCard>
      <div className={classes.actionBar}>
        <FramoButtonWithLoading
          buttonSize='sizeNormal'
          buttonStyle='styleFilled'
          type='button'
          handleClick={handleSubmit}
          submissionState={props.stateOnSave?.state}
        >
          {formik.values.id ? 'Save' : 'Register'}
        </FramoButtonWithLoading>
      </div>
    </div>
  );
};

export default AdminFormFieldValidationRulesAddPage;
