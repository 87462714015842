// Should be extended by all relevant fields to dynamically adapt the size
// based on the form settings.
export interface FieldSize {
  fieldSize?: 'xs' | 'sm' | 'md' | 'lg' | 'fullWidth';
}

// All the supported icontypes of the icon handler component
export type IconIdentifierType =
  | 'add'
  | 'arrow'
  | 'back'
  | 'forward'
  | 'logout'
  | 'search'
  | 'close'
  | 'upload'
  | 'print'
  | 'chevron'
  | 'send'
  | 'check'
  | 'important'
  | 'settings'
  | 'document'
  | 'externalLink'
  | 'dashboard'
  | 'omp'
  | 'delete'
  | 'droplet'
  | 'pdi'
  | 'loader'
  | 'upload-small';

export interface IconIdentifier {
  iconIdentifier?: IconIdentifierType;
}

export function isValidIconIdentifier(icon: string): icon is IconIdentifierType {
  return (
    icon === 'add' ||
    icon === 'arrow' ||
    icon === 'back' ||
    icon === 'forward' ||
    icon === 'logout' ||
    icon === 'chevron' ||
    icon === 'search' ||
    icon === 'close' ||
    icon === 'upload' ||
    icon === 'print' ||
    icon === 'send' ||
    icon === 'check' ||
    icon === 'important' ||
    icon === 'settings' ||
    icon === 'dashboard' ||
    icon === 'externalLink' ||
    icon === 'document' ||
    icon === 'omp' ||
    icon === 'pdi' ||
    icon === 'delete' ||
    icon === 'droplet'
  );
}

export interface FieldErrorType {
  errorText: string;
  severity: Severity;
  showNotification: boolean;
}

export interface ChipData {
  key: number;
  label: string;
  clicked: boolean;
  id: string;
  isMandatory: boolean;
}

export type Severity = 'Normal' | 'Marginal' | 'Critical' | 'Error';
