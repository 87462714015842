import { makeStyles, Theme, Tooltip } from '@material-ui/core';
import React from 'react';
import { IconHandler } from '../util';

const useYesNoStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
  },
  yes: {
    transition: '0.2s',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      borderRadius: '8px',
      backgroundColor: theme.palette.background.default,
    },
    marginRight: theme.spacing(1),
    '& > svg': {
      width: '24px',
      height: '24px',
    },
    '& > svg > *': {
      stroke: 'rgb(245, 245, 245)',
    },
  },
  no: {
    transition: '0.2s',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      borderRadius: '8px',
      backgroundColor: theme.palette.background.default,
    },
    '& > svg': {
      width: '24px',
      height: '24px',
    },
    '& > svg > *': {
      stroke: theme.palette.error.main,
    },
  },
}));
interface YesNoProps {
  onYes: (...args: any[]) => void;
  onNo: (...args: any[]) => void;
}
export default function YesNo(props: YesNoProps) {
  const classes = useYesNoStyles();
  return (
    <div className={classes.root}>
      <Tooltip title='Confirm' arrow>
        <div onClick={props.onYes} className={classes.yes}>
          <IconHandler iconIdentifier='check' />
        </div>
      </Tooltip>
      <Tooltip title='Abort' arrow>
        <div onClick={props.onNo} className={classes.no}>
          <IconHandler iconIdentifier='close' />
        </div>
      </Tooltip>
    </div>
  );
}
