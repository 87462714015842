import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React from 'react';
import { IconHandler } from '../util';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: '6px',
    border: '2px solid',
    borderColor: (props: InspectionTagProps) => getColor(props.color, theme),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    color: theme.palette.text.primary,
    display: 'inline-flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  icon: {
    marginRight: theme.spacing(0.5),
  },
}));

const getColor = (color: Color, theme: Theme) => {
  switch (color) {
    case 'error':
      return theme.palette.error.main;
    case 'primary':
      return theme.palette.icons.primary;
    case 'secondary':
      return theme.palette.icons.secondary;
    case 'success':
      return theme.palette.success.main;
    case 'warning':
      return theme.palette.warning.main;
    default:
      return theme.palette.text.primary;
  }
};

export type InspectionType = 'L1' | 'L2' | 'AI' | 'PDI' | 'FOC';
export type Color = 'primary' | 'secondary' | 'success' | 'warning' | 'error';

interface InspectionTagProps {
  type: InspectionType;
  color: Color;
  className?: string;
}

const getCorrectIcon = (type: InspectionType) => {
  switch (type) {
    case 'L1':
    case 'L2':
      return 'omp';
    default:
      return 'pdi';
  }
};

export default function InspectionTypeTag(props: InspectionTagProps) {
  const icon = getCorrectIcon(props.type);

  const classes = useStyles(props);
  return (
    <div className={clsx(classes.root, props.className)}>
      <IconHandler iconIdentifier={icon} className={classes.icon} /> {props.type}
    </div>
  );
}
