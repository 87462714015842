import React, { useState } from 'react';
import { DataGrid, GridColDef, GridCellParams, GridRowData } from '@material-ui/data-grid';
import { makeStyles, Typography } from '@material-ui/core';
import { FlsCard } from '../../../components/card';
import { FramoAction, FramoButton, YesNo } from '../../../components/buttons';
import { Loadingbar, useApiRef } from '../../../components/datagrid';
import { IconHandler } from '../../../components/util';
import { WATER_RECORD } from '../../../constants/databaseConstants';
import {
  RecordPayload,
  WaterBulkPayload,
  useApiClient,
  Sample,
  LatestRecordForSamplesPayload,
} from '../../../api';
import { createRecord } from '../../../utils/formUtils';
import { isEmpty } from 'ramda';
import { useLoading } from '../../../hooks';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 'calc(100vh - 546px)',
    minHeight: '424px',
    flexDirection: 'column',
    boxShadow: theme.palette.boxShadows.dark,
    borderRadius: '6px',
    '& .idCell': {
      fontSize: '0.875rem',
    },
  },
  datagridContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    ...theme.typography.body1,
    color: theme.palette.text.secondary,
    '& .MuiDataGrid-root': {
      ...theme.typography.body1,
    },
    '& .MuiDataGrid-columnHeaderWrapper': {
      backgroundColor: theme.palette.misc.header,
      color: theme.palette.text.secondary,
    },
    '& .MuiToolbar-root': {
      color: theme.palette.primary.main,
    },
    '& .MuiSelect-icon': {
      color: theme.palette.primary.main,
    },
    '& .MuiDataGrid-window': {
      overflowX: 'hidden',
    },
    '& .inputCells, .MuiDataGrid-cell.MuiDataGrid-cell--editing': {
      backgroundColor: theme.palette.background.default,
    },
    '& .MuiInputBase-input': {
      '&[type=number]': {
        '-moz-appearance': 'textfield',
      },
      '&::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '&::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
    },
    '& .MuiDataGrid-cell--withRenderer': {
      cursor: 'pointer',
      '& > *': {
        '& > *': {
          stroke: theme.palette.error.main,
        },
      },
    },
  },
  waterTestHeader: {
    padding: '16px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  activeContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  inactiveContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    boxShadow: 'none',
  },
  invalidField: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.palette.error.main,
  },
}));

const LOCAL_STORAGE_KEY = 'water-test-data';
// https://material-ui.com/api/data-grid/grid-col-def/
const waterTestColumns: GridColDef[] = [
  /*{
    field: 'slot',
    headerName: 'Slot',
    type: 'number',
    align: 'right',
    headerAlign: 'left',
    width: 60,
    hideSortIcons: true,
    editable: true,
    cellClassName: 'inputCells',
  }, */
  {
    field: 'shortId',
    headerAlign: 'left',
    sortable: false,
    hideSortIcons: true,
    headerName: 'ID',
    flex: 1,
    cellClassName: 'idCell',
  },
  {
    field: 'water',
    type: 'number',
    headerName: 'Value',
    sortable: false,
    align: 'right',
    headerAlign: 'left',
    editable: true,
    width: 72,
    cellClassName: 'inputCells',
  },
  {
    field: '',
    sortable: false,
    width: 20,
    align: 'center',
    // eslint-disable-next-line react/display-name
    renderCell: () => <IconHandler iconIdentifier='close' />,
  },
];

interface WaterTestTableProps {
  onClear?: (rows: string[]) => void;
  onSubmit?: () => void;
  onRemove?: () => void;
  fetchSamples: () => Sample[];
}
/* By request, the slot functionality has been disabled, but not purged. This functionality allowed users to input which physical slot a water test sample was allocated to. Supporting code for this functionality has not been removed */

const WaterTestTable = (props: WaterTestTableProps) => {
  const [rows, setRows] = useState<GridRowData[]>();
  const { apiRef, columns } = useApiRef(waterTestColumns);
  const classes = useStyles();
  const client = useApiClient();
  const { state: waterState, dispatch: dispatchWater } = useLoading();
  const [clearAttempt, setClearAttempt] = useState<boolean>(false);
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);

  React.useEffect(() => {
    const data = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (data) {
      setRows(JSON.parse(data));
    }
  }, []);

  const readRows = () => {
    const data = (apiRef as any)?.current?.getRowModels();
    let storedRows: GridRowData[] | undefined = undefined;
    if (data) {
      for (const row of data) {
        if (storedRows === undefined) {
          storedRows = [row[1]];
        } else {
          storedRows.push(row[1]);
        }
      }
      return storedRows;
    }
    return undefined;
  };

  const clearAllRows = () => {
    setClearAttempt(false);
    setIsFirstRender(true);
    localStorage.removeItem(LOCAL_STORAGE_KEY);
    if (props.onClear) {
      props.onClear(rows?.map((row) => row.id) ?? []);
    }
    setRows(undefined);
    dispatchWater({ type: 'RESET' });
  };

  /** Best solution found so far for row removal in datagrid is to read all data from datagrid, remove row and insert back in.
   * Due to trickyness in cell definition, the trigger used is cell double clicked and check if the cell field belongs to the icon.
   * @param params Data of the cell clicked
   */
  const removeRow = (params: GridCellParams) => {
    if (params.field === '') {
      const newRows = readRows()
        ?.filter((entry) => entry.id !== params.row.id)
        .sort((a, b) => a.shortId.localeCompare(b.shortId, 'en', { numeric: true })); //Sort numerically
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newRows));
      setRows(newRows);
    }
  };

  const uniqueCheck = (params) => {
    const data = (apiRef as any)?.current?.getRowModels();
    if (data) {
      for (const row of data) {
        if (row[1].slot !== undefined && row[1].slot === params.value && params.id !== row[1].id) {
          return false;
        }
      }
    }
    return true;
  };

  // Store data after cell editing is complete
  const storeData = (event) => {
    const storedData = readRows();
    if (storedData) {
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(storedData));
    }
  };

  const validateSending = (data) => {
    if (data) {
      for (const entry of data) {
        if (
          entry.water !== 0 &&
          (!entry.water || entry.water === '' || !/^[1-9][0-9]*$/.test(entry.water.toString()))
        ) {
          dispatchWater({
            type: 'ERROR',
            toast:
              'Validation Error: Water field is either missing value or not a number. Check table for warning boxes.',
          });
          return false;
        }
      }
      return true;
    }
    dispatchWater({
      type: 'ERROR',
      toast: 'Data error: Could not read from table. Refresh or contact support.',
    });
    return false;
  };

  const submitRecords = async () => {
    dispatchWater({ type: 'REQUEST' });
    let parseError = false;
    const data = readRows();
    const recordList: RecordPayload[] = [];
    if (validateSending(data) && data) {
      for (const entry of data) {
        const recordValue = createRecord(
          WATER_RECORD.discriminator,
          WATER_RECORD.formFieldId,
          entry.water,
          false,
        );
        if (!recordValue) {
          parseError = true;
          dispatchWater({
            type: 'ERROR',
            toast: 'Submission aborted. Could not process water field for ' + entry.shortId,
          });
          break;
        } else {
          recordList.push({
            formId: WATER_RECORD.formId,
            flsSampleId: entry.id,
            recordValues: [recordValue],
          });
        }
      }
      if (!parseError) {
        const apiPayload: WaterBulkPayload = { formId: WATER_RECORD.formId, records: recordList };
        try {
          const postResponse = await client.postBulkInsert(apiPayload);
          if (isEmpty(postResponse)) {
            dispatchWater({
              type: 'SUCCESS',
              toast: 'Succesfull submission, you are free to clear the table.',
            });
            if (props.onSubmit) {
              props.onSubmit();
            }
          }
        } catch (error) {
          dispatchWater({
            type: 'ERROR',
            toast:
              'Could not insert into database. This may occur due to database disconnect or field validation errors.',
          });
        }
      }
    }
  };

  //previous clears data stays persistent until new data is stored. Fix so it doesn't read from this for duplicate checks the first time
  const apirefFix = () => {
    if (isFirstRender) {
      setIsFirstRender(false);
      return undefined;
    } else {
      return readRows()?.map((item) => item.id);
    }
  };

  //Add the rows selected externally in another datagrid to this datagrid.
  const addRows = () => {
    const data = props.fetchSamples();
    let oldRows: GridRowData[] | undefined = rows?.map((item) =>
      Object.assign({}, item, { selected: false }),
    );
    const idList = apirefFix();
    if (data !== undefined) {
      for (const row of data) {
        if (oldRows === undefined) {
          oldRows = [row];
        } else if (!idList?.includes(row.id)) {
          oldRows.push(row);
          idList?.push(row.id);
        }
      }
      if (oldRows) {
        getWaterValues(oldRows);
      }
    }
  };

  const getWaterValues = async (oldRows: GridRowData[]) => {
    dispatchWater({ type: 'REQUEST' });
    const payloadIds = new Set(oldRows.filter((row) => !row.water).map((row) => row.id));
    if (payloadIds) {
      const valuesToSend: LatestRecordForSamplesPayload = {
        flsSampleIds: [...payloadIds],
        formFieldId: WATER_RECORD.formFieldId,
      };
      try {
        const resultList = await client.getLatestRecordForSamples(valuesToSend);
        const resultMap = new Map(
          resultList.map((resultObject) => [
            resultObject.flsSampleId,
            resultObject.value.decimalValue || resultObject.value.intValue || '',
          ]),
        );
        setRows(
          oldRows
            .map((row) => {
              if (resultMap.has(row.id)) {
                return {
                  water: resultMap.get(row.id),
                  ...row,
                };
              }
              return row;
            })
            .sort((a, b) => a.shortId.localeCompare(b.shortId, 'en', { numeric: true })),
        );
        dispatchWater({ type: 'SUCCESS' });
      } catch (error) {
        dispatchWater({
          type: 'ERROR',
          error: error,
          toast: 'Error occured when attempting to retrieve previous water test for samples...',
        });
        console.log(error);
      }
    }
  };

  //https://material-ui.com/api/data-grid/data-grid/ for info on adapting the datagrid
  return (
    <div className={classes.root}>
      {rows ? (
        <div className={classes.activeContainer}>
          <div className={classes.waterTestHeader}>
            <Typography variant='body2'>Water Test</Typography>
            <FramoAction handleClick={addRows} colorVariant='secondary'>
              Add Selected
            </FramoAction>
          </div>
          <div className={classes.datagridContainer}>
            <DataGrid
              //sortModel={[{ field: 'slot', sort: 'asc' }]}
              rows={rows}
              columns={columns}
              disableColumnFilter
              disableColumnMenu
              hideFooterPagination
              disableSelectionOnClick
              onCellEditStop={storeData}
              onCellDoubleClick={removeRow}
              scrollbarSize={1}
              loading={waterState.state === 'pending'}
              hideFooter
              getCellClassName={(params: GridCellParams) => {
                if (params.field === 'slot') {
                  return uniqueCheck(params) ? '' : classes.invalidField;
                }
                return '';
              }}
              components={{
                LoadingOverlay: Loadingbar,
              }}
            />
          </div>
          <div className={classes.waterTestHeader}>
            {clearAttempt ? (
              <YesNo onNo={() => setClearAttempt(false)} onYes={clearAllRows} />
            ) : (
              <FramoAction handleClick={() => setClearAttempt(true)} colorVariant='hazard'>
                Clear
              </FramoAction>
            )}
            <FramoAction handleClick={submitRecords} colorVariant='secondary'>
              Submit
            </FramoAction>
          </div>
        </div>
      ) : (
        <FlsCard
          header='Water Test'
          showDivider
          className={classes.root}
          overrideChildStyle={classes.inactiveContainer}
        >
          <FramoButton handleClick={addRows} buttonSize='sizeNormal'>
            Add Selected Rows
          </FramoButton>
        </FlsCard>
      )}
    </div>
  );
};
export default WaterTestTable;
