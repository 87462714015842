import React from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { CalendarIcon } from '../../assets/icons';
import { FramoIcon } from '../buttons';
import { HelperText, IconHandler } from '../util';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    '& .MuiFormHelperText-root': {
      margin: '0px',
    },
    '& .MuiFormControl-root': {
      width: '100%',
    },
    margin: 0,
    '& .MuiInputBase-input': {
      padding: theme.spacing(1.5),
    },
    '& .MuiInputBase-root': {
      border: '1px solid transparent',
      borderColor: (props: DatePickerProps) =>
        props.helperText ? theme.palette.error.main : 'transparent',
      backgroundColor: (props: DatePickerProps) =>
        props.disabled ? theme.palette.button.disabled : theme.palette.background.input,
      color: theme.palette.text.primary,
      height: theme.spacing(6),
      width: '100%',
      borderRadius: (props: DatePickerProps) =>
        props.handleDisableButton ? '4px 0px 0px 4px' : '4px',
      transition: '0.2s',
      '&:focus-within': {
        color: theme.palette.text.secondary,
        border: '1px solid',
        borderColor: theme.palette.primary.main,
        '& .MuiButtonBase-root': {
          '& .MuiIconButton-label': {
            '& > *': {
              '& > *': {
                transition: '0.2s',
                stroke: theme.palette.text.primary,
              },
            },
          },
          '&:hover': {
            '& .MuiIconButton-label': {
              '& > *': {
                '& > *': {
                  stroke: theme.palette.button.highlight,
                },
              },
            },
          },
        },
      },
    },
    //Style Icon
    '& .MuiButtonBase-root': {
      '& .MuiIconButton-label': {
        '& > *': {
          '& > *': {
            transition: '0.2s',
            stroke: theme.palette.text.secondary,
          },
        },
      },
      '&:hover': {
        '& .MuiIconButton-label': {
          '& > *': {
            '& > *': {
              stroke: theme.palette.button.highlight,
            },
          },
        },
      },
    },
  },
  closeIcon: {
    borderRadius: '0px',
    '& > *': {
      borderRadius: '0px 4px 4px 0px',
      backgroundColor: theme.palette.background.default,
      width: theme.spacing(6),
      height: theme.spacing(6),
    },
    '&:hover': {
      '& > *': {
        '& > *': {
          '& > *': {
            stroke: theme.palette.icons.secondary,
          },
        },
      },
    },
  },
  datePickerContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
}));

interface DatePickerProps {
  id?: string;
  name?: string;
  handleDisableButton?: () => void;
  disabled?: boolean;
  value: Date | null;
  onChange: (newDate: Date) => void;
  helperText?: string;
  displayHelper?: boolean;
  className?: string;
}

const Datepicker = (props: DatePickerProps) => {
  const {
    id,
    value,
    handleDisableButton,
    disabled,
    helperText,
    displayHelper,
    onChange,
    name,
    className,
  } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.errorContainer}>
      <div className={classes.datePickerContainer}>
        <KeyboardDatePicker
          autoOk
          autoComplete='off'
          id={id}
          name={name}
          className={clsx(classes.root, className)}
          disableToolbar
          disabled={disabled}
          variant='inline'
          format='dd/MM/yyyy'
          margin='normal'
          inputVariant='standard'
          invalidDateMessage={<></>} //Overide internal error handling
          maxDateMessage={<></>}
          minDateMessage={<></>}
          InputProps={{ disableUnderline: true }}
          keyboardIcon={<CalendarIcon />}
          value={value}
          onChange={onChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        {handleDisableButton && (
          <FramoIcon className={classes.closeIcon} handleClick={handleDisableButton}>
            <IconHandler iconIdentifier='close' />{' '}
          </FramoIcon>
        )}
      </div>
      {displayHelper && <HelperText helperText={helperText} severity={'Error'} />}
    </div>
  );
};
export default Datepicker;
