import React, { PropsWithChildren } from 'react';
import { Typography, makeStyles, Theme } from '@material-ui/core';
import { SubmissionState } from '../../models';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    cursor: (props: FramoButtonProps) => (props.disabled ? 'default' : 'pointer'),
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    padding: theme.spacing(0, 4, 0, 4), //Might need changes due to resizing
    borderRadius: theme.spacing(0.5),
    transition: '0.3s',
    border: (props: FramoButtonProps) =>
      props.buttonStyle === 'styleOutlined' ? '2px solid' : 'none',
    borderColor: (props: FramoButtonProps) =>
      props.disabled
        ? theme.palette.button.disabled
        : props.submissionState === 'error'
        ? theme.palette.error.main
        : props.submissionState === 'submitted'
        ? theme.palette.success.main
        : props.buttonStyle === 'styleOutlined'
        ? theme.palette.button.normalBgd
        : 'none',
    backgroundColor: (props: FramoButtonProps) =>
      props.disabled
        ? theme.palette.button.disabled
        : props.submissionState === 'error'
        ? theme.palette.error.main
        : props.submissionState === 'submitted'
        ? theme.palette.success.main
        : props.disabled
        ? theme.palette.button.active
        : props.buttonStyle === 'styleOutlined'
        ? theme.palette.button.normalAltBgd
        : theme.palette.button.normalBgd,
    color: (props: FramoButtonProps) =>
      props.disabled
        ? theme.palette.text.disabled
        : props.submissionState === 'error' || props.submissionState === 'submitted'
        ? theme.palette.button.text
        : props.buttonStyle === 'styleOutlined'
        ? theme.palette.button.textAlt
        : theme.palette.button.text,
    '&:focus': {
      boxShadow: theme.palette.boxShadows.focus,
      backgroundColor: (props: FramoButtonProps) =>
        props.disabled
          ? theme.palette.button.disabled
          : props.submissionState === 'error'
          ? theme.palette.error.main
          : props.submissionState === 'submitted'
          ? theme.palette.success.main
          : props.disabled
          ? theme.palette.button.active
          : props.buttonStyle === 'styleOutlined'
          ? theme.palette.button.normalAltBgd
          : theme.palette.button.highlight,
      color: (props: FramoButtonProps) =>
        props.disabled
          ? theme.palette.text.disabled
          : props.submissionState === 'error' || props.submissionState === 'submitted'
          ? theme.palette.button.text
          : props.buttonStyle === 'styleOutlined'
          ? theme.palette.button.highlight
          : theme.palette.button.text,
      borderColor: (props: FramoButtonProps) =>
        props.disabled
          ? theme.palette.button.disabled
          : props.submissionState === 'error'
          ? theme.palette.error.main
          : props.submissionState === 'submitted'
          ? theme.palette.success.main
          : props.buttonStyle === 'styleOutlined'
          ? theme.palette.button.highlight
          : 'none',
    },

    '&:hover': {
      boxShadow: (props: FramoButtonProps) =>
        props.disabled ? 'none' : theme.palette.boxShadows.dark,
      backgroundColor: (props: FramoButtonProps) =>
        props.disabled
          ? theme.palette.button.disabled
          : props.disabled
          ? theme.palette.button.active
          : props.buttonStyle === 'styleOutlined'
          ? theme.palette.button.highlightAlt
          : theme.palette.button.highlight,
      color: (props: FramoButtonProps) =>
        props.disabled
          ? theme.palette.text.disabled
          : props.buttonStyle === 'styleOutlined'
          ? theme.palette.button.highlight
          : theme.palette.button.text,
      borderColor: (props: FramoButtonProps) =>
        props.disabled
          ? theme.palette.button.disabled
          : props.buttonStyle === 'styleOutlined'
          ? theme.palette.button.highlight
          : 'none',
    },

    /**
     * Material UI compiles unique classes based on propositional logic.
     * These are appended last, which increases specificity of these classes.
     * Therefore, the following reduntant code because !important is not smart.
     **/
    '&:active': {
      backgroundColor: (props: FramoButtonProps) =>
        props.disabled
          ? theme.palette.button.disabled
          : props.buttonStyle === 'styleOutlined'
          ? theme.palette.button.active
          : theme.palette.button.active,
      color: (props: FramoButtonProps) =>
        props.disabled
          ? theme.palette.text.disabled
          : props.buttonStyle === 'styleOutlined'
          ? theme.palette.button.text
          : theme.palette.button.text,
      borderColor: (props: FramoButtonProps) =>
        props.disabled
          ? theme.palette.button.disabled
          : props.buttonStyle === 'styleOutlined'
          ? theme.palette.button.active
          : 'none',
    },
  },
  sizeMini: {
    height: theme.spacing(5),
    width: 'fit-content',
  },
  sizeNormal: {
    height: theme.spacing(6),
    width: 'fit-content',
  },
}));

export interface FramoButtonProps {
  handleClick?: (ev?: any) => any;
  buttonStyle?: 'styleFilled' | 'styleOutlined';
  buttonSize?: 'sizeMini' | 'sizeNormal';
  type?: 'button' | 'reset' | 'submit';
  disabled?: boolean;
  form?: string;
  submissionState?: SubmissionState;
  className?: string;
}

const FramoButton = (props: PropsWithChildren<FramoButtonProps>) => {
  const { handleClick, buttonStyle, buttonSize, className, children, submissionState, ...rest } =
    props;
  const classes = useStyles(props);
  return (
    <button
      className={`${classes.root} ${className} ${
        buttonSize ? classes[buttonSize] : classes['sizeNormal']
      } `}
      onClick={handleClick}
      {...rest}
    >
      <div>
        <Typography component={'span'} variant='body2'>
          {children}
        </Typography>
      </div>
    </button>
  );
};

export default FramoButton;
