import React from 'react';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import { IconHandler } from '../util';
import { FormStatus } from '../../api';
import { IconIdentifierType } from '../../models';

const getColor = (chipStatus: FormStatus, theme: Theme) => {
  switch (chipStatus) {
    case 'Normal':
      return theme.palette.success.main;
    case 'Critical':
      return theme.palette.error.main;
    case 'Marginal':
      return theme.palette.warning.main;
    case 'Empty':
      return theme.palette.primary.main;
    default:
      return theme.palette.primary.main;
  }
};

interface StatusCellProps {
  label: string;
  status: FormStatus;
  showIcon: boolean;
  iconOverride?: IconIdentifierType;
  iconPlacement?: 'overflow' | 'suffix';
}

const useStyles = makeStyles((theme) => ({
  box: (props: StatusCellProps) => ({
    borderRadius: theme.spacing(0.3),
    display: 'inline-flex',
    borderStyle: 'solid',
    border: 1,
    borderColor: getColor(props.status, theme),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    position: 'relative',
  }),

  text: {
    fontWeight: 'bold',
  },
  iconOverflow: (props: StatusCellProps) => ({
    position: 'absolute',
    height: '12px',
    width: '12px',
    zIndex: 1,
    backgroundColor: getColor(props.status, theme),
    borderRadius: '50%',
    top: -6,
    left: -6,
  }),
  iconSuffix: (props: StatusCellProps) => ({
    height: '20px',
    width: '20px',
    marginLeft: theme.spacing(2),
    marginTop: '2px',
  }),
}));

const getIcon = (status: FormStatus, iconOverride?: IconIdentifierType) => {
  switch (status) {
    case 'Critical':
    case 'Marginal':
      return 'important';
    case 'Normal':
      return 'check';
    default:
      return iconOverride;
  }
};

const StatusCell = ({ label, status, showIcon, iconOverride, iconPlacement }: StatusCellProps) => {
  const classes = useStyles({ label, status, showIcon, iconOverride, iconPlacement });

  const icon = getIcon(status, iconOverride);
  return (
    <div className={classes.box}>
      <Typography variant='body1' className={classes.text}>
        {label}
      </Typography>
      {icon && showIcon && (
        <IconHandler
          iconIdentifier={icon}
          className={iconPlacement === 'suffix' ? classes.iconSuffix : classes.iconOverflow}
        />
      )}
    </div>
  );
};

export default StatusCell;
