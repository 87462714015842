import { GridCellParams } from '@material-ui/data-grid';
import { useHistory } from 'react-router-dom';
import FramoAction from '../../../components/buttons/FramoAction';
import React from 'react';
import { useApiClient } from '../../../api';
import { Divider, makeStyles } from '@material-ui/core';
import { useLoading } from '../../../hooks';
import YesNoModal from '../../../modals/YesNoModal';
import { useModalContext } from '../../../contexts';

const useStyles = makeStyles((theme) => ({
  actionButtons: {
    margin: theme.spacing(0, 1),
  },
  divider: {
    margin: theme.spacing(1),
  },
}));

const ExternalLabsRowActionButtons = (props: GridCellParams) => {
  const history = useHistory();
  const client = useApiClient();
  const classes = useStyles();
  const { dispatch } = useLoading();
  const { setIsOpen, setModalProps, setContent } = useModalContext();

  const submitDeletion = async () => {
    try {
      await client.deleteExternalLab(props.row.id);
      window.location.reload();
    } catch (error) {
      console.error(error);
      const errorJson = await error.json();
      dispatch({
        type: 'ERROR',
        error: errorJson.message || 'Something went wrong',
        toast: errorJson.message || 'Failed to delete external lab',
      });
    }
  };

  const handleDeleteClick = () => {
    setModalProps({
      title: 'Deletion',
    });
    setContent(
      <YesNoModal
        header={'Are you sure you want to delete this external lab?'}
        yesAction={submitDeletion}
        quitModal={() => setIsOpen(false)}
      />,
    );
    setIsOpen(true);
  };

  return (
    <>
      <FramoAction
        colorVariant='secondary'
        handleClick={() => history.push(`/admin/externalLabs/add/${props.row.id}`)}
      >
        Edit
      </FramoAction>
      <Divider className={classes.divider} orientation='vertical' flexItem />
      <FramoAction colorVariant='secondary' handleClick={handleDeleteClick}>
        Delete
      </FramoAction>
    </>
  );
};

export default ExternalLabsRowActionButtons;
