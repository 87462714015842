import React from 'react';
import AdminMenu from '../../../components/menus/AdminMenu';

const AdminPage = () => {
  return (
    <>
      <AdminMenu />
    </>
  );
};

export default AdminPage;
