import React from 'react';
import {
  CircularProgress,
  InputBase,
  InputBaseProps,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { FieldSize } from '../../models/components';
import { calculateFieldSize } from '../../utils/inputfieldUtils';
import { ImportantIcon } from '../../assets/icons';
import { Severity } from '../../models';

const useStyles = makeStyles((theme) => ({
  notificationIcon: {
    position: 'absolute',
    top: '-7px',
    right: '-7px',
    borderRadius: '100%',
    background: (props: FlsInputProps) =>
      props.severity === 'Marginal'
        ? theme.palette.warning.main
        : props.severity === 'Critical' || props.severity === 'Error'
        ? theme.palette.error.main
        : theme.palette.primary.main,
  },
  iconContainer: {
    alignItems: 'center',
    border: 'none',
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: theme.spacing(0.5),
  },
  //Work in progress, trying to normalize styling according to design across all input fields
  '.MuiInputBase-root': {
    ...theme.typography.body1,
  },
  inputField: {
    //...theme.typography.body1,
    backgroundColor: (props: FlsInputProps) =>
      props.disabled
        ? theme.palette.button.disabled
        : props.colorVariant === 'secondary'
        ? theme.palette.background.paper
        : theme.palette.background.default,
    color: theme.palette.text.primary,
    width: '100%',
    '&:focus': {
      color: theme.palette.text.secondary,
    },
    '&[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  inputContainer: {
    alignItems: 'inherit',
    backgroundColor: (props: FlsInputProps) =>
      props.disabled
        ? theme.palette.button.disabled
        : props.colorVariant === 'secondary'
        ? theme.palette.background.paper
        : theme.palette.background.default,
    borderWidth: '1px',
    borderStyle: (props: FlsInputProps) => (props.disableOutline ? 'none' : 'solid'),
    borderColor: (props: FlsInputProps) =>
      props.disabled
        ? theme.palette.button.disabled
        : props.severity === 'Marginal'
        ? theme.palette.warning.main
        : props.severity === 'Critical' || props.severity === 'Error'
        ? theme.palette.error.main
        : theme.palette.background.default,
    borderRadius: (props: FlsInputProps) =>
      props.dropDownOpen ? theme.spacing(0.75, 0.75, 0, 0) : theme.spacing(0.75),
    height: (props: FlsInputProps) => (props.multiline ? 'inherit' : '48px'),
    padding: theme.spacing(1, 1.5),
    //Defaults to md if no size is set. No maxWidht when fullWidth is set
    width: (props: FlsInputProps) =>
      calculateFieldSize(
        props.fullWidth ? 'fullWidth' : props.fieldSize,
        !!props.icon || !!props.suffix,
      ),
    '&:focus-within': {
      borderColor: (props: FlsInputProps) => (props.severity ? '' : theme.palette.primary.main),
    },
  },
  suffix: {
    color: theme.palette.text.secondary,
  },
}));

export interface FlsInputProps extends InputBaseProps, FieldSize {
  icon?: React.ReactNode;
  suffix?: string; //Places a text string after the input values
  disableOutline?: boolean; //Disable focus and error outline
  loading?: boolean; //Spinner icon will be activated if true
  dropDownOpen?: boolean; //If used in a dropdownsetting, this will set correct borderRadius
  colorVariant?: string; //Sets the back
  severity?: Severity;
  showNotificationIcon?: boolean;
}

/**
 * Component which handle text and numeric input. The field have various
 * size settings, but defaults to medium if none is supplied. In addition this component can
 * act as a textarea by suppling "multiline", "rows={number}" and maxRows={number} as props.
 * Supports icons, suffixes and a loading state.
 */
const FlsField = (props: FlsInputProps) => {
  const {
    fieldSize,
    icon,
    suffix,
    disableOutline,
    loading,
    dropDownOpen,
    colorVariant,
    severity,
    showNotificationIcon,
    ...inputProps
  } = props;
  const classes = useStyles(props);

  return (
    <InputBase
      autoComplete='off'
      classes={{
        root: classes.inputContainer,
        input: classes.inputField,
      }}
      endAdornment={
        <>
          {loading && (
            <div className={classes.iconContainer}>
              <CircularProgress color='inherit' size={20} />
            </div>
          )}
          {suffix && (
            <div className={classes.iconContainer}>
              <Typography className={classes.suffix}>{suffix}</Typography>
            </div>
          )}
          {icon && <div className={classes.iconContainer}>{icon}</div>}
          {showNotificationIcon && <ImportantIcon className={classes.notificationIcon} />}
        </>
      }
      aria-describedby={`${props.id}-text`}
      {...inputProps}
    />
  );
};

export default FlsField;
