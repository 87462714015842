import React from 'react';
import { Button, Typography, Box, Theme, makeStyles, CircularProgress } from '@material-ui/core';
import { UploadIcon } from '../../assets/icons';
import { constructFormData } from '../../utils/uploadUtil';
import { useApiClient } from '../../api';
import { useLoading } from '../../hooks';
import { SubmissionState } from '../../models';
import { IconHandler } from '../util';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: 'none',
  },
  uploadPanel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  //To be elaborated after design consulting
  button: {
    display: 'flex',
    justifyContent: 'center',
    textTransform: 'none',
    alignItems: 'center',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    transition: '0.3s',
    '&:hover, &:focus': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      '& > *': {
        '& > *': {
          '& > *': {
            color: (props: StylingProp) => !props.state && theme.palette.button.highlight,
            '& > *': {
              stroke: (props: StylingProp) => !props.state && theme.palette.button.highlight,
            },
          },
        },
      },
    },
  },
  iconContainer: {
    padding: theme.spacing(2),
  },
  input: {
    display: 'none',
  },
  icon: {
    transition: '0.3s',
    width: '42px',
    height: '42px',
    '& > *': {
      stroke: (props: StylingProp) =>
        props.state === 'submitted'
          ? theme.palette.success.main
          : props.state === 'error'
          ? theme.palette.error.main
          : props.state === 'pending'
          ? theme.palette.primary.main
          : theme.palette.text.primary,
    },
  },
  iconText: {
    paddingTop: theme.spacing(2),
    color: (props: StylingProp) =>
      props.state === 'submitted'
        ? theme.palette.success.main
        : props.state === 'error'
        ? theme.palette.error.main
        : theme.palette.primary.main,
  },
  stateIcons: {
    width: '42px',
    height: '42px',
  },
}));

interface StylingProp {
  state: SubmissionState;
}

interface FramoUploadBoxProps {
  fileAccept: string;
  multipleFiles?: boolean;
  onSuccess?: () => void;
}

const ActiveIcon = (props: StylingProp) => {
  const classes = useStyles({ state: props.state });
  switch (props.state) {
    case 'pending': {
      return <CircularProgress size={42} />;
    }
    case 'submitted': {
      return <IconHandler className={classes.icon} iconIdentifier='check' />;
    }
    case 'error': {
      return <IconHandler className={classes.icon} iconIdentifier='close' />;
    }
    default: {
      return <UploadIcon className={classes.icon} />;
    }
  }
};

const FramoUploadBox = (props: FramoUploadBoxProps) => {
  const { fileAccept, multipleFiles } = props;
  const client = useApiClient();
  const { state: uploadState, dispatch: dispatchUpload } = useLoading();
  const classes = useStyles({ state: uploadState.state });

  const handleChange = async (event) => {
    dispatchUpload({ type: 'REQUEST' });
    const files = event.target.files;
    const processedFiles = constructFormData({ files });
    try {
      await client.postFileForParsing(processedFiles);
      dispatchUpload({ type: 'SUCCESS', toast: 'Succesfully parsed the uploaded files' });
      if (props.onSuccess) {
        props.onSuccess();
      }
    } catch (error) {
      if (!error.text) {
        dispatchUpload({
          type: 'ERROR',
          error: error,
          toast: 'Unknown error when parsing files',
        });
      }
      const errorMessage = await error.text();
      const errorObject = JSON.parse(errorMessage);
      dispatchUpload({
        type: 'ERROR',
        error: error,
        toast: (
          <div>
            <p style={{ marginTop: '0px' }}>Some files was not parsed:</p>
            {errorObject &&
              errorObject.fileProcessErrors.map((err, key) => {
                return <p key={key}>{err}</p>;
              })}
          </div>
        ),
      });
      if (props.onSuccess) {
        props.onSuccess();
      }
    }
  };

  return (
    <Box className={classes.iconContainer}>
      <label htmlFor='contained-button-file' className={classes.root}>
        <input
          className={classes.input}
          id='contained-button-file'
          accept={fileAccept}
          multiple={multipleFiles}
          onChange={handleChange}
          type='file'
        />
        <Button variant='contained' component='span' className={classes.button}>
          <div className={classes.uploadPanel}>
            <ActiveIcon state={uploadState.state} />
            <Typography variant='body2' className={classes.iconText}>
              Upload
            </Typography>
          </div>
        </Button>
      </label>
    </Box>
  );
};

export default FramoUploadBox;
