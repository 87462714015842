import React, { PropsWithChildren } from 'react';
import { IconButton, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: 0,
    '& > *': {
      //Span
      width: theme.spacing(5),
      height: theme.spacing(5),
      transition: '0.3s',
      borderRadius: '4px',
      '& > *': {
        //Fill
        fill: 'rgba(0,0,0,0)',
        '& > *': {
          // Stroke
          transition: '0.1s',
          stroke: theme.palette.icons.primary,
        },
      },
    },
    '&:hover': {
      '& > *': {
        backgroundColor: theme.palette.icons.background,
        boxShadow: 'inset 2px 2px 6px rgba(0, 0, 0, 0.24)',
      },
    },
    '&:active': {
      '& > *': {
        backgroundColor: theme.palette.icons.background,
        boxShadow: 'inset 2px 2px 6px rgba(0, 0, 0, 0.24)',
        '& > *': {
          '& > *': {
            stroke: theme.palette.icons.secondary,
          },
        },
      },
    },
  },
}));

interface FramoIconProps {
  handleClick?: () => void;
  ariaLabel?: string;
  className?: string;
}

const FramoIcon = (props: PropsWithChildren<FramoIconProps>) => {
  const { ariaLabel, handleClick, className, children } = props;
  const classes = useStyles();
  return (
    <IconButton
      className={clsx(classes.root, className)}
      onClick={handleClick}
      aria-label={ariaLabel}
      style={{ backgroundColor: 'transparent' }}
    >
      {children}
    </IconButton>
  );
};

export default FramoIcon;
