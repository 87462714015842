import React, { FormEvent, MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import {
  Backdrop,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Link,
  makeStyles,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import {
  FramoAction,
  FramoButton,
  FramoIconBox,
  FramoButtonWithLoading,
} from '../../components/buttons';
import {
  VesselModal,
  PrintPreview,
  Finalization,
  ExternalLab,
  UploadDocumentationModal,
} from '../../modals';
import { useHistory, useParams } from 'react-router-dom';
import { useApiClient, Record, FlsFile, FormResultBuilder, FormFieldValueBuilder } from '../../api';
import { useModalContext, useSampleContext } from '../../contexts';
import { FlsCard } from '../../components/card';
import { FlsModal } from '../../components/modal';
import { mergeRecords } from '../../utils';
import { Form, FlsGrid } from './dynamicForms';
import { FlsList, InformationBar } from '../../components/lists';
import { FlsLabel, IconHandler } from '../../components/util';
import { flsToast } from '../../components/util/FlsToast';
import { FlsRadio } from '../../components/inputs';
import { useLoading } from '../../hooks';
import { useDebouncedCallback } from 'use-debounce/lib';
import DotsMenu, { DotsMenuItemProps } from '../../menus/DotsMenu';
import { WarningMessages } from '../../texts/WarningMessages';
import SampleRevisionLabel from '../../components/util/RevisionLabel';
import { revisionNumberToNameConverter } from '../../utils/revisionUtils';
import YesNoModal from '../../modals/YesNoModal';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    color: '#fff',
    zIndex: theme.zIndex.drawer + 1,
  },
  backdropContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  buttonContainer: {
    padding: theme.spacing(2),
  },
  pageContainer: {
    padding: theme.spacing(6),
    paddingTop: theme.spacing(3),
  },
  actionButtons: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > * ': {
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    '& > :last-child': {
      marginRight: 0,
    },
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
      width: '100%',
      justifyContent: 'space-between',
      '& > * ': {
        marginRight: '0px',
        marginTop: theme.spacing(1),
      },
    },
  },
  listContainer: {
    width: '100%',
    textAlign: 'left',
    paddingTop: theme.spacing(4),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(2),
  },
  radioGroup: {
    marginLeft: theme.spacing(2),
  },
  uploadButton: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
      '& > svg': {
        stroke: theme.palette.icons.secondary,
        '& > *': {
          stroke: 'inherit',
        },
      },
    },
  },
  uploadIcon: {
    marginRight: theme.spacing(1),
    height: '20px',
    '&:hover': {},
  },
  uploadTitle: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(0.5),
  },
  standaloneCard: {
    marginBottom: theme.spacing(2),
  },
  mandatory: {
    maxWidth: '366px',
    width: '100%',
  },
  dynamicFormContainer: {
    flexWrap: 'nowrap',
  },
  form: {
    width: '100%',
    display: 'flex',
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      flexWrap: 'noWrap',
      flexDirection: 'column',
      '& > *': {
        flex: '50%',
      },
    },
  },
  actionRow: {
    flexBasis: '298px',
    flexShrink: 0,
  },
  infobarContainer: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      '& > *': {
        marginTop: theme.spacing(2),
      },
    },
    alignItems: 'end',
    justifyContent: 'space-between',
  },
  infoContainer: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  mobileGrid: {
    display: 'flex',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      flexDirection: 'column-reverse',
    },
  },
  globalNavigation: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  warningText: {
    color: theme.palette.warning.main,
  },
}));

const AnalysesPage = () => {
  const history = useHistory();
  const classes = useStyles();
  const client = useApiClient();
  const { sample, setSampleId, patchSample, sampleLoading } = useSampleContext();
  const { urlSampleId } = useParams<{ urlSampleId: string }>();
  const [documents, setDocuments] = useState<FlsFile[]>([]);
  const [parsedFiles, setParsedFiles] = useState<FlsFile[]>([]);
  const downloadLinkRef = useRef<HTMLAnchorElement>(null);
  const [recordLoading, setRecordLoading] = useState<{ [key: string]: boolean }>();
  const { setIsOpen, setModalProps, setContent } = useModalContext();
  const [records, setRecords] = useState<Record[]>([]);
  const [finalizedOn, setFinalizedOn] = useState<Date>();
  const [isRecordsLoading, setIsRecordsLoading] = useState<boolean>(true);
  const [didFormChange, setDidFormChange] = useState<boolean>(false);
  const [formResultBuilders, setFormResultBuilders] = useState<FormResultBuilder[]>([]);
  const [formFieldValueBuilders, setformFieldValueBuilders] = useState<FormFieldValueBuilder[]>([]);
  const [dotsMenuItems, setDotsMenuItems] = useState<DotsMenuItemProps[]>([]);
  const { dispatch: deleteFileDispatch, state: deleteFileState } = useLoading();
  const { dispatch: submitDispatch, state: submitState } = useLoading();

  async function fetchDocuments() {
    try {
      if (urlSampleId) {
        const documents = await client.getDocumentationFiles(urlSampleId);
        setDocuments(documents);
      }
    } catch (error) {
      console.error(error);
      setDocuments([]);
    }
  }

  async function fetchParsedFiles() {
    try {
      if (urlSampleId) {
        const files = await client.getParsedFiles(urlSampleId);
        setParsedFiles(files);
      }
    } catch (error) {
      console.error(error);
      setParsedFiles([]);
    }
  }

  const updateRecordLoading = (key: string, value: boolean) => {
    setRecordLoading((previousData) => ({ ...previousData, [key]: value }));
  };

  const handleModalAnalysis = () => {
    setModalProps({
      title: 'Edit Test',
      titleIcon: undefined,
    });
    setContent(<VesselModal showTests />);
    setIsOpen(true);
  };
  const handleModalPrint = () => {
    setModalProps({
      title: 'Preview Label',
      titleIcon: undefined,
    });
    setContent(<PrintPreview />);
    setIsOpen(true);
  };

  const handleUploadModal = () => {
    setModalProps({
      title: 'Upload file',
      titleIcon: undefined,
      onClose: () => {
        fetchDocuments();
      },
    });
    setContent(
      <UploadDocumentationModal
        forms={sample?.forms.map((form) => form.analysisType) || []}
        existingFileNames={[]}
      />,
    );
    setIsOpen(true);
  };

  const handleModalExternalLab = () => {
    setModalProps({
      title: `Register sample ${sample?.shortId || '<No sample found>'} for external lab`,
    });
    setContent(<ExternalLab />);
    setIsOpen(true);
  };
  const handleModalFinalization = async () => {
    let warningMessage = '';
    if (didFormChange) {
      warningMessage = 'Warning: unsaved changes in the form';
    }
    setModalProps({
      title: 'Finalization',
    });
    setContent(
      <Finalization
        finalizedOn={finalizedOn}
        setFinalizedOn={setFinalizedOn}
        warningMessage={warningMessage}
      />,
    );
    setIsOpen(true);
  };
  const handleDeleteClick = async () => {
    setModalProps({
      title: 'Deletion',
    });
    setContent(
      <YesNoModal
        header={'Are you sure you want to delete this sample?'}
        yesAction={submitDeletion}
        quitModal={() => setIsOpen(false)}
      />,
    );
    setIsOpen(true);
  };

  const submitDeletion = async () => {
    try {
      if (sample?.id == null) {
        flsToast.error('Sample does not have an Id');
        return;
      }
      await client.markDeletedSampleById(sample?.id);
      setIsOpen(false);
      history.push('/');
    } catch (error) {
      console.error(error);
      flsToast.error('Error when trying to delete sample');
    }
  };

  const handleUnArchiveClick = async () => {
    try {
      if (sample?.id === undefined) {
        flsToast.error('Sample does not have an Id');
        return;
      }
      await client.unArchiveSampleById(sample?.id);
      window.location.reload();
    } catch (error) {
      console.error(error);
      flsToast.error('Error when trying to delete sample');
    }
  };

  const handleCreateRevision = async () => {
    try {
      if (sample?.id === undefined) {
        flsToast.error('Sample does not have an Id');
        return;
      }
      const revisedSample = await client.reviseSampleById(sample?.id);
      history.push('/analyses/' + revisedSample.id);
      window.location.reload();
    } catch (error) {
      console.error(error);
      flsToast.error('Error when trying to revise a sample');
    }
  };

  const downloadFile = async (fileName, filePath) => {
    if (!downloadLinkRef || !downloadLinkRef.current) {
      return;
    }
    const blob = await client.downloadFile(urlSampleId, filePath);

    if (!blob) {
      return;
    }
    const href = window.URL.createObjectURL(blob);
    const a = downloadLinkRef.current;
    const contentDisposition = fileName;
    a.download = contentDisposition;
    a.href = href;
    a.click();
    a.href = '';
  };

  const deleteFile = async (fileName, filePath) => {
    try {
      deleteFileDispatch({ type: 'REQUEST' });
      await client.deleteDocumentation(urlSampleId, filePath);
      await fetchDocuments();
      deleteFileDispatch({ type: 'SUCCESS', toast: `Successfully deleted ${fileName}` });
    } catch (error) {
      console.error(error);
      deleteFileDispatch({
        type: 'ERROR',
        error: 'Unable to delete file',
        toast: 'Unable to delete file',
      });
    }
  };

  useEffect(() => {
    fetchDocuments();
    fetchParsedFiles();
  }, [urlSampleId]);

  //Retrieve a sample based on url.
  React.useEffect(() => {
    if (!sample && urlSampleId !== '') {
      setSampleId(urlSampleId);
    }
  }, [sample, setSampleId, urlSampleId]);

  const changeSample = useCallback((sampleId) => {
    setSampleId(sampleId);
    history.push('/analyses/' + sampleId);
  }, []);

  React.useEffect(() => {
    if (sample?.finalizedOn) {
      setFinalizedOn(sample.finalizedOn);
    }

    if (sample?.state === 'Finalized') {
      setDotsMenuItems([
        { name: 'Delete', key: 'Delete', onClick: handleDeleteClick },
        { name: 'Un-archive', key: 'Un-archive', onClick: handleUnArchiveClick },
        { name: 'Create revision', key: 'Create revision', onClick: handleCreateRevision },
      ]);
    } else {
      setDotsMenuItems([{ name: 'Delete', key: 'Delete', onClick: handleDeleteClick }]);
    }

    const fetchData = async () => {
      const frb: FormResultBuilder[] = [];
      const formsToCalculateResult = sample?.forms?.filter((x) => x.calculateResult === true);
      for (const x of formsToCalculateResult || []) {
        const resultBuilders = await client.getFormResultBuilders(x.id);
        frb.push(...resultBuilders);
      }

      setFormResultBuilders(frb);
    };

    fetchData();
  }, [sample]);

  useEffect(() => {
    if (formFieldValueBuilders.length !== 0) {
      return;
    }

    const fetchData = async () => {
      const resultBuilders = await client.getFormFieldValueBuilders();
      setformFieldValueBuilders(resultBuilders);
    };

    fetchData();
  }, []);

  //Whenever a form is retrieving a record, it will append its form ID and loading status to recordLoading. If all loadingstates are false, we consider it safe to save.
  // As this loading queue might happen in a bit of a random order, to avoid the buttons flickering, debounce the final set call.
  const updateLoadingState = useDebouncedCallback((isLoading: boolean) => {
    setIsRecordsLoading(isLoading);
  }, 500);

  useEffect(() => {
    if (recordLoading) {
      updateLoadingState(Object.values(recordLoading).indexOf(true) > -1);
    }
  }, [recordLoading]);

  const setDidFormChangeOneWay = async (formChanged: boolean) => {
    if (didFormChange) {
      return;
    }

    setDidFormChange(formChanged);
  };

  const onFormSubmit = async (event?: FormEvent<HTMLFormElement>) => {
    if (event) {
      event.preventDefault();
    }
    submitDispatch({ type: 'REQUEST' });
    //Merge records by form
    const mergedRecords = mergeRecords(records);
    const promises = Object.values(mergedRecords)
      .filter((record: Record) => record.recordValues && record.recordValues.length > 0)
      .map(async (record) => {
        return await client.postRecord(record);
      });
    try {
      await Promise.all(promises);
      submitDispatch({ type: 'SUCCESS' });
      flsToast.success('Records saved successfully');
      setDidFormChange(false);
      return true;
    } catch (e) {
      console.error(e);
      submitDispatch({
        type: 'ERROR',
        toast: `Unable to save records. Message from server: ${e}`,
        error: `Unable to save records. Message from server: ${e}`,
      });
      return false;
    }
  };
  return (
    <>
      <Container maxWidth='xl' className={classes.pageContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className={classes.globalNavigation}>
              <FramoAction
                type='button'
                colorVariant='secondary'
                handleClick={() => history.push('/')}
                iconIdentifier='back'
              >
                Return to dashboard
              </FramoAction>
              <FramoAction
                disabled={urlSampleId ? false : true}
                type='button'
                colorVariant='secondary'
                handleClick={() => history.push(`/createSample/${urlSampleId}`)}
                iconIdentifier='forward'
                iconRight
              >
                Sample Info
              </FramoAction>
            </div>
          </Grid>
          <Grid item xs={12} style={{ flexWrap: 'nowrap' }}>
            <Typography variant='h1'>
              Results: {sample?.shortId} <SampleRevisionLabel />
            </Typography>
            {sample?.revised && (
              <Typography className={classes.warningText}>
                {WarningMessages.DocumentRevised}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <div className={classes.infobarContainer}>
              <div className={classes.infoContainer}>
                <InformationBar
                  values={[
                    {
                      key: 'Installation',
                      label: 'Installation',
                      value: sample?.installationName ? sample.installationName : ' - ',
                    },
                    {
                      key: 'Oil Type',
                      label: 'Oil Type',
                      value: sample?.oilType ? sample.oilType : ' - ',
                    },
                    {
                      key: 'originalOilType',
                      value: sample?.originalOilType || 'Not yet assigned',
                      label: 'Original Oil Type',
                    },
                    {
                      key: 'ompDocNr',
                      value: sample?.ompDocNr || 'Not yet assigned',
                      label: 'OMP Document nr.',
                    },
                    {
                      key: 'finalizedOn',
                      value: sample?.finalizedOn
                        ? new Date(sample.finalizedOn.toString()).toDateString()
                        : 'Not finalized',
                      label: 'Finalized On',
                    },
                  ]}
                />
                <div className={classes.radioGroup}>
                  <FlsLabel htmlFor='invalidSample'>Invalid Sample</FlsLabel>
                  <RadioGroup
                    row
                    id={'invalidSample'}
                    value={sample?.invalidSample || false}
                    onChange={(e, value) => patchSample('invalidSample', value === 'true')}
                  >
                    <div style={{ display: 'flex' }}>
                      <FlsRadio
                        label={'Yes'}
                        value={true}
                        disabled={
                          sampleLoading || (!(sample?.invalidSample || false) && sample?.revised)
                        }
                      />
                      <FlsRadio
                        label={'No'}
                        value={false}
                        disabled={
                          sampleLoading || ((sample?.invalidSample || false) && sample?.revised)
                        }
                      />
                    </div>
                  </RadioGroup>
                </div>
                <div className={classes.radioGroup}>
                  <FlsLabel htmlFor='microscope'>Microscope</FlsLabel>
                  <RadioGroup
                    row
                    id={'microscope'}
                    value={sample?.microscope || false}
                    onChange={(e, value) => patchSample('microscope', value === 'true')}
                  >
                    <div style={{ display: 'flex' }}>
                      <FlsRadio
                        label={'Yes'}
                        value={true}
                        disabled={
                          sampleLoading || (!(sample?.microscope || false) && sample?.revised)
                        }
                      />
                      <FlsRadio
                        label={'No'}
                        value={false}
                        disabled={
                          sampleLoading || ((sample?.microscope || false) && sample?.revised)
                        }
                      />
                    </div>
                  </RadioGroup>
                </div>
              </div>
              <div className={classes.actionButtons}>
                <FramoButton
                  handleClick={handleModalAnalysis}
                  buttonStyle='styleOutlined'
                  type='button'
                  disabled={submitState.state === 'pending' || isRecordsLoading || sample?.revised}
                >
                  Add/Edit Tests
                </FramoButton>
                <FramoButtonWithLoading
                  buttonStyle='styleOutlined'
                  type='submit'
                  form='recordValues'
                  submissionState={submitState.state}
                  disabled={
                    submitState.state === 'pending' ||
                    isRecordsLoading ||
                    !didFormChange ||
                    sample?.revised
                  }
                >
                  Save
                </FramoButtonWithLoading>
                <FramoButton
                  disabled={submitState.state === 'pending' || isRecordsLoading || sample?.revised}
                  submissionState={finalizedOn && 'submitted'}
                  handleClick={handleModalFinalization}
                >
                  Finalize
                </FramoButton>
                <DotsMenu
                  items={dotsMenuItems}
                  disabled={submitState.state === 'pending' || isRecordsLoading || sample?.revised}
                />
              </div>
            </div>
          </Grid>
          <Grid container item xs={12} className={classes.dynamicFormContainer}>
            <div className={classes.mobileGrid}>
              <form onSubmit={onFormSubmit} id='recordValues' className={classes.form}>
                <FlsGrid>
                  {sample &&
                    sample.forms &&
                    sample.forms
                      .filter((form) => !form.isMandatory)
                      .sort((form1, form2) => form1.sortOrder - form2.sortOrder)
                      .map((form, index) => (
                        <Form
                          formResultBuilders={formResultBuilders.filter(
                            (x) => x.formId === form.id,
                          )}
                          formId={form.id}
                          setRecords={setRecords}
                          sampleId={urlSampleId}
                          key={form.id}
                          updateRecordLoading={updateRecordLoading}
                          setDidFormChange={setDidFormChangeOneWay}
                          disabled={sample?.revised}
                          formFieldValueBuilders={formFieldValueBuilders}
                        />
                      ))}
                </FlsGrid>
                <div className={classes.mandatory}>
                  {sample &&
                    sample.forms &&
                    sample.forms
                      .sort((form1, form2) => form1.sortOrder - form2.sortOrder)
                      .filter((form) => form.isMandatory)
                      .map((form) => (
                        <div key={form.id} style={{ marginBottom: '16px' }}>
                          <Form
                            formId={form.id}
                            setRecords={setRecords}
                            sampleId={urlSampleId}
                            updateRecordLoading={updateRecordLoading}
                            setDidFormChange={setDidFormChangeOneWay}
                            disabled={sample?.revised}
                          />
                        </div>
                      ))}
                </div>
              </form>
              <div className={classes.actionRow}>
                {sample?.revisions && sample?.revisions.length > 0 && (
                  <FlsCard className={classes.standaloneCard}>
                    <div className={classes.listContainer}>
                      <Typography variant='body1' className={classes.uploadTitle}>
                        Revisions
                      </Typography>
                      <Divider />
                      <FlsList
                        divider
                        items={
                          sample?.revisions
                            ? sample?.revisions.map((revision, index) => {
                                return {
                                  text: revisionNumberToNameConverter(index) || '',
                                  onItemClick: (ev: MouseEvent<HTMLLIElement>) => {
                                    changeSample(revision.sampleId);
                                  },
                                  confirmIconClick: true,
                                  selected: revision.sampleId === sample?.id,
                                  id: revision.sampleId,
                                };
                              })
                            : []
                        }
                      />
                    </div>
                  </FlsCard>
                )}
                {!sample?.revised && (
                  <FlsCard className={classes.standaloneCard}>
                    <FramoIconBox
                      iconIdentifier='print'
                      handleClick={handleModalPrint}
                      buttonText='Print Label'
                    />
                  </FlsCard>
                )}
                {!sample?.revised && (
                  <FlsCard className={classes.standaloneCard}>
                    <FramoIconBox
                      iconIdentifier='send'
                      handleClick={handleModalExternalLab}
                      buttonText={
                        sample?.externalLabSample &&
                        sample?.externalLabSample.externalLabTests.length > 0
                          ? 'Edit external lab request'
                          : 'Send sample to external lab'
                      }
                    />
                  </FlsCard>
                )}
                <FlsCard className={classes.standaloneCard}>
                  <div className={classes.listContainer}>
                    <Typography variant='body1' className={classes.uploadTitle}>
                      Documents
                    </Typography>
                    <Divider />
                    <FlsList
                      divider
                      items={
                        documents
                          ? documents.map((document) => {
                              return {
                                text: document.fileName || '',
                                onItemClick: (ev: MouseEvent<HTMLLIElement>) => {
                                  downloadFile(document.fileName, document.blobPath);
                                },
                                icon: 'delete',
                                onIconClick: (ev: MouseEvent<HTMLDivElement>) => {
                                  deleteFile(document.fileName, document.blobPath);
                                },
                                disabled: deleteFileState.state === 'pending',
                                confirmIconClick: true,
                                id: document.fileName,
                              };
                            })
                          : []
                      }
                    />
                    {
                      // eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content
                      <a ref={downloadLinkRef} tabIndex={-1} />
                    }
                    {!sample?.revised && (
                      <Link
                        onClick={(e) => {
                          e.preventDefault();
                          handleUploadModal();
                        }}
                        className={classes.uploadButton}
                        role='button'
                        href='#'
                      >
                        <IconHandler iconIdentifier='upload-small' className={classes.uploadIcon} />
                        Upload
                      </Link>
                    )}
                  </div>
                </FlsCard>
                <FlsCard className={classes.standaloneCard}>
                  <div className={classes.listContainer}>
                    <Typography variant='body1' className={classes.uploadTitle}>
                      Parsed Files
                    </Typography>
                    <Divider />
                    <FlsList
                      divider
                      items={
                        parsedFiles
                          ? parsedFiles.map((file) => {
                              return {
                                text: file.fileName || '',
                                onItemClick: (ev: MouseEvent<HTMLLIElement>) => {
                                  downloadFile(file.fileName, file.blobPath);
                                },
                                disabled: deleteFileState.state === 'pending',
                                confirmIconClick: true,
                                id: file.fileName,
                              };
                            })
                          : []
                      }
                    />
                    {
                      // eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content
                      <a ref={downloadLinkRef} tabIndex={-1} />
                    }
                  </div>
                </FlsCard>
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
      <Backdrop className={classes.backdrop} open={submitState.state === 'pending'}>
        <div className={classes.backdropContainer}>
          <CircularProgress color='inherit' />
          <Typography>Submitting...</Typography>
        </div>
      </Backdrop>
      <FlsModal />
    </>
  );
};

export default AnalysesPage;
