import { ExternalTests } from '../api';
import { difference } from './setUtils';

export function determineExternalTestGroup(tests: Set<string>, externalTests?: ExternalTests) {
  if (!externalTests) {
    return '-';
  }

  const isStandard =
    difference(
      tests,
      externalTests.standard.map((x) => x.value),
    ).size === 0;
  const isChloride =
    difference(
      tests,
      externalTests.chlorides.map((x) => x.value),
    ).size === 0;

  if (isStandard) {
    return 'Standard';
  }
  if (isChloride) {
    return 'Chloride';
  }
  return 'Custom';
}
