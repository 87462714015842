import { IconButton, Menu } from '@material-ui/core';
import React from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MenuItem from '@material-ui/core/MenuItem';

export interface DotsMenuItemProps {
  name: string;
  key: string;
  onClick: (ev: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
}
interface DotsMenuProps {
  items: DotsMenuItemProps[];
  disabled?: boolean;
}
const DotsMenu = (props: DotsMenuProps) => {
  const { items, disabled } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label='more'
        aria-controls='long-menu'
        aria-haspopup='true'
        onClick={handleClick}
        disabled={disabled}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id='long-menu'
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        {items.map((item) => (
          <MenuItem
            key={item.name}
            onClick={(e) => {
              handleClose();
              item.onClick(e);
            }}
          >
            {item.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
export default DotsMenu;
