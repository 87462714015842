import { PaletteOptions } from '@material-ui/core/styles/createPalette';

declare module '@material-ui/core/styles/createPalette' {
  export interface TypeBackground {
    default: string;
    menu: string;
    input: string;
    paper: string;
    paperElements: string;
    table: string;
    inactive: string;
  }
  export interface TypeBackgroundOptions {
    default?: string;
    menu?: string;
    input?: string;
    paper?: string;
    paperElements?: string;
  }
}

declare module '@material-ui/core/styles/createPalette' {
  export interface Palette {
    button: {
      normalBgd: string;
      normalAltBgd: string;
      text: string;
      textAlt: string;
      highlight: string;
      highlightAlt: string;
      active: string;
      radioOutline: string;
      disabled: string;
    };
    boxShadows: { dark: string; focus: string };
    icons: { primary: string; secondary: string; background: string };
    misc: { header: string };
  }
  export interface PaletteOptions {
    button: {
      normalBgd?: string;
      normalAltBgd?: string;
      text?: string;
      textAlt?: string;
      highlight?: string;
      highlightAlt?: string;
      active?: string;
      radioOutline?: string;
      disabled?: string;
    };
    boxShadows: { dark?: string; focus?: string };
    icons: { primary?: string; secondary?: string; background?: string };
    misc: { header?: string };
  }
}

//Named Colours
const FramoDarkBlue = '#0F1B26';
const FramoDeepBlue = '#1A2B3B';
const FramoShallowBlue = '#20374C';
const FramoCoal = '#1D2731';
const FramoSilver = '#B1B0B0';
const FramoWhite = '#F5F5F5';
const FramoBrightBlue = '#3399FF';
const FramoGoodGreen = '#06D6A0';
const FramoWarningYellow = '#FFCA3A';
const FramoAlarmRed = '#FA3947';
const FramoHighlightBright = '#47A3FF';
const FramoHighlightDark = '#102030';
const Framo = '#015794';
const FramoRadioOutline = '#B1B0B0';
const FramoInactiveBlue = '#455666';
//const FramoHighlight = '#2E4559'; Pending hover design rework

//Box Shadows
const BoxShadowDark = '4px 4px 12px rgba(0, 0, 0, 0.32)';
const BoxShadowActive = '4px 4px 12px rgba(51, 153, 255, 0.32)';

const paletteCommon = {
  success: {
    main: FramoGoodGreen,
  },
  warning: {
    main: FramoWarningYellow,
  },
  error: {
    main: FramoAlarmRed,
  },
};

export const DarkPalette = {
  type: 'dark',
  ...paletteCommon,
  primary: {
    main: FramoBrightBlue,
  },
  secondary: {
    main: FramoDarkBlue,
  },
  text: {
    primary: FramoWhite,
    secondary: FramoSilver,
  },
  button: {
    normalBgd: FramoBrightBlue,
    normalAltBgd: FramoDarkBlue,
    text: FramoDarkBlue,
    textAlt: FramoBrightBlue,
    highlight: FramoHighlightBright,
    highlightAlt: FramoHighlightDark,
    active: Framo,
    radioOutline: FramoRadioOutline,
    disabled: FramoCoal,
  },
  boxShadows: {
    dark: BoxShadowDark,
    focus: BoxShadowActive,
  },
  icons: {
    primary: FramoWhite,
    secondary: FramoBrightBlue,
    background: FramoDarkBlue,
  },
  divider: FramoDarkBlue,
  background: {
    default: FramoDarkBlue,
    menu: FramoDarkBlue,
    input: FramoDarkBlue,
    paper: FramoShallowBlue,
    table: FramoDeepBlue,
    inactive: FramoInactiveBlue,
  },
  misc: {
    header: FramoDeepBlue,
  },
} as PaletteOptions;
