import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { ISO_THRESHOLDS, PPM_THRESHOLDS } from '../../constants/thresholds';
import { ImportantIcon } from '../../assets/icons';

const useStyles = makeStyles((theme) => ({
  box: {
    borderRadius: theme.spacing(0.3),
    display: 'inline-block',
    borderStyle: 'solid',
    border: 1,
  },
  errorBorder: {
    borderColor: theme.palette.error.main,
  },
  errorFill: {
    background: theme.palette.error.main,
  },
  icon: {
    position: 'absolute',
    top: '-10px',
    right: '-10px',
    borderRadius: '100%',
  },
  innerBox: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    position: 'relative',
  },
  innerBoxBorder: {
    borderRight: '1px solid white',
  },
  innerBoxContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  normalBorder: {
    borderColor: theme.palette.info.main,
  },
  normalFill: {
    background: theme.palette.info.main,
  },
  warningBorder: {
    borderColor: theme.palette.warning.main,
  },
  warningFill: {
    background: theme.palette.warning.main,
  },
}));

interface ValueBoxProps {
  value: number | number[] | null;
  fallBack: React.ReactNode;
}

/**
 * Find if the value provided to the component exceeds any of the tresholds set.
 * Currently, tresholds are defined as constants, but it may be better to pass them as props
 **/
const findTresholdIndexValue = (value: number | null) => {
  if (!value) {
    return -1;
  }
  for (let index = 0; index < PPM_THRESHOLDS.length - 1; index++) {
    if (index === 0 && 0 < value && value < PPM_THRESHOLDS[index + 1]) {
      return 0;
    } else if (PPM_THRESHOLDS[index] < value && value < PPM_THRESHOLDS[index + 1]) {
      return index;
    }
  }
  return PPM_THRESHOLDS.length - 1;
};

/**Find if the values provided to the component exceeds any of the tresholds set.
 * If all values are below a treshold the index of that treshold is returned-
 * Currently, tresholds are defined as constants, but it may be better to pass them as props
 **/
const findTresholdIndexArray = (values: number[] | null) => {
  if (!values) {
    return -1;
  }
  for (let index = 0; index < ISO_THRESHOLDS.length; index++) {
    if (values.every((value) => value < ISO_THRESHOLDS[index])) {
      return index;
    }
  }
  return ISO_THRESHOLDS.length - 1;
};

const ValueBox = (props: ValueBoxProps) => {
  const { value, fallBack } = props;
  const classes = useStyles();
  const listOfStatusBorderColors = [
    classes.normalBorder,
    classes.warningBorder,
    classes.errorBorder,
  ];
  const listOfStatusFillColors = [classes.normalFill, classes.warningFill, classes.errorFill];

  const colorIndex = Array.isArray(value)
    ? findTresholdIndexArray(value)
    : findTresholdIndexValue(value);

  return (
    <>
      {!value ? (
        fallBack
      ) : Array.isArray(value) ? (
        <div className={`${classes.box} ${listOfStatusBorderColors[colorIndex]}`}>
          <div className={classes.innerBoxContainer}>
            {value.map((innerVal, i) => (
              <div
                key={`${innerVal}-${i}`}
                className={`${classes.innerBox} ${
                  i === value.length - 1 ? '' : classes.innerBoxBorder
                }`}
              >
                <Typography variant='body2'>{innerVal}</Typography>
                {i === value.length - 1 && colorIndex > 0 && (
                  <ImportantIcon
                    className={`${classes.icon} ${listOfStatusFillColors[colorIndex]}`}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className={`${classes.box} ${listOfStatusBorderColors[colorIndex]}`}>
          <div className={classes.innerBox}>
            <Typography variant='body2'>{value}</Typography>
            {colorIndex > 0 && (
              <ImportantIcon className={`${classes.icon} ${listOfStatusFillColors[colorIndex]}`} />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ValueBox;
