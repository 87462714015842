import React, { useEffect, useState } from 'react';
import AdminMenu from '../../../components/menus/AdminMenu';
import FlsDataGrid from '../../../components/datagrid/FlsDataGrid';
import { DatagridToolbar, Loadingbar } from '../../../components/datagrid';
import { GridColDef } from '@material-ui/data-grid';
import { useApiClient, User } from '../../../api';
import { makeStyles } from '@material-ui/core/styles';
import UsersRowActionButtons from './UsersRowActionButtons';
import { FlsModal } from '../../../components/modal';

const useStyles = makeStyles({
  usersGridContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  usersGrid: {
    width: '80%',
  },
});

const columns: GridColDef[] = [
  {
    field: 'userId',
    type: 'string',
    headerName: 'User ID',
    flex: 1,
    minWidth: 84,
    filterable: false,
    sortable: false,
  },
  {
    field: 'email',
    type: 'string',
    headerName: 'Email',
    flex: 1,
    minWidth: 84,
    filterable: false,
    sortable: false,
  },
  {
    field: 'firstName',
    type: 'string',
    headerName: 'First Name',
    flex: 1,
    minWidth: 84,
    filterable: false,
    sortable: false,
  },
  {
    field: 'lastName',
    type: 'string',
    headerName: 'Last Name',
    flex: 1,
    minWidth: 84,
    filterable: false,
    sortable: false,
  },
  {
    field: 'lab.labName',
    headerName: 'Lab',
    renderCell: (row) => <>{row.row.lab?.labName}</>,
    flex: 1,
    minWidth: 84,
    filterable: false,
    sortable: false,
  },
  {
    field: 'userLevel',
    headerName: 'User Level',
    flex: 1,
    minWidth: 84,
    filterable: false,
    sortable: false,
  },
  {
    field: '',
    headerName: 'Actions',
    sortable: false,
    renderCell: UsersRowActionButtons,
    editable: false,
    width: 115,
    resizable: false,
    filterable: false,
    disableColumnMenu: true,
    disableReorder: true,
    hideSortIcons: true,
  },
];

const AdminUsersPage = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalEntries, setTotalEntries] = useState<number>();
  const client = useApiClient();
  const classes = useStyles();
  const pageSize = 20;
  const [columnsButtonEl, setColumnsButtonEl] = React.useState(null);

  const onSearchChange = (searchString: string): void => {
    setSearchQuery(searchString);
  };

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);

      try {
        const users = await client.getUsers(pageNumber, pageSize, searchQuery);
        setUsers(users.results);
        setTotalEntries(users.totalCount);
      } catch (error) {
        console.error(error);
        setError('Unable to fetch users');
      }

      setLoading(false);
    };

    fetchUsers();
  }, [searchQuery, pageNumber]);

  return (
    <>
      <AdminMenu />
      <div className={classes.usersGridContainer}>
        <div className={classes.usersGrid}>
          <FlsDataGrid
            rows={users ? users : []}
            getRowId={(row) => row.userId}
            columns={columns}
            loading={loading}
            error={error}
            pagination
            pageSize={pageSize}
            rowCount={totalEntries}
            onPageChange={(page: number) => setPageNumber(page + 1)}
            paginationMode='server'
            sortingMode='server'
            disableSelectionOnClick
            components={{
              Toolbar: DatagridToolbar,
              LoadingOverlay: Loadingbar,
            }}
            componentsProps={{
              toolbar: {
                onSearchChange: onSearchChange,
                searchQuery: searchQuery,
                addNewLink: '/admin/users/add',
                searchPlaceholder: 'Search by ID',
                setColumnsButtonEl: setColumnsButtonEl,
              },
              panel: {
                anchorEl: columnsButtonEl,
              },
            }}
          />
        </div>
      </div>
      <FlsModal />
    </>
  );
};

export default AdminUsersPage;
