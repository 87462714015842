import usePromise from 'react-use-promise';
import { useApiClient } from '../api';

const useListForms = () => {
  const client = useApiClient();
  const [listResponse, errors, state] = usePromise(async () => await client.listForms(), []);

  return {
    listResponse,
    errors,
    loading: state === 'pending',
  };
};

export default useListForms;
