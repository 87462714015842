import React, { useEffect, useState } from 'react';
import AdminMenu from '../../../components/menus/AdminMenu';
import FlsDataGrid from '../../../components/datagrid/FlsDataGrid';
import { DatagridToolbar, Loadingbar } from '../../../components/datagrid';
import { ExternalLab, useApiClient } from '../../../api';
import { makeStyles } from '@material-ui/core/styles';
import { GridColDef } from '@material-ui/data-grid';
import { FlsModal } from '../../../components/modal';
import ExternalLabsRowActionButtons from './ExternalLabsRowActionButtons';

const useStyles = makeStyles((theme) => ({
  labsGridContainer: {},
  labsGrid: {
    margin: theme.spacing(1, 4),
  },
}));

const columns: GridColDef[] = [
  {
    field: 'id',
    type: 'string',
    headerName: 'ID',
    flex: 1,
    filterable: false,
    hide: true,
    sortable: false,
  },
  {
    field: 'name',
    type: 'string',
    headerName: 'Name',
    flex: 0.5,
    filterable: false,
    sortable: false,
  },
  {
    field: 'email',
    type: 'string',
    headerName: 'Email',
    flex: 0.5,
    filterable: false,
    sortable: false,
  },
  {
    field: '',
    headerName: 'Actions',
    sortable: false,
    renderCell: ExternalLabsRowActionButtons,
    editable: false,
    width: 115,
    resizable: false,
    filterable: false,
    disableColumnMenu: true,
    disableReorder: true,
    hideSortIcons: true,
  },
];

const AdminExternalLabsPage = () => {
  const [externalLabs, setExternalLabs] = useState<ExternalLab[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>();
  const client = useApiClient();
  const classes = useStyles();
  const [columnsButtonEl, setColumnsButtonEl] = React.useState(null);

  useEffect(() => {
    const fetchLabs = async () => {
      setLoading(true);

      try {
        const labs = await client.getExternalLabs();
        setExternalLabs(labs);
      } catch (error) {
        console.error(error);
        setError('Unable to fetch labs');
      }

      setLoading(false);
    };

    fetchLabs();
  }, []);

  return (
    <>
      <AdminMenu />
      <div className={classes.labsGridContainer}>
        <div className={classes.labsGrid}>
          <FlsDataGrid
            rows={externalLabs ? externalLabs : []}
            getRowId={(row) => row.id}
            columns={columns}
            loading={loading}
            error={error}
            disableSelectionOnClick
            components={{
              Toolbar: DatagridToolbar,
              LoadingOverlay: Loadingbar,
            }}
            componentsProps={{
              toolbar: {
                addNewLink: '/admin/externalLabs/add',
                setColumnsButtonEl: setColumnsButtonEl,
              },
              panel: {
                anchorEl: columnsButtonEl,
              },
            }}
          />
        </div>
      </div>
      <FlsModal />
    </>
  );
};

export default AdminExternalLabsPage;
