import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { toast, ToastContainer, ToastContainerProps } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import IconHandler from './IconHandler';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .Toastify__toast': {
      background: theme.palette.background.paper,
      color: theme.palette.text.primary,
      '&.Toastify__toast--success > .Toastify__toast-body > .Toastify__toast-icon > svg > *': {
        stroke: theme.palette.success.main,
      },
      '&.Toastify__toast--warning > .Toastify__toast-body > .Toastify__toast-icon > svg > *': {
        fill: theme.palette.warning.main,
      },
      '&.Toastify__toast--error > .Toastify__toast-body > .Toastify__toast-icon > svg > *': {
        stroke: theme.palette.error.main,
      },
      '&.Toastify__toast--error > .Toastify__toast-body > .Toastify__toast-icon > svg': {
        width: '24px',
        height: '24px',
      },
      '& > .Toastify__toast-body': {
        alignItems: 'flex-start',
      },
    },
  },
}));

export const flsToast = { ...toast };

flsToast.success = (content, options) => {
  const overrideOptions = {
    icon: <IconHandler iconIdentifier='check' />,
    ...options,
  };
  return toast.success(content, overrideOptions);
};

flsToast.warning = (content, options) => {
  const overrideOptions = {
    icon: <IconHandler iconIdentifier='important' />,
    ...options,
  };
  return toast.warning(content, overrideOptions);
};
flsToast.error = (content, options) => {
  const overrideOptions = {
    icon: <IconHandler iconIdentifier='important' />,
    ...options,
  };
  return toast.error(content, overrideOptions);
};

const FlsToast = (props: ToastContainerProps) => {
  const styles = useStyles();
  return <ToastContainer className={styles.root} {...props} />;
};

export default FlsToast;
