import { useMsal } from '@azure/msal-react';
import usePromise from 'react-use-promise';
import { useApiClient } from '../api';

const useLab = () => {
  const { accounts } = useMsal();

  const client = useApiClient();
  if (accounts.length > 0) {
    const currentLab = accounts[0].idTokenClaims
      ? accounts[0].idTokenClaims['extension_Lab']
      : null;
    const [lab, errors, state] = usePromise(
      async () => await client.getLabById(currentLab),
      [currentLab],
    );

    return {
      lab,
      labId: currentLab,
      errors,
      loading: state === 'pending',
    };
  }

  throw Error('No lab added for loggedin user');
};

export default useLab;
