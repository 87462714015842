import { GridCellParams } from '@material-ui/data-grid';
import FramoAction from '../../../components/buttons/FramoAction';
import React from 'react';
import { useApiClient } from '../../../api';
import { Divider, makeStyles } from '@material-ui/core';
import { flsToast } from '../../../components/util/FlsToast';
import { useModalContext } from '../../../contexts';
import YesNoModal from '../../../modals/YesNoModal';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  actionButtons: {
    margin: theme.spacing(0, 1),
  },
  divider: {
    margin: theme.spacing(1),
  },
}));

const UsersRowActionButtons = (props: GridCellParams) => {
  const client = useApiClient();
  const { setIsOpen, setModalProps, setContent } = useModalContext();
  const classes = useStyles();
  const history = useHistory();

  const handleDeleteClick = () => {
    setModalProps({
      title: 'Deletion',
    });
    setContent(
      <YesNoModal
        header={'Are you sure you want to delete this user?'}
        yesAction={submitDeletion}
        quitModal={() => setIsOpen(false)}
      />,
    );
    setIsOpen(true);
  };

  const submitDeletion = async () => {
    try {
      await client.deleteUser(props.row.userId);
      setIsOpen(false);
      window.location.reload();
    } catch (error) {
      console.error(error);
      flsToast.error('Error when trying to delete user');
    }
  };

  return (
    <>
      <FramoAction
        colorVariant='secondary'
        handleClick={() => history.push(`/admin/users/add/${props.row.userId}`)}
      >
        Edit
      </FramoAction>
      <Divider className={classes.divider} orientation='vertical' flexItem />
      <FramoAction colorVariant='secondary' handleClick={handleDeleteClick}>
        Delete
      </FramoAction>
    </>
  );
};

export default UsersRowActionButtons;
