import React, { useEffect, useState } from 'react';
import AdminMenu from '../../../components/menus/AdminMenu';
import {
  FormFieldPreValues,
  FormFieldPreValuesPayload,
  FormField,
  useApiClient,
} from '../../../api';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useParams } from 'react-router-dom';
import { Formik, setNestedObjectValues, useFormikContext } from 'formik';
import { DebouncedTextField, FlsSelect } from '../../../components/inputs';
import * as Yup from 'yup';
import { FramoButtonWithLoading } from '../../../components/buttons';
import { isEmpty } from 'ramda';
import { useLoading } from '../../../hooks';
import { LoadingActions, State } from '../../../hooks/useLoading';
import { FlsCard } from '../../../components/card';
import { MenuItem } from '@material-ui/core';
import { FlsLabel } from '../../../components/util';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  actionBar: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(2),
  },
  childOverride: {
    height: '100%',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    paddingTop: theme.spacing(1),
  },
  dividerStyle: {
    margin: theme.spacing(0, 2),
  },
  fieldContainers: {
    padding: theme.spacing(1, 2),
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    width: '100%',

    '& > *': {
      flex: '1 0 21%',
      marginRight: theme.spacing(4),
    },
  },
}));

const ValidationSchema = Yup.object().shape({
  value: Yup.string().required('Required').label('Value'),
  label: Yup.string().nullable().label('Label'),
  sortOrder: Yup.number().nullable().label('Sort Order'),
});

const AdminFormFieldPreValuesAddPage = () => {
  const client = useApiClient();
  const { state: stateOnSave, dispatch: dispatchOnSave } = useLoading();
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      <AdminMenu />
      <div className={classes.root}>
        <Formik
          initialValues={{
            id: undefined,
            formFieldId: undefined,
            value: '',
            label: undefined,
            sortOrder: undefined,
          }}
          validationSchema={ValidationSchema}
          onSubmit={async (values: FormFieldPreValuesPayload) => {
            if (values.id) {
              try {
                dispatchOnSave({ type: 'REQUEST' });
                await client.putFormFieldPreValues(values);
                dispatchOnSave({ type: 'SUCCESS', toast: 'Values updated' });
              } catch (error) {
                dispatchOnSave({
                  type: 'ERROR',
                  error: error?.message || 'Something went wrong',
                  toast: 'Failed to insert values to database.',
                });
              }
            } else {
              try {
                dispatchOnSave({ type: 'REQUEST' });
                const result = await client.postFormFieldPreValues(values);
                dispatchOnSave({ type: 'SUCCESS' });
                history.push('/admin/formFieldPreValues/add/' + (result as any).id);
              } catch (error) {
                dispatchOnSave({
                  type: 'ERROR',
                  error: error?.message || 'Something went wrong',
                  toast: 'Failed to insert values to database.',
                });
              }
            }
          }}
        >
          <InnerAddFormFieldPreValues stateOnSave={stateOnSave} dispatchOnSave={dispatchOnSave} />
        </Formik>
      </div>
    </>
  );
};

interface InnerAddFormFieldPreValuesProps {
  stateOnSave: State<unknown>;
  dispatchOnSave: React.Dispatch<LoadingActions<unknown>>;
}

const InnerAddFormFieldPreValues = (props: InnerAddFormFieldPreValuesProps) => {
  const formik = useFormikContext<FormFieldPreValues>();
  const [formFieldPreValues, setFormFieldPreValues] = useState<FormFieldPreValues>();
  const [formFields, setFormFiels] = useState<FormField[]>();
  const { urlFormFieldPreValuesId } = useParams<{ urlFormFieldPreValuesId: string }>();
  const client = useApiClient();
  const classes = useStyles();

  useEffect(() => {
    const fetchFormFieldPreValues = async () => {
      if (urlFormFieldPreValuesId) {
        const formFieldPreValues = await client.getFormFieldPreValuesById(urlFormFieldPreValuesId);
        setFormFieldPreValues(formFieldPreValues);
      }
    };

    fetchFormFieldPreValues();
  }, [urlFormFieldPreValuesId]);

  useEffect(() => {
    const fetchFormFields = async () => {
      const formFields = await client.getFormFields();
      const filteredFormFields = formFields.filter(
        (x) => !x.canHaveValidationRules && !x.isClassifierSelector && x.label !== 'Class',
      );
      setFormFiels(filteredFormFields);
    };

    fetchFormFields();
  }, []);

  const handleSubmit = async (ev: any) => {
    props.dispatchOnSave({ type: 'REQUEST' });
    const errors = await formik.validateForm();
    if (isEmpty(errors)) {
      formik.handleSubmit(ev);
    } else {
      console.log(errors);
      formik.setErrors(errors);
      formik.setTouched(setNestedObjectValues(errors, true));
      props.dispatchOnSave({
        type: 'ERROR',
        error: 'Validation Error',
        toast: 'Some fields have failed validation',
      });
    }
  };

  React.useEffect(() => {
    if (formFieldPreValues) {
      Object.entries(formFieldPreValues).forEach(([key, value]) => {
        if (formik.values.hasOwnProperty(key)) {
          formik.setFieldValue(key, value);
        }
      });
    }
  }, [formFieldPreValues]);

  return (
    <div>
      <FlsCard overrideChildStyle={classes.childOverride} showDivider header='Preconfigured Values'>
        <form onSubmit={handleSubmit}>
          <div id='firstbox' className={classes.fieldContainers}>
            <div className={classes.inputContainer}>
              <DebouncedTextField
                fullWidth
                displayHelper
                value={formik.values.value}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                id={'value'}
                placeholder='Value'
                helperText={formik.touched.value && formik.errors.value}
              />
            </div>
          </div>
          <div id='secondbox' className={classes.fieldContainers}>
            <div className={classes.inputContainer}>
              <DebouncedTextField
                fullWidth
                displayHelper
                value={formik.values.label}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                id={'label'}
                placeholder='Label'
                helperText={formik.touched.label && formik.errors.label}
              />
            </div>
            <div className={classes.inputContainer}>
              <DebouncedTextField
                fullWidth
                displayHelper
                value={formik.values.sortOrder}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                id={'sortOrder'}
                placeholder='Sort Order'
                helperText={formik.touched.sortOrder && formik.errors.sortOrder}
              />
            </div>
          </div>
          <div id='thirdbox' className={classes.fieldContainers}>
            <div className={classes.inputContainer}>
              <FlsLabel htmlFor={'formFieldId'} textTransform='none'>
                FORM FIELD
              </FlsLabel>
              <FlsSelect
                disabled={formik.values.id !== undefined}
                fullWidth
                value={formik.values.formFieldId || ''}
                onChange={(a) => {
                  formik.setFieldValue('formFieldId', a.target.value);
                }}
                id={'formFieldId'}
                helperText={formik.touched.formFieldId && formik.errors.formFieldId}
              >
                {formFields?.length === 0 ? (
                  <MenuItem disabled>No options</MenuItem>
                ) : (
                  formFields?.map((formField) => (
                    <MenuItem key={formField.id} value={formField.id}>
                      {formField.label}
                    </MenuItem>
                  ))
                )}
              </FlsSelect>
            </div>
          </div>
        </form>
      </FlsCard>
      <div className={classes.actionBar}>
        <FramoButtonWithLoading
          buttonSize='sizeNormal'
          buttonStyle='styleFilled'
          type='button'
          handleClick={handleSubmit}
          submissionState={props.stateOnSave?.state}
        >
          {formik.values.id ? 'Save' : 'Register'}
        </FramoButtonWithLoading>
      </div>
    </div>
  );
};

export default AdminFormFieldPreValuesAddPage;
