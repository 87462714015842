import React, { useEffect, useState } from 'react';
import AdminMenu from '../../../components/menus/AdminMenu';
import FlsDataGrid from '../../../components/datagrid/FlsDataGrid';
import { DatagridToolbar, Loadingbar } from '../../../components/datagrid';
import { FormFieldPreValues, useApiClient } from '../../../api';
import { makeStyles } from '@material-ui/core/styles';
import { GridColDef } from '@material-ui/data-grid';
import FormFieldPreValuesActionButtons from './FormFieldPreValuesActionButtons';

const useStyles = makeStyles((theme) => ({
  FormFieldPreValuesGridContainer: {},
  FormFieldPreValuesGrid: {
    margin: theme.spacing(1, 4),
  },
}));

const columns: GridColDef[] = [
  {
    field: 'id',
    type: 'string',
    headerName: 'ID',
    flex: 1,
    filterable: false,
    hide: true,
    sortable: false,
  },
  {
    field: 'formFieldId',
    type: 'string',
    headerName: 'Form Field Id',
    flex: 1,
    filterable: false,
    hide: true,
    sortable: false,
  },
  {
    field: 'value',
    type: 'string',
    headerName: 'Value',
    flex: 0.5,
    filterable: false,
    sortable: false,
  },
  {
    field: 'label',
    type: 'string',
    headerName: 'Label',
    flex: 0.5,
    filterable: false,
    sortable: false,
  },
  {
    field: 'sortOrder',
    type: 'string',
    headerName: 'Sort Order',
    flex: 0.5,
    filterable: false,
    sortable: false,
  },
  {
    field: '',
    headerName: 'Actions',
    sortable: false,
    renderCell: FormFieldPreValuesActionButtons,
    editable: false,
    width: 115,
    resizable: false,
    filterable: false,
    disableColumnMenu: true,
    disableReorder: true,
    hideSortIcons: true,
  },
];

const AdminFormFieldPreValuesPage = () => {
  const [formFieldPreValues, setFormFieldPreValues] = useState<FormFieldPreValues[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>();
  const client = useApiClient();
  const classes = useStyles();
  const [columnsButtonEl, setColumnsButtonEl] = React.useState(null);

  useEffect(() => {
    const fetchFormFieldPreValues = async () => {
      setLoading(true);

      try {
        const fetchedFormFieldPreValues = await client.getFormFieldPreValues();
        const nonClassifyingValues = fetchedFormFieldPreValues.sort((a, b) => {
          const sortOrderA = a.sortOrder !== undefined ? a.sortOrder : Infinity;
          const sortOrderB = b.sortOrder !== undefined ? b.sortOrder : Infinity;
          return sortOrderA - sortOrderB;
        });

        setFormFieldPreValues(nonClassifyingValues);
      } catch (error) {
        console.error(error);
        setError('Unable to fetch FormFieldPreValues');
      }

      setLoading(false);
    };

    fetchFormFieldPreValues();
  }, []);

  return (
    <>
      <AdminMenu />
      <div className={classes.FormFieldPreValuesGridContainer}>
        <div className={classes.FormFieldPreValuesGrid}>
          <FlsDataGrid
            rows={formFieldPreValues ? formFieldPreValues : []}
            getRowId={(row) => row.id}
            columns={columns}
            loading={loading}
            error={error}
            disableSelectionOnClick
            components={{
              Toolbar: DatagridToolbar,
              LoadingOverlay: Loadingbar,
            }}
            componentsProps={{
              toolbar: {
                addNewLink: '/admin/formFieldPreValues/add',
                setColumnsButtonEl: setColumnsButtonEl,
              },
              panel: {
                anchorEl: columnsButtonEl,
              },
            }}
          />
        </div>
      </div>
    </>
  );
};

export default AdminFormFieldPreValuesPage;
