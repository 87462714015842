import { Severity } from '../../models';
import { makeStyles } from '@material-ui/core';
import { ImportantIcon } from '../../assets/icons';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  icon: {
    height: '12px',
    width: '12px',
  },
  errorIconBackground: {
    alignItems: 'center',
    background: theme.palette.error.main,
    borderRadius: '50%',
    display: 'inline-flex',
    height: '12px',
    marginRight: theme.spacing(1),
    width: '12px',
  },
  marginalIconBackground: {
    alignItems: 'center',
    background: theme.palette.warning.main,
    borderRadius: '50%',
    display: 'inline-flex',
    height: '12px',
    marginRight: theme.spacing(1),
    width: '12px',
  },
}));

interface HelperIconProps {
  severity: Severity;
}

const HelperIcon = (props: HelperIconProps) => {
  const { severity } = props;
  const classes = useStyles(props);

  switch (severity) {
    case 'Critical':
    case 'Error':
      return (
        <div className={classes.errorIconBackground}>
          <ImportantIcon className={classes.icon} />
        </div>
      );
    case 'Marginal':
      return (
        <div className={classes.marginalIconBackground}>
          <ImportantIcon className={classes.icon} />
        </div>
      );
    case 'Normal':
      return <></>;
  }
};

export default HelperIcon;
