import {
  CircularProgress,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React, { useEffect, useRef, useState } from 'react';
import { ExternalLabOrderData, useApiClient } from '../api';
import FramoButtonWithLoading from '../components/buttons/FramoButtonWithLoading';
import { StatusCell } from '../components/datagrid';
import { FlsTextField } from '../components/inputs';
import { HelperText, IconHandler } from '../components/util';
import { useLab } from '../hooks';
import useLoading from '../hooks/useLoading';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  intro: {},
  wrapper: {},
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& > *:first-child': {
      marginRight: theme.spacing(2),
    },
  },
  actionBar: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  inputContainer: {
    marginBottom: theme.spacing(2),
  },
  tableHeader: {
    padding: theme.spacing(2),
  },
  closeCell: {
    cursor: 'pointer',
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const StyledHeaderRow = withStyles((theme: Theme) => ({
  head: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
  },
}))(TableRow);

const StyledPaper = withStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.table,
    marginBottom: theme.spacing(2),
  },
}))(Paper);

interface FormInputValue {
  value: any;
  error?: string;
}

export default function OrderExternalTests({ setIsOpen }) {
  const classes = useStyles();
  const downloadLinkRef = useRef<HTMLAnchorElement>(null);

  const { state: previewState, dispatch: previewDispatch } = useLoading();
  const { state: orderState, dispatch: orderDispatch } = useLoading();
  const { state: samplesState, dispatch: samplesDispatch } = useLoading<ExternalLabOrderData>();
  const [removedSamples, setRemovedSamples] = useState<Set<string>>(new Set());
  const { labId } = useLab();

  const [purchaseOrder, setPurchaseOrder] = useState<FormInputValue>({
    value: '',
    error: undefined,
  });
  const [comment, setComment] = useState<FormInputValue>({
    value: '',
    error: undefined,
  });
  const [formError, setFormError] = useState<string>('');

  const { getExternalLabOrderData, orderExternalTests, previewExternalTestsOrder } = useApiClient();

  useEffect(() => {
    async function fetchSamples() {
      try {
        samplesDispatch({ type: 'REQUEST' });
        const result = await getExternalLabOrderData(labId);
        setPurchaseOrder({ value: result.poNumber || '' });
        samplesDispatch({
          type: 'SUCCESS',
          result: result,
        });
      } catch (error) {
        samplesDispatch({ type: 'ERROR', error: error, toast: 'Unable to fetch samples' });
        console.error(error);
      }
    }
    fetchSamples();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateForm = () => {
    setFormError('');
    let error = false;
    if (!purchaseOrder.value) {
      setPurchaseOrder((prev) => ({ value: prev.value, error: 'Po number is a required field' }));
      error = true;
    }
    return error;
  };
  const submit = async () => {
    const error = validateForm();
    const samples =
      samplesState.data && samplesState.data.samples
        ? samplesState.data.samples
            .filter((x) => !removedSamples.has(x.sampleId))
            .map((x) => x.sampleId)
        : [];
    if (!error && samples.length > 0) {
      //TODO: submit
      orderDispatch({ type: 'REQUEST' });
      try {
        await orderExternalTests({
          comment: comment.value,
          purchaseOrderNumber: purchaseOrder.value,
          labId: labId,
          samples: samples,
        });
        orderDispatch({ type: 'SUCCESS' });
        setIsOpen(false);
      } catch (error) {
        console.error(error);
        orderDispatch({ type: 'ERROR', error: error });
        setFormError('An error occured while submitting the form.');
      }
    }
  };

  const previewOrderPdf = async () => {
    const error = validateForm();
    const samples =
      samplesState.data && samplesState.data.samples
        ? samplesState.data.samples
            .filter((x) => !removedSamples.has(x.sampleId))
            .map((x) => x.sampleId)
        : [];
    if (error || samples.length === 0) {
      return;
    }
    if (!downloadLinkRef || !downloadLinkRef.current) {
      return;
    }
    previewDispatch({ type: 'REQUEST' });
    const response = await previewExternalTestsOrder({
      comment: comment.value,
      purchaseOrderNumber: purchaseOrder.value,
      labId: labId,
      samples: samples,
    });

    if (!response.ok) {
      setFormError('An error occured while downloading the order preview');
      previewDispatch({
        type: 'ERROR',
        error: 'An error occured while downloading the order preview',
      });
      return;
    }

    previewDispatch({ type: 'SUCCESS' });
    const blob = await response.blob();
    const href = window.URL.createObjectURL(blob);
    const a = downloadLinkRef.current;
    const contentDisposition = response.headers.get('content-disposition');
    a.download = contentDisposition
      ? contentDisposition.split('filename=')[1].split(';')[0]
      : `preview-${purchaseOrder.value}.pdf`;
    a.href = href;
    a.click();
    a.href = '';
  };

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <div className={classes.inputContainer}>
          <FlsTextField
            placeholder='Po number'
            value={purchaseOrder.value}
            onChange={(e) => {
              setPurchaseOrder((prev) => ({
                value: e.target.value,
                error: !!e.target.value ? undefined : prev.error,
              }));
            }}
            id={'purchaseOrderNumber'}
            helperText={purchaseOrder.error}
          />
        </div>
        <div className={classes.inputContainer}>
          <FlsTextField
            placeholder='Additional Information'
            multiline={true}
            fullWidth={true}
            rows={3}
            value={comment.value}
            onChange={(e) => {
              setComment((prev) => ({
                value: e.target.value,
                error: undefined,
              }));
            }}
            id={'comment'}
            helperText={comment.error}
          />
        </div>
        <StyledPaper>
          <div className={classes.tableHeader}>Samples</div>
          <TableContainer>
            <Table>
              <TableHead>
                <StyledHeaderRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Analysis</TableCell>
                  <TableCell></TableCell>
                </StyledHeaderRow>
              </TableHead>
              <TableBody>
                {samplesState.state === 'pending' ? (
                  <TableRow className={classes.loader}>
                    <TableCell>
                      <CircularProgress size={24} />
                    </TableCell>
                  </TableRow>
                ) : (
                  samplesState.data &&
                  samplesState.data.samples &&
                  samplesState.data.samples
                    .filter((x) => !removedSamples.has(x.sampleId))
                    .map((sample, index) => {
                      return (
                        <TableRow key={sample.shortId}>
                          <TableCell>{sample.shortId}</TableCell>
                          <TableCell>
                            {sample.analysisGroups &&
                              sample.analysisGroups.map((group, groupIndex) => {
                                return (
                                  <StatusCell
                                    label={group}
                                    status='Empty'
                                    showIcon={true}
                                    key={groupIndex}
                                    iconOverride='droplet'
                                    iconPlacement='suffix'
                                  />
                                );
                              })}
                          </TableCell>
                          <TableCell
                            onClick={() => {
                              const newSamples = new Set(removedSamples);
                              newSamples.add(sample.sampleId);
                              setRemovedSamples(newSamples);
                            }}
                            className={classes.closeCell}
                          >
                            <IconHandler iconIdentifier='close' />
                          </TableCell>
                        </TableRow>
                      );
                    })
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </StyledPaper>
      </div>
      <div className={classes.actionBar}>
        {formError && <HelperText helperText={formError} severity={'Error'} />}
        <div className={classes.buttons}>
          <FramoButtonWithLoading
            buttonStyle='styleOutlined'
            buttonSize='sizeMini'
            type='button'
            handleClick={previewOrderPdf}
            submissionState={previewState.state}
          >
            Preview order pdf
          </FramoButtonWithLoading>
          {
            // eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content
            <a ref={downloadLinkRef} />
          }
          <FramoButtonWithLoading
            buttonStyle='styleFilled'
            buttonSize='sizeMini'
            type='button'
            handleClick={submit}
            submissionState={orderState.state}
          >
            Order external tests
          </FramoButtonWithLoading>
        </div>
      </div>
    </div>
  );
}
