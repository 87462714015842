import usePromise from 'react-use-promise';
import { useApiClient } from '../api';

const useListPreValues = () => {
  const client = useApiClient();
  const [preValues, preValueError, state] = usePromise(
    async () => await client.listCreateSampleOptions(),
    [],
  );
  return { preValues, preValueError, preValueState: state === 'pending' };
};

export default useListPreValues;
