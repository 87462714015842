import usePromise from 'react-use-promise';
import { useApiClient } from '../api';
const useNewestRecordByForm = (formId: string | undefined, sampleId: string) => {
  const client = useApiClient();
  const [record, errors, state] = usePromise(async () => {
    if (formId) {
      return await client.getNewestRecordByFormId(formId, sampleId);
    }
  }, [formId, sampleId]);

  return {
    record,
    errors,
    loading: state === 'pending',
  };
};

export default useNewestRecordByForm;
