import React from 'react';
import { makeStyles, RadioGroup, Divider, CircularProgress } from '@material-ui/core';
import { PreValue, PreValueTypes } from '../../../api';
import { FlsCard } from '../../../components/card';
import {
  DatePicker,
  DebouncedTextField,
  FlsAutocomplete,
  FlsRadio,
} from '../../../components/inputs';
import { FlsLabel, HelperText } from '../../../components/util';
import { useVesselContext } from '../../../contexts';
import { isEmpty } from 'ramda';
import { DateTime as LuxonDateTime } from 'luxon';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'inherit',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    paddingTop: theme.spacing(1),
  },
  disabler: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dateContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  closeIcon: {
    '& > *': {
      width: theme.spacing(6),
      height: theme.spacing(6),
    },
  },
  radioGroup: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
  },
  radioGrouping: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  dividerStyle: {
    margin: theme.spacing(0, 2),
  },
  fieldContainers: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    width: '100%',

    '& > *': {
      flex: '1 0 21%',
      marginRight: theme.spacing(4),
    },
  },
  fieldBoxContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    height: '100%',
  },
  mainContainer: {
    display: 'block',
    height: '100%',
  },
}));

interface SampleOverviewProps {
  formik: any;
  preValues: PreValue | undefined;
  disabled?: boolean;
}

const SampleOverview = (props: SampleOverviewProps) => {
  const { formik, preValues, disabled } = props;
  const { vessel } = useVesselContext();
  const classes = useStyles();
  const setFormikFieldDate = (value: Date, fieldName: string) => {
    if (value === null) {
      formik.setFieldValue(fieldName, null);
      return;
    }
    const utcMidnightValue = LuxonDateTime.fromJSDate(value)
      .setZone('utc', { keepLocalTime: true })
      .set({ hours: 0, minutes: 0, seconds: 0 });
    const jsDateUtcMidnightValue = utcMidnightValue.toJSDate();
    formik.setFieldValue(fieldName, jsDateUtcMidnightValue);
  };

  return (
    <FlsCard className={classes.root} showDivider header='Sample Information'>
      <div className={classes.mainContainer}>
        {preValues ? (
          <div className={classes.fieldBoxContainer}>
            <div id='firstbox' className={classes.fieldContainers}>
              <div className={classes.inputContainer}>
                <DebouncedTextField
                  value={formik.values.installationName}
                  onChange={formik.handleChange}
                  disabled={!isEmpty(vessel) || disabled}
                  displayHelper
                  fullWidth
                  onBlur={formik.onBlur}
                  id='installationName'
                  placeholder='Installation Name'
                  helperText={formik.touched.installationName && formik.errors.installationName}
                />
              </div>
              <div className={classes.inputContainer}>
                <FlsLabel htmlFor='expeditionDate'>Expedition Date</FlsLabel>
                <div className={classes.dateContainer}>
                  <DatePicker
                    value={formik.values.expeditionDate}
                    onChange={(value) => setFormikFieldDate(value, 'expeditionDate')}
                    id='expeditionDate'
                    helperText={formik.touched.expeditionDate && formik.errors.expeditionDate}
                    displayHelper
                    disabled={disabled}
                  />
                </div>
              </div>
              <div className={classes.inputContainer}>
                <FlsLabel htmlFor='oilType'>Oil Type</FlsLabel>
                <FlsAutocomplete
                  fullWidth
                  displayHelper
                  disableClearable
                  id={'oilType'}
                  allowCustomInputs
                  colorVariant='primary'
                  autoSelect
                  value={formik.values.oilType}
                  options={preValues.values.oilType}
                  getOptionLabel={(data) =>
                    data.label ? data.label : data ? data : formik.values.oilType
                  }
                  getOptionSelected={(option: PreValueTypes) =>
                    option.value === formik.values.oilType
                  }
                  onChange={(e, value: PreValueTypes) =>
                    formik.setFieldValue(
                      'oilType',
                      value?.value ? value.value : value ? value : null,
                    )
                  }
                  blurOnSelect
                  cancelOption={() => formik.setFieldValue('oilType', null)}
                  helperText={formik.touched.oilType && formik.errors.oilType}
                  disabled={disabled}
                />
              </div>
              <div className={classes.inputContainer}>
                <FlsLabel htmlFor='sampledBy'>Sampled By</FlsLabel>
                <FlsAutocomplete
                  fullWidth
                  displayHelper
                  disableClearable
                  id={'sampledBy'}
                  colorVariant='primary'
                  value={
                    preValues.values.sampledBy.find((x) => x.value === formik.values.sampledBy)
                      ?.label || formik.values.sampledBy
                  }
                  options={preValues.values.sampledBy}
                  getOptionLabel={(data) =>
                    data.label ? data.label : data ? data : formik.values.sampledBy
                  }
                  getOptionSelected={(option: PreValueTypes) =>
                    option.value === formik.values.sampledBy
                  }
                  onChange={(e, value: PreValueTypes) =>
                    formik.setFieldValue(
                      'sampledBy',
                      value?.value ? value.value : value ? value : null,
                    )
                  }
                  blurOnSelect
                  cancelOption={() => formik.setFieldValue('sampledBy', null)}
                  helperText={formik.touched.sampledBy && formik.errors.sampledBy}
                  disabled={disabled}
                />
              </div>
              <div className={classes.inputContainer}>
                <FlsLabel htmlFor='sampleDate'>Sample Date</FlsLabel>
                <div className={classes.dateContainer}>
                  <DatePicker
                    id={'sampleDate'}
                    name='sampleDate'
                    value={formik.values.sampleDate}
                    onChange={(value) => setFormikFieldDate(value, 'sampleDate')}
                    helperText={formik.touched.sampleDate && formik.errors.sampleDate}
                    displayHelper
                    disabled={disabled}
                  />
                </div>
              </div>

              <div className={classes.inputContainer}>
                <FlsLabel htmlFor='samplePoint'>Sample Point</FlsLabel>
                <FlsAutocomplete
                  fullWidth
                  displayHelper
                  id={'samplePoint'}
                  allowCustomInputs
                  autoSelect
                  colorVariant='primary'
                  value={formik.values.samplePoint}
                  options={preValues.values.samplePoint}
                  getOptionLabel={(data) =>
                    data.label ? data.label : data ? data : formik.values.samplepoint
                  }
                  getOptionSelected={(option: PreValueTypes) =>
                    option.value === formik.values.samplePoint
                  }
                  onChange={(e, value: PreValueTypes) => {
                    formik.setFieldValue(
                      'samplePoint',
                      value?.value ? value.value : value ? value : null,
                    );
                  }}
                  blurOnSelect
                  cancelOption={() => formik.setFieldValue('samplePoint', null)}
                  helperText={formik.touched.samplePoint && formik.errors.samplePoint}
                  disabled={disabled}
                />
              </div>

              <div className={classes.inputContainer}>
                <FlsLabel htmlFor='lastFilterChange'>Last Filter Change</FlsLabel>
                <div className={classes.dateContainer}>
                  <DatePicker
                    id={'lastFilterChangedition'}
                    name='lastFilterChange'
                    value={formik.values.lastFilterChange}
                    onChange={(value) => setFormikFieldDate(value, 'lastFilterChange')}
                    displayHelper
                    helperText={formik.touched.lastFilterChange && formik.errors.lastFilterChange}
                    disabled={disabled}
                  />
                </div>
              </div>

              <div className={classes.inputContainer}>
                <FlsLabel htmlFor='samplePurpose'>Purpose of Sample</FlsLabel>
                <FlsAutocomplete
                  fullWidth
                  displayHelper
                  disableClearable
                  id={'samplePurpose'}
                  colorVariant='primary'
                  value={formik.values.samplePurpose}
                  options={preValues.values.samplePurpose}
                  getOptionLabel={(data) =>
                    data.label ? data.label : data ? data : formik.values.samplePurpose
                  }
                  getOptionSelected={(option: PreValueTypes) =>
                    option.value === formik.values.samplePurpose
                  }
                  onChange={(e, value: PreValueTypes) =>
                    formik.setFieldValue(
                      'samplePurpose',
                      value?.value ? value.value : value ? value : null,
                    )
                  }
                  blurOnSelect
                  helperText={formik.touched.samplePurpose && formik.errors.samplePurpose}
                  disabled={disabled}
                />
              </div>
            </div>
            <Divider className={classes.dividerStyle} variant='fullWidth' />
            <div id='secondBox' className={classes.fieldContainers}>
              <div className={classes.inputContainer}>
                <DebouncedTextField
                  fullWidth
                  displayHelper
                  errorSeverity={formik.errors.imoNumber ? 'Error' : 'Normal'}
                  value={formik.values.imoNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  id={'imoNumber'}
                  placeholder='Imo Number'
                  helperText={formik.touched.imoNumber && formik.errors.imoNumber}
                  disabled={disabled}
                />
              </div>
              <div className={classes.inputContainer}>
                <FlsLabel htmlFor='customer'>Customer</FlsLabel>
                <FlsAutocomplete
                  displayHelper
                  fullWidth
                  allowCustomInputs
                  id={'customer'}
                  value={formik.values.customer}
                  autoSelect
                  colorVariant='primary'
                  options={preValues.values.customer}
                  getOptionLabel={(data) =>
                    data.label ? data.label : data ? data : formik.values.customer
                  }
                  getOptionSelected={(option: PreValueTypes) =>
                    option.value === formik.values.customer
                  }
                  onChange={(e, value: PreValueTypes) =>
                    formik.setFieldValue(
                      'customer',
                      value?.value ? value.value : value ? value : null,
                    )
                  }
                  blurOnSelect
                  cancelOption={() => formik.setFieldValue('customer', null)}
                  helperText={formik.touched.customer && formik.errors.customer}
                  disabled={disabled}
                />
              </div>
              <div className={classes.inputContainer}>
                <DebouncedTextField
                  displayHelper
                  fullWidth
                  value={formik.values.customerReference}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  id={'customerReference'}
                  placeholder='Customer reference'
                  helperText={formik.touched.customerReference && formik.errors.customerReference}
                  disabled={disabled}
                />
              </div>

              <div className={classes.inputContainer}>
                <DebouncedTextField
                  fullWidth
                  displayHelper
                  value={formik.values.framoReference}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  id={'framoReference'}
                  placeholder='Framo Reference'
                  helperText={formik.touched.framoReference && formik.errors.framoReference}
                  disabled={disabled}
                />
              </div>
            </div>
            <Divider className={classes.dividerStyle} variant='middle' />
            <div id='thirdBox' className={classes.fieldContainers}>
              <div className={classes.inputContainer}>
                <DebouncedTextField
                  displayHelper
                  fullWidth
                  value={formik.values.framoSalesOrderNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  id={'framoSalesOrderNumber'}
                  placeholder='Framo Sales Order Number'
                  helperText={
                    formik.touched.framoSalesOrderNumber && formik.errors.framoSalesOrderNumber
                  }
                  disabled={disabled}
                />
              </div>
              <div className={classes.inputContainer}>
                <FlsLabel htmlFor='hpuSystem'>HPU System</FlsLabel>
                <FlsAutocomplete
                  displayHelper
                  fullWidth
                  disableClearable
                  id={'hpuSystem'}
                  colorVariant='primary'
                  value={formik.values.hpuSystem}
                  options={preValues.values.hpuSystem}
                  getOptionLabel={(data) =>
                    data.label ? data.label : data ? data : formik.values.hpuSystem
                  }
                  getOptionSelected={(option: PreValueTypes) =>
                    option.value === formik.values.hpuSystem
                  }
                  onChange={(e, value: PreValueTypes) =>
                    formik.setFieldValue(
                      'hpuSystem',
                      value?.value ? value.value : value ? value : null,
                    )
                  }
                  blurOnSelect
                  cancelOption={() => formik.setFieldValue('hpuSystem', null)}
                  helperText={formik.touched.hpuSystem && formik.errors.hpuSystem}
                  disabled={disabled}
                />
              </div>

              <div className={classes.inputContainer}>
                <FlsLabel htmlFor='internalInvoiceOffice'>Internal Invoice Office</FlsLabel>
                <FlsAutocomplete
                  displayHelper
                  fullWidth
                  disableClearable
                  autoSelect
                  id={'internalInvoiceOffice'}
                  colorVariant='primary'
                  value={
                    preValues.values.invoiceOffice.find(
                      (office) => office.value === formik.values.internalInvoiceOffice,
                    )?.label || formik.values.internalInvoiceOffice
                  }
                  options={preValues.values.invoiceOffice}
                  getOptionLabel={(data) =>
                    data.label ? data.label : data ? data : formik.values.internalInvoiceOffice
                  }
                  getOptionSelected={(option: PreValueTypes) =>
                    option.value === formik.values.internalInvoiceOffice
                  }
                  onChange={(e, value: PreValueTypes) =>
                    formik.setFieldValue(
                      'internalInvoiceOffice',
                      value.value ? value.value : value ? value : null,
                    )
                  }
                  blurOnSelect
                  helperText={
                    formik.touched.internalInvoiceOffice && formik.errors.internalInvoiceOffice
                  }
                  disabled={disabled}
                />
              </div>
              <div className={classes.radioGrouping}>
                <div className={classes.inputContainer}>
                  {preValues ? (
                    <>
                      <FlsLabel htmlFor='internalInvoiceType'>Internal Invoice Type</FlsLabel>
                      <RadioGroup
                        className={classes.radioGroup}
                        id={'internalInvoiceType'}
                        value={formik.values.internalInvoiceType}
                        onChange={(e, value) => formik.setFieldValue('internalInvoiceType', value)}
                      >
                        {preValues.values.invoiceType.map((option) => (
                          <FlsRadio
                            key={option.label}
                            label={option.label}
                            value={option.value}
                            disabled={
                              option.value !== formik.values.internalInvoiceType && disabled
                            }
                          />
                        ))}
                      </RadioGroup>
                      <HelperText
                        helperText={
                          formik.touched.internalInvoiceType && formik.errors.internalInvoiceType
                        }
                        severity={'Error'}
                      />
                    </>
                  ) : (
                    <CircularProgress size={50} />
                  )}
                </div>
                <div className={classes.inputContainer}>
                  <FlsLabel htmlFor='sampleBottleType'>Framo Bottle</FlsLabel>
                  <RadioGroup
                    className={classes.radioGroup}
                    id={'sampleBottleType'}
                    value={formik.values.sampleBottleType}
                    onChange={(e, value) =>
                      formik.setFieldValue('sampleBottleType', value === 'true')
                    }
                  >
                    <FlsRadio
                      label={'Yes'}
                      value={true}
                      disabled={!formik.values.sampleBottleType && disabled}
                    />
                    <FlsRadio
                      label={'No'}
                      value={false}
                      disabled={formik.values.sampleBottleType && disabled}
                    />
                  </RadioGroup>
                  <HelperText
                    helperText={formik.touched.invalidSample && formik.errors.invalidSample}
                    severity={'Error'}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <CircularProgress size={50} />
        )}
      </div>
    </FlsCard>
  );
};

export default SampleOverview;
