import React, { PropsWithChildren } from 'react';
import { Typography, Card, Divider, Theme, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    alignContent: 'center',
    borderRadius: '6px',
    boxShadow: '4px 4px 12px rgba(0, 0, 0, 0.32)',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
  },
  noCorner: {
    borderTopLeftRadius: '0',
  },
  headerStyle: {
    textAlign: 'left',
    margin: theme.spacing(3, 0, 3, 3),
  },
  dividerStyle: {
    margin: theme.spacing(0, 2),
  },
  childContainer: {
    alignItems: (props: CardProps) => props.variant || 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    width: '100%',
  },
}));

interface CardProps {
  header?: string;
  showDivider?: boolean;
  notRoundedTopLeftCorner?: boolean;
  variant?: 'start' | 'center' | 'end' | 'normal';
  className?: string;
  overrideChildStyle?: string;
}

const FlsCard = (props: PropsWithChildren<CardProps>) => {
  const { header, showDivider, children, notRoundedTopLeftCorner, className, overrideChildStyle } =
    props;
  const classes = useStyles(props);
  return (
    <Card
      className={`${classes.root} ${notRoundedTopLeftCorner ? classes.noCorner : ''} ${className}`}
    >
      {header && (
        <Typography className={classes.headerStyle} variant='body2'>
          {header}
        </Typography>
      )}
      {showDivider && <Divider className={classes.dividerStyle} variant='fullWidth' />}
      <div className={`${classes.childContainer} ${overrideChildStyle}`}>{children}</div>
    </Card>
  );
};
export default FlsCard;
