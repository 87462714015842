import React, { PropsWithChildren } from 'react';
import { makeStyles } from '@material-ui/core';
import { FlsCard } from '../card';

const useStyles = makeStyles((theme) => ({
  panelContainer: {
    padding: 0,
    boxSizing: 'border-box',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      backgroundColor: theme.palette.secondary.main,
      width: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.main,
      margin: '1px',
      borderRadius: '6px',
    },
    boxShadow: theme.palette.boxShadows.dark,
  },
}));

interface TabPanelProps {
  index: number;
  currentTab: number;
}

const TabPanel = (props: PropsWithChildren<TabPanelProps>) => {
  const { children, index, currentTab, ...other } = props;
  const classes = useStyles();
  return (
    <div
      className={classes.panelContainer}
      role='tabpanel'
      hidden={currentTab !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {currentTab === index && <FlsCard notRoundedTopLeftCorner>{children}</FlsCard>}
    </div>
  );
};

export default TabPanel;
