import { GridCellParams } from '@material-ui/data-grid';
import { useHistory } from 'react-router-dom';
import FramoAction from '../../../components/buttons/FramoAction';
import React from 'react';
import { useApiClient } from '../../../api';
import { Divider, makeStyles } from '@material-ui/core';
import { useLoading } from '../../../hooks';

const useStyles = makeStyles((theme) => ({
  actionButtons: {
    margin: theme.spacing(0, 1),
  },
  divider: {
    margin: theme.spacing(1),
  },
}));

const FormFieldValidationRuleRowActionButtons = (props: GridCellParams) => {
  const history = useHistory();
  const client = useApiClient();
  const classes = useStyles();
  const { dispatch } = useLoading();

  const deleteAction = async () => {
    if (window.confirm('Are you sure you want to delete the validation rule?')) {
      try {
        await client.deleteFormFieldValidationRule(props.row.id);
        window.location.reload();
      } catch (error) {
        console.log(error);
        dispatch({
          type: 'ERROR',
          error: error?.message || 'Something went wrong',
          toast: 'Failed to delete form field validation rule',
        });
      }
    }
  };

  return (
    <>
      <FramoAction
        colorVariant='secondary'
        handleClick={() => history.push(`/admin/formFieldValidationRules/add/${props.row.id}`)}
      >
        Edit
      </FramoAction>
      <Divider className={classes.divider} orientation='vertical' flexItem />
      <FramoAction colorVariant='secondary' handleClick={deleteAction}>
        Delete
      </FramoAction>
    </>
  );
};

export default FormFieldValidationRuleRowActionButtons;
