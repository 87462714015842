import React, { useEffect } from 'react';
import { getFormulaSplitValues } from '../../../utils';
import { makeStyles, Typography } from '@material-ui/core';
import { FormResultBuilder, ParticleResultLineStrategy } from '../../../api';

interface ParticleResultLineProps {
  formResultBuilder: FormResultBuilder;
  resultValues: any;
}

const useStyles = makeStyles((theme) => ({
  resultLine: {
    marginBottom: theme.spacing(2),
  },
}));

const ParticleResultLine = (props: ParticleResultLineProps) => {
  const { formResultBuilder, resultValues } = props;
  const [display, setDisplay] = React.useState('');
  const classes = useStyles();

  useEffect(() => {
    const splitFormula = getFormulaSplitValues(
      formResultBuilder.formula,
      formResultBuilder.delimiter,
    );
    switch (formResultBuilder.strategy) {
      case ParticleResultLineStrategy.FieldCalculation:
        let str = '';
        splitFormula.forEach((x, idx, array) => {
          if (x in resultValues) {
            if (resultValues[x] === '' || isNaN(resultValues[x])) {
              str += '-';
            } else {
              str += resultValues[x];
            }
          } else {
            str += '-';
          }

          if (idx !== array.length - 1) {
            str += formResultBuilder.delimiter;
          }
        });

        setDisplay(str);
        break;
      case ParticleResultLineStrategy.HighestClass:
        let highestNo = Number.MIN_SAFE_INTEGER;
        /*
          Exception made for SAE format 00 and 000 classes.
         */
        splitFormula.forEach((x, idx, array) => {
          if (x in resultValues) {
            let num = 0;
            if (resultValues[x] === '00') {
              num = -1;
            } else if (resultValues[x] === '000') {
              num = -2;
            } else {
              num = parseInt(resultValues[x]);
            }
            if (num > highestNo) {
              highestNo = num;
            }
          }
        });

        if (highestNo === Number.MIN_SAFE_INTEGER) {
          setDisplay('-');
        } else if (highestNo === -1) {
          setDisplay('00');
        } else if (highestNo === -2) {
          setDisplay('000');
        } else {
          setDisplay(highestNo.toString());
        }
        break;
    }
  }, [resultValues]);

  if (
    formResultBuilder.strategy === ParticleResultLineStrategy.HighestClass ||
    formResultBuilder.strategy === ParticleResultLineStrategy.FieldCalculation
  ) {
    return <Typography className={classes.resultLine}>Result: {display}</Typography>;
  } else {
    return <Typography className={classes.resultLine}>Strategy not found</Typography>;
  }
};

export default ParticleResultLine;
