import React, { useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import LabelSelector, { LabelOptions } from '../components/printing/LabelSelector';
import { FramoButton } from '../components/buttons';
import { makeStyles, RadioGroup, Typography } from '@material-ui/core';
import { FlsRadio } from '../components/inputs';
import { useModalContext } from '../contexts';
import { ExternalTests, useApiClient, Sample } from '../api';
import { useLoading } from '../hooks';
import { determineExternalTestGroup } from '../utils';
import ReactDOM from 'react-dom';
import '../index.css';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minWidth: '610px',
    minHeight: '655px',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    alignItems: 'center',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  radioContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  labelSizeContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
  },
}));

interface PrintProps {
  samples: Sample[];
}

const MultiPrintPreview = (props: PrintProps) => {
  const classes = useStyles();
  const { setIsOpen } = useModalContext();
  const { dispatch: externalTestDispatch, state: externalTestState } = useLoading<ExternalTests>();
  const { getExternalTests } = useApiClient();
  const options: LabelOptions[] = ['External Lab Label', 'Big Label', 'Small Label'];
  const componentRef = useRef<HTMLDivElement>(document.createElement('div'));
  const [selected, setSelected] = useState<LabelOptions>('External Lab Label');
  const [renderedSamples, setRenderedSamples] = useState<JSX.Element[]>([]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    if (renderedSamples.length > 0) {
      while (componentRef.current.firstChild) {
        componentRef.current.removeChild(componentRef.current.firstChild);
      }

      renderedSamples.forEach((sample) => {
        const sampleDiv = document.createElement('div');
        ReactDOM.render(sample, sampleDiv);
        sampleDiv.className = 'page-break';
        componentRef.current.appendChild(sampleDiv);
      });
    }
  }, [renderedSamples]);

  useEffect(() => {
    if (!externalTestState.data) {
      return;
    }

    setRenderedSamples([]);
    const samples = props.samples.map((sample) => renderSample(sample));
    setRenderedSamples(samples);
  }, [externalTestState.data, selected]);

  function renderSample(sample: Sample) {
    return (
      <LabelSelector
        selector={selected}
        labelContent={{
          title: sample.shortId,
          qrContent: sample.shortId,
          comment: sample.externalLabSample?.comment,
          testProfile: sample.externalLabSample?.externalLabTests
            ? determineExternalTestGroup(
                new Set(sample.externalLabSample?.externalLabTests),
                externalTestState.data,
              )
            : '-',
        }}
      />
    );
  }

  const printActivation = () => {
    if (handlePrint) {
      handlePrint();
    }
    setIsOpen(false);
  };

  useEffect(() => {
    async function fetchExternalTests() {
      try {
        externalTestDispatch({ type: 'REQUEST' });
        const tests = await getExternalTests();
        externalTestDispatch({ type: 'SUCCESS', result: tests });
      } catch (error) {
        externalTestDispatch({ type: 'ERROR', error: 'Unable to fetch external tests' });
      }
    }
    fetchExternalTests();
  }, [props.samples]);

  const getOrientation = (selected: LabelOptions) => {
    switch (selected) {
      case 'Small Label': {
        return 'Portrait';
      }
      case 'Big Label': {
        return 'Landscape';
      }
      case 'External Lab Label': {
        return 'Portrait';
      }
      default: {
        return 'Unknown';
      }
    }
  };

  return (
    <>
      <div className={classes.root}>
        <div className={classes.labelSizeContainer}>
          <Typography>Select label size:</Typography>
          <RadioGroup
            row
            value={selected}
            onChange={(_e, value: LabelOptions) => setSelected(value)}
          >
            {options.map((option) => {
              return <FlsRadio key={option} label={option} value={option} />;
            })}
          </RadioGroup>
        </div>
        <div>
          <LabelSelector
            selector={selected}
            labelContent={{
              multiLable: props.samples.length > 1,
              title: props.samples[0].shortId,
              qrContent: props.samples[0].shortId,
              comment: props.samples[0].externalLabSample?.comment,
              testProfile: props.samples[0].externalLabSample?.externalLabTests
                ? determineExternalTestGroup(
                    new Set(props.samples[0].externalLabSample?.externalLabTests),
                    externalTestState.data,
                  )
                : '-',
            }}
          />
        </div>
        <div className={classes.buttonContainer}>
          {selected && (
            <Typography variant='h6'>Orientation: {getOrientation(selected)}</Typography>
          )}
          <FramoButton
            handleClick={printActivation}
            buttonSize='sizeMini'
            buttonStyle='styleFilled'
          >
            Print {`${props.samples.length === 1 ? 'label' : props.samples.length + ' labels'}`}
          </FramoButton>
        </div>
      </div>
    </>
  );
};

export default MultiPrintPreview;
