import {
  ListItem,
  ListItemProps,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React, { MouseEvent, MouseEventHandler, useState } from 'react';
import { IconIdentifierType } from '../../models';
import { YesNo } from '../buttons';
import { IconHandler } from '../util';

export interface FlsListItemProps {
  text: string;
  subText?: string;
  icon?: IconIdentifierType;
  onIconClick?: MouseEventHandler<HTMLDivElement>;
  onItemClick?: MouseEventHandler<HTMLLIElement>;
  disabled?: boolean;
  confirmIconClick?: boolean;
  id: any;
  selected?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    '&:hover': {
      stroke: theme.palette.icons.secondary,
      '& > *': {
        stroke: 'inherit',
      },
    },
  },
  secondaryConfirm: {
    right: '0px',
  },
}));

const StyledListItem = withStyles((theme: Theme) => ({
  root: {
    cursor: (props: ListItemProps) => (props.onClick ? 'pointer' : 'default'),
    paddingLeft: theme.spacing(1),
    textDecoration: (props: ListItemProps) => (props.onClick ? 'underline' : 'none'),
    color: (props: ListItemProps) => (props.selected ? theme.palette.primary.main : 'inherit'),
    '&:hover': {
      backgroundColor: 'transparent',
      color: (props: ListItemProps) => (props.onClick ? theme.palette.primary.main : 'inherit'),
      textDecoration: (props: ListItemProps) => (props.onClick ? 'underline' : 'none'),
    },
    '&.Mui-selected': {
      backgroundColor: 'transparent',
    },
    '&.Mui-selected:hover': {
      backgroundColor: 'transparent',
    },
  },
}))(ListItem);

const StyledListItemText = withStyles((theme: Theme) => ({
  root: {
    textDecoration: 'inherit',
    color: 'inherit',
    '&:hover': {
      color: 'inherit',
    },
  },
}))(ListItemText);

const StyledSecondaryAction = withStyles((theme: Theme) => ({
  root: {
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.icons.secondary,
    },
  },
}))(ListItemSecondaryAction);

export default function FlsListItem(props: FlsListItemProps) {
  const [confirmClickState, setConfirmClickState] = useState(false);
  const classes = useStyles();

  const handleIconClick = (ev: MouseEvent<HTMLDivElement>) => {
    if (props.confirmIconClick && !confirmClickState) {
      setConfirmClickState(true);
    } else if (props.onIconClick) {
      props.onIconClick(ev);
      setConfirmClickState(false);
    }
  };
  return (
    <StyledListItem
      button
      onClick={props.onItemClick}
      disabled={props.disabled}
      selected={props.selected}
    >
      <StyledListItemText primary={props.text} secondary={props.subText} />
      {props.onIconClick &&
        props.icon &&
        (!confirmClickState ? (
          <StyledSecondaryAction onClick={handleIconClick}>
            <IconHandler iconIdentifier={props.icon} className={classes.icon} />
          </StyledSecondaryAction>
        ) : (
          <StyledSecondaryAction className={classes.secondaryConfirm}>
            <YesNo
              onYes={handleIconClick}
              onNo={() => {
                setConfirmClickState(false);
              }}
            />
          </StyledSecondaryAction>
        ))}
    </StyledListItem>
  );
}
