const locale = 'en-GB'; // change locale to navigator.language to get dynamic date format

type InputDate = Date | string | number | undefined;

export const tryParseDate = (date: InputDate): Date | undefined => {
  if (!date) return undefined;
  if (date instanceof Date) {
    return date;
  }
  try {
    return new Date(date);
  } catch (e) {
    console.warn('Failed to parse date', date, e);
    return undefined;
  }
};

const dateFormat = {
  formatDate(date: InputDate, options: Intl.DateTimeFormatOptions): string {
    const dateToFormat = tryParseDate(date);
    if (!dateToFormat) return typeof date === 'string' ? date ?? '' : '';
    return dateToFormat.toLocaleString(locale, options);
  },
  formatDateLong(date: InputDate): string {
    return this.formatDate(date, {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });
  },
  formatDateShortMonth(date: InputDate): string {
    return this.formatDate(date, {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
  },
  formatDateShortFormat(date: InputDate): string {
    return this.formatDate(date, {
      day: 'numeric',
      month: 'numeric',
      year: '2-digit',
    }).replaceAll('/', '.');
  },
  formatDateTimeLong(date: InputDate): string {
    return this.formatDate(date, {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    });
  },
  formatUrlDate(date: Date): string {
    return date.toISOString();
  },
};

export default dateFormat;
