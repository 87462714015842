import React from 'react';
import { makeStyles } from '@material-ui/core';
import { DataGrid, DataGridProps } from '@material-ui/data-grid';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: 'calc(100vh - 400px)',
    minHeight: '570px',
    width: '100%',
    ...theme.typography.body1,
    color: theme.palette.text.secondary,
    '& .MuiDataGrid-root': {
      ...theme.typography.body1,
      border: 'none',
    },
    '& .MuiDataGrid-columnHeaderWrapper': {
      backgroundColor: theme.palette.misc.header,
      color: theme.palette.text.secondary,
    },
    '& MuiDataGrid-window': {
      overflowX: 'hidden',
    },
    '& .MuiToolbar-root': {
      color: theme.palette.primary.main,
    },
    '& .MuiSelect-icon': {
      color: theme.palette.primary.main,
    },
    '& .MuiDataGrid-main': {
      borderTop: '1px solid black',
    },
    '& .MuiDataGrid-footerContainer .MuiDataGrid-selectedRowCount': {
      visibility: 'hidden',
    },
  },
  actionButtons: {
    display: 'flex',
  },
  divider: {
    margin: theme.spacing(1),
  },
}));
const FlsDataGrid = (props: DataGridProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <DataGrid {...props} />
    </div>
  );
};

export default FlsDataGrid;
