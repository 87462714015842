import { GridCellParams } from '@material-ui/data-grid';
import { useHistory } from 'react-router-dom';
import FramoAction from '../../../components/buttons/FramoAction';
import React from 'react';

const ClassifiersRowActionButtons = (props: GridCellParams) => {
  const history = useHistory();

  return (
    <>
      <FramoAction
        colorVariant='secondary'
        handleClick={() => history.push(`/admin/classifiers/add/${props.row.id}`)}
      >
        Edit
      </FramoAction>
    </>
  );
};

export default ClassifiersRowActionButtons;
