import React, { useEffect, useState } from 'react';
import AdminMenu from '../../../components/menus/AdminMenu';
import { ExternalLab, useApiClient } from '../../../api';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useParams } from 'react-router-dom';
import { Formik, setNestedObjectValues, useFormikContext } from 'formik';
import { DebouncedTextField } from '../../../components/inputs';
import * as Yup from 'yup';
import { FramoButtonWithLoading } from '../../../components/buttons';
import { isEmpty } from 'ramda';
import { useLoading } from '../../../hooks';
import { LoadingActions, State } from '../../../hooks/useLoading';
import { FlsCard } from '../../../components/card';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  actionBar: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(2),
  },
  childOverride: {
    height: '100%',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    paddingTop: theme.spacing(1),
  },
  dividerStyle: {
    margin: theme.spacing(0, 2),
  },
  fieldContainers: {
    padding: theme.spacing(1, 2),
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    width: '100%',

    '& > *': {
      flex: '1 0 21%',
      marginRight: theme.spacing(4),
    },
  },
  overrideChip: {
    flexWrap: 'nowrap',
    flexDirection: 'column',
    textAlign: 'left',
    boxShadow: 'none',
    alignItems: 'center',
    '& > *': {
      '& > *': {
        width: '260px',
        '& > *': {
          width: '100%',
          '& > *': {
            dispaly: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          },
        },
      },
    },
  },
}));

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required('Required').label('Lab Name'),
  email: Yup.string().required('Required').email().label('Contact Email'),
});

const AdminExternalLabsAddPage = () => {
  const client = useApiClient();
  const { state: stateOnSave, dispatch: dispatchOnSave } = useLoading();
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      <AdminMenu />
      <div className={classes.root}>
        <Formik
          initialValues={{
            id: undefined,
            name: '',
            email: '',
          }}
          validationSchema={ValidationSchema}
          onSubmit={async (values: ExternalLab) => {
            if (values.id) {
              try {
                dispatchOnSave({ type: 'REQUEST' });
                await client.updateExternalLab(values);
                dispatchOnSave({ type: 'SUCCESS', toast: 'Values updated' });
              } catch (error) {
                dispatchOnSave({
                  type: 'ERROR',
                  error: error?.message || 'Something went wrong',
                  toast: 'Failed to insert values to database.',
                });
              }
            } else {
              try {
                dispatchOnSave({ type: 'REQUEST' });
                const result = await client.addExternalLab(values);
                dispatchOnSave({ type: 'SUCCESS' });
                history.push('/admin/externalLabs/add/' + (result as any).id);
              } catch (error) {
                dispatchOnSave({
                  type: 'ERROR',
                  error: error?.message || 'Something went wrong',
                  toast: 'Failed to insert values to database.',
                });
              }
            }
          }}
        >
          <InnerAddLab stateOnSave={stateOnSave} dispatchOnSave={dispatchOnSave} />
        </Formik>
      </div>
    </>
  );
};

interface InnerAddLabProps {
  stateOnSave: State<unknown>;
  dispatchOnSave: React.Dispatch<LoadingActions<unknown>>;
}

const InnerAddLab = (props: InnerAddLabProps) => {
  const formik = useFormikContext<ExternalLab>();
  const [externalLab, setExternalLab] = useState<ExternalLab>();
  const { urlExternalLabId } = useParams<{ urlExternalLabId: string }>();
  const client = useApiClient();
  const classes = useStyles();

  useEffect(() => {
    const fetchLab = async () => {
      if (urlExternalLabId) {
        const externalLab = await client.getExternalLab(urlExternalLabId);
        setExternalLab(externalLab);
      }
    };

    fetchLab();
  }, [urlExternalLabId]);

  React.useEffect(() => {
    if (externalLab) {
      Object.entries(externalLab).forEach(([key, value]) => {
        formik.setFieldValue(key, value);
      });
    }
  }, [externalLab]);

  const handleSubmit = async (ev: any) => {
    props.dispatchOnSave({ type: 'REQUEST' });
    const errors = await formik.validateForm();
    if (isEmpty(errors)) {
      formik.handleSubmit(ev);
    } else {
      console.error(errors);
      formik.setErrors(errors);
      formik.setTouched(setNestedObjectValues(errors, true));
      props.dispatchOnSave({
        type: 'ERROR',
        error: 'Validation Error',
        toast: 'Some fields have failed validation',
      });
    }
  };

  return (
    <div>
      <FlsCard overrideChildStyle={classes.childOverride} showDivider header='Lab'>
        <form onSubmit={handleSubmit}>
          <div id='firstbox' className={classes.fieldContainers}>
            <div className={classes.inputContainer}>
              <DebouncedTextField
                fullWidth
                displayHelper
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                id={'name'}
                placeholder='Name'
                helperText={formik.touched.name && formik.errors.name}
              />
            </div>
            <div className={classes.inputContainer}>
              <DebouncedTextField
                fullWidth
                displayHelper
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                id={'email'}
                placeholder='Email'
                helperText={formik.touched.email && formik.errors.email}
              />
            </div>
          </div>
        </form>
      </FlsCard>
      <div className={classes.actionBar}>
        <FramoButtonWithLoading
          buttonSize='sizeNormal'
          buttonStyle='styleFilled'
          type='button'
          handleClick={handleSubmit}
          submissionState={props.stateOnSave?.state}
        >
          {formik.values.id ? 'Save' : 'Add'}
        </FramoButtonWithLoading>
      </div>
    </div>
  );
};

export default AdminExternalLabsAddPage;
