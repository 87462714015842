import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { FlsLabel } from '../util';

const useStyles = makeStyles((theme) => ({
  menuContainer: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      marginRight: '0px',
      justifyContent: 'space-between',
    },
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginRight: theme.spacing(4),
    '&: last-child': {
      marginRight: 0,
    },
    marginLeft: theme.spacing(2),
    '&:first-child': {
      marginLeft: 0,
    },
  },
  adjust: {
    //Needed for alignement due to radiogroup
    paddingTop: '8px',
  },
}));

interface LabelValuePair {
  key: string;
  label: string;
  value: string;
}
interface InformationBarProps {
  values: LabelValuePair[];
}

const InformationBar = (props: InformationBarProps) => {
  const classes = useStyles();

  return (
    <div className={classes.menuContainer}>
      {props.values &&
        props.values.map((value, index) => {
          return (
            <div className={classes.inputContainer} key={index}>
              <FlsLabel htmlFor={value.key}>{value.label}</FlsLabel>
              <Typography className={classes.adjust} variant='body1' id={value.key}>
                {value.value}
              </Typography>
            </div>
          );
        })}
    </div>
  );
};

export default InformationBar;
