import { Divider, List, ListProps, Theme } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React from 'react';
import FlsListItem, { FlsListItemProps } from './FlsListItem';

const StyledList = withStyles((theme: Theme) => ({
  root: {
    padding: 0,
    width: '100%',
  },
}))(List);

interface FlsListProps extends ListProps {
  items: FlsListItemProps[];
  divider?: boolean;
}
//Expand this if you need more handlers or properties

export default function FlsList(props: FlsListProps) {
  const { items, divider, ...rest } = props;

  return (
    <StyledList {...rest}>
      {items &&
        items.map((item, index) => {
          return (
            <React.Fragment key={item.id}>
              <FlsListItem {...item} />
              {divider && <Divider />}
            </React.Fragment>
          );
        })}
    </StyledList>
  );
}
