// Configuration used by default in Customer/Service/Demo portal in Production
const productionDefaults = Object.freeze({
  REACT_APP_B2C_CLIENT_ID: '',
  REACT_APP_PASSWORD_RESET_POLICY: '',
  REACT_APP_SIGN_IN_POLICY: '',
  REACT_APP_API_BASE: '',
  REACT_APP_B2C_AUTHORITY: '',
});

type Environment = typeof productionDefaults;

// Configuration used by default in Customer/Service/Demo portal in Staging
const stagingDefaults: Environment = Object.freeze({
  REACT_APP_B2C_CLIENT_ID: '',
  REACT_APP_PASSWORD_RESET_POLICY: '',
  REACT_APP_SIGN_IN_POLICY: '',
  REACT_APP_API_BASE: '',
  REACT_APP_B2C_AUTHORITY: '',
});

const flsEnvironment = process.env['REACT_APP_FLS_ENVIRONMENT'] ?? 'Production';

const defaults =
  flsEnvironment === 'Staging' || flsEnvironment === 'Development'
    ? stagingDefaults
    : productionDefaults;

const environment: Environment = Object.entries(defaults).reduce((obj, [key, defaultValue]) => {
  obj[key] = process.env[key] ?? defaultValue;
  return obj;
}, {} as Environment);

export default Object.freeze(environment);
