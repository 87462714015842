export const PROPER_LABELS = {
  originalOilType: 'Original Oil Type',
  owner: 'Owner',
  manager: 'Manager',
  customer: 'Customer',
  installCode: 'Install Code',
  salesOrderNo: 'Sales Order No.',
};

export type DashboardTabId = 'InProgress' | 'SentToExternalLab' | 'Finalized';

export interface DashboardTabLabel {
  id: DashboardTabId;
  label: string;
}

export const DASHBOARD_TABLABELS: DashboardTabLabel[] = [
  { id: 'InProgress', label: 'In Progress' },
  { id: 'SentToExternalLab', label: 'External Lab' },
  { id: 'Finalized', label: 'Archived' },
];
