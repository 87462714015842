import { Typography, Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FramoButtonWithLoading, FramoAction, FramoButton } from '../../../components/buttons';
import { useModalContext, useSampleContext } from '../../../contexts';
import { SubmissionState } from '../../../models';
import { InformationBar } from '../../../components/lists';
import { PrintPreview } from '../../../modals';
import { WarningMessages } from '../../../texts/WarningMessages';
import SampleRevisionLabel from '../../../components/util/RevisionLabel';

const useStyles = makeStyles((theme) => ({
  infobarContainer: {
    minHeight: '60px',
    display: 'flex',
    flexDirection: 'row',
    '& > *': {
      '&:first-child': {
        marginBottom: '10px',
      },
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      '& > *': {
        marginTop: theme.spacing(2),
      },
    },
    alignItems: 'end',
    justifyContent: 'space-between',
  },
  vesselContainer: {
    paddingRight: theme.spacing(2),
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    '& > *': {
      marginRight: theme.spacing(2),
      '&:last-child': {
        marginRight: '0px',
      },
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      justifyContent: 'space-between',
      '& > *': {
        marginRight: 0,
      },
    },
  },
  globalNavigation: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  warningText: {
    color: theme.palette.warning.main,
  },
}));

interface ActionBarProps {
  formik: any;
  submissionAction: (ev?: React.FormEvent<HTMLFormElement> | undefined) => void;
  copyFieldsOverToNewSample: () => void;
  resetFields: () => void;
  saveState?: SubmissionState;
  disabled?: boolean;
}

const Actionbar = (props: ActionBarProps) => {
  const { formik, submissionAction, copyFieldsOverToNewSample, resetFields, saveState, disabled } =
    props;
  const classes = useStyles();
  const { sample } = useSampleContext();
  const history = useHistory();
  const { urlSampleId } = useParams<{ urlSampleId: string }>();
  const { setModalProps, setContent, setIsOpen } = useModalContext();

  const values = [
    {
      key: 'Installation',
      label: 'Installation',
      value: formik.values.installationName ? formik.values.installationName : ' - ',
    },
    {
      key: 'Oil Type',
      label: 'Oil Type',
      value: formik.values.oilType ? formik.values.oilType : ' - ',
    },
    {
      key: 'originalOilType',
      label: 'Original Oil Type',
      value: formik.values.originalOilType ? formik.values.originalOilType : 'No vessel selected',
    },
    {
      key: 'ompDocNr',
      label: 'OMP Document Nr',
      value: formik.values.ompDocNr
        ? formik.values.ompDocNr
        : 'Assigned when registered (to vessel)',
    },
  ];

  const handleModalPrint = () => {
    setModalProps({
      title: 'Preview Label',
      titleIcon: undefined,
    });
    setContent(<PrintPreview />);
    setIsOpen(true);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <div className={classes.globalNavigation}>
          <FramoAction
            type='button'
            colorVariant='secondary'
            handleClick={() => history.push('/')}
            iconIdentifier='back'
          >
            Return to dashboard
          </FramoAction>
          <FramoAction
            disabled={urlSampleId ? false : true}
            type='button'
            colorVariant='secondary'
            handleClick={() => history.push(`/analyses/${urlSampleId}`)}
            iconIdentifier='forward'
            iconRight
          >
            Sample Data
          </FramoAction>
        </div>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h1'>
          {sample ? 'Info: ' + sample.shortId : 'New Sample'} <SampleRevisionLabel />
        </Typography>
        {disabled && (
          <Typography className={classes.warningText}>{WarningMessages.DocumentRevised}</Typography>
        )}
      </Grid>
      <Grid item xs={12}>
        <div className={classes.infobarContainer}>
          <InformationBar values={values} />
          <div className={classes.buttonContainer}>
            <FramoButton
              buttonSize='sizeNormal'
              buttonStyle='styleOutlined'
              type='button'
              handleClick={resetFields}
            >
              Add New
            </FramoButton>
            <FramoButton
              disabled={!urlSampleId}
              buttonSize='sizeNormal'
              buttonStyle='styleOutlined'
              type='button'
              handleClick={copyFieldsOverToNewSample}
            >
              Clone
            </FramoButton>
            <FramoButton
              disabled={!urlSampleId || disabled}
              buttonSize='sizeNormal'
              buttonStyle='styleOutlined'
              type='button'
              handleClick={handleModalPrint}
            >
              Print Label
            </FramoButton>
            <FramoButtonWithLoading
              buttonSize='sizeNormal'
              buttonStyle='styleFilled'
              type='button'
              handleClick={(e) => submissionAction(e)}
              submissionState={saveState}
              disabled={disabled}
            >
              {sample ? 'Save' : 'Register'}
            </FramoButtonWithLoading>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default Actionbar;
