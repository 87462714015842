import { GridCellParams } from '@material-ui/data-grid';
import React from 'react';
import { StatusCell } from '.';
import { SampleForm } from '../../api';

export default function FormStatusCell(params: GridCellParams) {
  const forms = params.value as SampleForm[];
  return (
    <div>
      {forms
        .filter((f) => f.showStatus)
        .sort((form1, form2) => form1.sortOrder - form2.sortOrder)
        .map((f, index) => {
          return <StatusCell label={f.shortName} status={f.status} showIcon={true} key={index} />;
        })}
    </div>
  );
}
