import React from 'react';
import { makeStyles } from '@material-ui/core';
import { QrCode } from '../util';
import { LabelProps } from './LabelSelector';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '38mm',
    height: '20mm',
    backgroundColor: 'transparent',
  },
  printContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: 'transparent',
    width: '38mm',
    height: '20mm',
    '& > *': {
      margin: '0px',
      color: 'black',
    },
  },
  qrContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '20mm',
    width: '20mm',
    boxSizing: 'inherit',
  },
  header: {
    margin: '0px',
  },
  labelContainer: {
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    width: '38mm',
    height: '20mm',
    boxShadow: `0 0 0 4px ${theme.palette.background.paper}`,
    borderRadius: '4px',
    zIndex: 3,
  },
  backgroundLabelContainerFirst: {
    position: 'absolute',
    marginTop: '16px',
    marginLeft: '-16px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    backgroundColor: theme.palette.background.inactive,
    width: '38mm',
    height: '20mm',
    boxShadow: `0 0 0 4px ${theme.palette.background.paper}`,
    borderRadius: '4px',
    zIndex: 2,
    '& > *': {
      margin: '0px',
      color: 'black',
    },
  },
  backgroundLabelContainerSecond: {
    position: 'absolute',
    marginTop: '32px',
    marginLeft: '-32px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    backgroundColor: theme.palette.background.inactive,
    width: '38mm',
    height: '20mm',
    boxShadow: `0 0 0 4px ${theme.palette.background.paper}`,
    borderRadius: '4px',
    zIndex: 1,
    '& > *': {
      margin: '0px',
      color: 'black',
    },
  },
  '@media print': {
    labelContainer: {
      boxShadow: '0 0 0 4px transparent',
    },
  },
}));

const SmallLabel = (props: LabelProps, ref) => {
  const classes = useStyles();

  return (
    <div className={classes.root} ref={ref}>
      {props.multiLable && <div className={classes.backgroundLabelContainerFirst}></div>}
      {props.multiLable && <div className={classes.backgroundLabelContainerSecond}></div>}
      <div className={classes.labelContainer}>
        <div className={classes.printContent}>
          <div>
            <p className={classes.header}>
              <strong>{props.title}</strong>
            </p>
          </div>
          <div className={classes.qrContainer}>
            <QrCode value={props.qrContent} margin={1} scale={2} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.forwardRef(SmallLabel);
