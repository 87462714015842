import React from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  AnalysesPage,
  CreateSample,
  Dashboard,
  AdminPage,
  AdminUsersPage,
  AdminLabsPage,
  AdminLabsAddPage,
  AdminUsersAddPage,
  AdminClassifiersPage,
  AdminClassThresholdsPage,
  AdminClassifiersAddPage,
  AdminFormFieldValidationRulesPage,
  AdminFormFieldValidationRulesAddPage,
  AdminFormFieldPreValuesPage,
  AdminFormFieldPreValuesAddPage,
  AdminExternalLabsAddPage,
  AdminExternalLabsPage,
} from './pages';
import NotFound from './NotFound';
import { makeStyles } from '@material-ui/core/styles';
import { FlsHeader } from './components/header';
import FlsToast from './components/util/FlsToast';
import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles({
  mainLayout: {
    width: '100%',
    '@media screen': {},
    '@media print': {
      display: 'block',
      overflow: 'visible',
    },
  },
});
const Routing = () => {
  const classes = useStyles();

  return (
    <div className={classes.mainLayout}>
      <FlsHeader />
      <Switch>
        <Route path={'/createSample/:urlSampleId'} component={CreateSample} />
        <Route path={'/createSample'} component={CreateSample} />
        <Route path={'/analyses/:urlSampleId'} component={AnalysesPage} />
        <Route path={'/analyses'} component={AnalysesPage} />
        <Route
          path={'/admin/externalLabs/add/:urlExternalLabId'}
          component={AdminExternalLabsAddPage}
        />
        <Route path={'/admin/externalLabs/add'} component={AdminExternalLabsAddPage} />
        <Route path={'/admin/externalLabs'} component={AdminExternalLabsPage} />
        <Route path={'/admin/labs/add/:urlLabId'} component={AdminLabsAddPage} />
        <Route path={'/admin/users/add/:urlUserId'} component={AdminUsersAddPage} />
        <Route
          path={'/admin/formFieldPreValues/add/:urlFormFieldPreValuesId'}
          component={AdminFormFieldPreValuesAddPage}
        />
        <Route
          path={'/admin/classifiers/add/:urlClassifierId'}
          component={AdminClassifiersAddPage}
        />
        <Route
          path={'/admin/formFieldValidationRules/add/:urlFormFieldValidationRuleId'}
          component={AdminFormFieldValidationRulesAddPage}
        />
        <Route path={'/admin/labs/add'} component={AdminLabsAddPage} />
        <Route path={'/admin/users/add'} component={AdminUsersAddPage} />
        <Route path={'/admin/classifiers/add'} component={AdminClassifiersAddPage} />
        <Route path={'/admin/formFieldPreValues/add'} component={AdminFormFieldPreValuesAddPage} />
        <Route
          path={'/admin/formFieldValidationRules/add'}
          component={AdminFormFieldValidationRulesAddPage}
        />
        <Route path={'/admin/classThresholds'} component={AdminClassThresholdsPage} />
        <Route path={'/admin/classifiers'} component={AdminClassifiersPage} />
        <Route path={'/admin/users'} component={AdminUsersPage} />
        <Route path={'/admin/labs'} component={AdminLabsPage} />
        <Route
          path={'/admin/formFieldValidationRules'}
          component={AdminFormFieldValidationRulesPage}
        />
        <Route path={'/admin/formFieldPreValues'} component={AdminFormFieldPreValuesPage} />
        <Route path={'/admin'} component={AdminPage} />
        <Route path={'/'} component={Dashboard} />
        <Route component={NotFound} />
      </Switch>
      <FlsToast
        position='bottom-right'
        autoClose={5000}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        hideProgressBar
        draggable={false}
      />
    </div>
  );
};

export default Routing;
