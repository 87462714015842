import React from 'react';
import { Typography, Box, Link, Theme, makeStyles, Tooltip } from '@material-ui/core';
import { IconHandler } from '../util';
import { IconIdentifier } from '../../models/components';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: 'none',
  },
  uploadPanel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  //To be elaborated after design consulting
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    transition: '0.3s',
    textDecoration: 'none',
    '&:hover, &:focus': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      textDecoration: 'none',
      '& > *': {
        '& > *': {
          '& > *': {
            color: theme.palette.button.highlight,
            stroke: theme.palette.button.highlight,
          },
        },
      },
    },
  },
  iconContainer: {
    padding: theme.spacing(2),
  },
  input: {
    display: 'none',
  },
  icon: {
    marginLeft: theme.spacing(0.2),
  },
  iconText: {
    whiteSpace: 'nowrap',
    paddingTop: theme.spacing(2),
    color: theme.palette.primary.main,
  },
}));
interface FramoIconBoxProps extends IconIdentifier {
  handleClick: () => void;
  buttonText: string;
  icon?: string;
  tooltip?: string;
}

const FramoIconBox = (props: FramoIconBoxProps) => {
  const { handleClick, buttonText, iconIdentifier, tooltip } = props;
  const classes = useStyles(props);

  return (
    <Box className={classes.iconContainer}>
      <Tooltip title={tooltip ? tooltip : ''} arrow placement='bottom'>
        <Link component='button' onClick={handleClick} className={classes.button}>
          <div className={classes.uploadPanel}>
            <IconHandler className={classes.icon} iconIdentifier={iconIdentifier} />
            <Typography variant='body2' className={classes.iconText}>
              {buttonText}
            </Typography>
          </div>
        </Link>
      </Tooltip>
    </Box>
  );
};

export default FramoIconBox;
