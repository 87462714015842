import React from 'react';
import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { DarkTheme } from './theme';
import { MsalProvider } from '@azure/msal-react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Routing from './Routing';
import { msalInstance } from './MsalConfiguration';
import { AuthenticationGuard } from './guards';
import { SuperContext } from 'react-super-context';
import { authContext, sampleContext, modalContext, vesselContext } from './contexts';
import { apiClientContext } from './api';
import { formContext } from './contexts/FormContext';

const App = () => (
  <MsalProvider instance={msalInstance}>
    <ThemeProvider theme={DarkTheme}>
      <CssBaseline />
      <AuthenticationGuard>
        <SuperContext
          contexts={[
            authContext,
            apiClientContext,
            sampleContext,
            modalContext,
            vesselContext,
            formContext,
          ]}
        >
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Routing />
          </MuiPickersUtilsProvider>
        </SuperContext>
      </AuthenticationGuard>
    </ThemeProvider>
  </MsalProvider>
);

export default App;
