import { Typography } from '@material-ui/core';
import React from 'react';
import { GridCellParams } from '@material-ui/data-grid';

const RowShortId = (gridCellParams: GridCellParams) => {
  return (
    <Typography display={'inline'}>
      {gridCellParams.value}
      {gridCellParams.row.revisions.length > 0 && (
        <Typography style={{ display: 'inline', fontSize: '10px' }}>
          {' '}
          rev. {gridCellParams.row.revisions.length - 1}
        </Typography>
      )}
    </Typography>
  );
};

export default RowShortId;
