import { makeStyles } from '@material-ui/core';
import CheckIcon from '../assets/icons/ico_check.svg';

export const iconStyles = makeStyles((theme) => ({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    display: 'block',
    borderRadius: '4px',
    width: '22px',
    height: '22px',
    border: '2px solid',
    borderColor: theme.palette.button.radioOutline,
    backgroundColor: theme.palette.background.paper,
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: theme.palette.button.radioOutline,
    },
  },
  checkedIcon: {
    backgroundColor: theme.palette.primary.main,
    backgroundImage: `url(${CheckIcon})`,
    backgroundPosition: 'center',
    border: '2px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: '4px',
    width: '22px',
    height: '22px',
    'input:hover ~ &': {
      backgroundColor: theme.palette.button.highlight,
    },
  },
}));
