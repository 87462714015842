import React from 'react';
import { Grid, Typography } from '@material-ui/core';

const NotFound = () => (
  <Grid container justify='center' alignItems='center'>
    <Typography variant='h2'>404 - Page not found</Typography>
  </Grid>
);

export default NotFound;
