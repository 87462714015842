import React from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import { useMsal } from '@azure/msal-react';
import { ErrorMessages } from './texts';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(4, 2),
    paddingTop: '10vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  errorMessage: {
    maxWidth: '400px',
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}));

interface ErrorPageProps {
  message?: string;
}

const reloadPage = () => window?.location?.reload();

const ErrorPage = ({ message }: ErrorPageProps) => {
  const classes = useStyles();
  const { instance } = useMsal();

  return (
    <Container className={classes.container} maxWidth={false}>
      <Typography variant='h1'>Error</Typography>
      <Typography variant='body2' className={classes.errorMessage}>
        {message ?? ErrorMessages.UnknownError}
      </Typography>
      <div className={classes.buttonContainer}>
        <Button onClick={reloadPage}>
          <Typography variant='body1'>Reload page</Typography>
        </Button>
        <Button onClick={() => instance.logout()}>
          <Typography variant='body1'>Log out</Typography>
        </Button>
      </div>
    </Container>
  );
};

export default ErrorPage;
