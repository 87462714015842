import React from 'react';
import { Sample } from '../api';
import FlsTable from '../components/tables/FlsTable';
import { TableCell, TableRow, makeStyles } from '@material-ui/core';
import dateFormat from '../utils/dateFormat';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      marginBottom: theme.spacing(2),
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  keyContainer: {
    whiteSpace: 'nowrap',
    padding: '8px 8px 8px 16px',
    color: theme.palette.text.secondary,
  },
  valueContainer: {
    padding: '8px 8px 8px 8px',
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
  tableContainer: {
    overflowX: 'auto',
    maxWidth: '700px',
    maxHeight: '450px',
    '&::-webkit-scrollbar': {
      backgroundColor: theme.palette.secondary.main,
      width: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.main,
      margin: '1px',
      borderRadius: '6px',
    },
  },
  tableHeader: {
    '& > *': {
      '&:last-child': {
        textAlign: 'center',
      },
    },
  },
}));
interface SampleInformationProps {
  selectedSample: Sample;
}

const SampleInformation = (props: SampleInformationProps) => {
  const { selectedSample } = props;
  const classes = useStyles();

  const PROPER_SAMPLE_LABEL = {
    shortId: 'Short ID',
    createdOn: 'Created On',
    updatedOn: 'Updated On',
    ompDocNr: 'OMP Doc. Number',
    ompDocRno: 'Omp Doc. RNO',
    expeditionDate: 'Expedition Date',
    disposalDate: 'Disposal Date',
    assumedSampleDate: 'Assumed Sample Date',
    customerReference: 'Customer Reference',
    framoReference: 'Framo Reference',
    sampledBy: 'Sampled By',
    samplePurpose: 'Sample Purpose',
    samplePoint: 'Sample Point',
    sampleDate: 'Sample Date',
    originalOilType: 'Original Oil Type',
    originalOil: 'Original Oil',
    lastFilterChange: 'Last Filter Change',
    sampleBottleType: 'Sample Bottle Type',
    imoNumber: 'IMO Number',
    installationName: 'Installation Name',
    installationId: 'Installation Id',
    customer: 'Customer',
    framoSalesOrderNumber: 'Framo Sales Order Number',
    partOfOmp: 'Part of OMP',
    internalInvoiceOffice: 'Internal Invoice Office',
    internalInvoiceType: 'Internal Invoice Type',
    projectEngineer: 'Project Engineer',
    hpuSystem: 'HPU System',
    analysedBy: 'Analysed By',
    owner: 'Owner',
    manager: 'Manager',
    invalidSample: 'Invalid Sample',
    state: 'State',
  };

  const SAMPLE_DATE_FORMATS = [
    'createdOn',
    'updatedOn',
    'expeditionDate',
    'disposalDate',
    'assumedSampleDate',
    'sampleDate',
    'lastFilterChange',
  ];
  return (
    <div className={classes.root}>
      <FlsTable
        headers={['Field', 'Value']}
        title='Sample Data'
        styles={{ root: classes.tableContainer, tableHeader: classes.tableHeader }}
      >
        {Object.entries(selectedSample).map(([key, value], index) => (
          <>
            {key in PROPER_SAMPLE_LABEL && (
              <TableRow key={index}>
                <TableCell className={classes.keyContainer}>{PROPER_SAMPLE_LABEL[key]}</TableCell>
                <TableCell className={classes.valueContainer}>
                  {value
                    ? SAMPLE_DATE_FORMATS.includes(key)
                      ? dateFormat.formatDateShortFormat(value.toString())
                      : value.toString()
                    : ' - '}
                </TableCell>
              </TableRow>
            )}
          </>
        ))}
      </FlsTable>
    </div>
  );
};
export default SampleInformation;
