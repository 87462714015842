import React from 'react';
import BigLabel from './BigLabel';
import ExternalLabLabel from './ExternalLabLabel';
import SmallLabel from './SmallLabel';

export type LabelOptions = 'External Lab Label' | 'Small Label' | 'Big Label';

interface LabelSelectorProps {
  selector: LabelOptions;
  labelContent: LabelProps;
}

export interface LabelProps {
  title: string;
  qrContent: string;
  comment?: string;
  testProfile: string;
  multiLable?: boolean;
}

const LabelSelector = (props: LabelSelectorProps, ref) => {
  const { selector } = props;
  switch (selector) {
    case 'External Lab Label':
      return <ExternalLabLabel ref={ref} {...props.labelContent} />;
    case 'Big Label':
      return <BigLabel ref={ref} {...props.labelContent} />;
    case 'Small Label':
      return <SmallLabel ref={ref} {...props.labelContent} />;
    default:
      return null;
  }
};

export default React.forwardRef(LabelSelector);
