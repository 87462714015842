import { makeStyles, Theme } from '@material-ui/core';
import React, { PropsWithChildren, useEffect, useLayoutEffect, useRef } from 'react';
import FlsGridItem from './FlsGridItem';

const useGridStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'grid',
    gridRowGap: theme.spacing(1),
    gridColumnGap: theme.spacing(2),
    gridTemplateColumns: 'repeat(auto-fill, minmax(316px, 1fr))',
    gridAutoRows: theme.spacing(1),
    width: '100%',
    marginRight: theme.spacing(2),
  },
}));

interface FlsGridProps {
  string?: any;
}

const FlsGrid = (props: PropsWithChildren<FlsGridProps>) => {
  const classes = useGridStyles();

  const grid = useRef<HTMLDivElement>(null);
  const items = useRef<HTMLDivElement[]>([]);

  useEffect(() => {
    items.current = items.current.slice(0, React.Children.count(props.children));
  }, [props.children]);

  function resizeGridItem(item: HTMLDivElement) {
    if (grid.current && item) {
      const content = item.querySelector('div.content');
      if (content) {
        const rowHeight = parseInt(
          window.getComputedStyle(grid.current).getPropertyValue('grid-auto-rows'),
        );
        const rowGap = parseInt(
          window.getComputedStyle(grid.current).getPropertyValue('grid-row-gap'),
        );
        const rowSpan = Math.ceil(
          (content.getBoundingClientRect().height + rowGap) / (rowHeight + rowGap),
        );
        item.style.gridRowEnd = 'span ' + rowSpan;
      }
    }
  }

  function resizeAll() {
    items.current.forEach((item) => resizeGridItem(item));
  }

  useEffect(() => {
    window.addEventListener('resize', resizeAll);
    return () => {
      window.removeEventListener('resize', resizeAll);
    };
  }, []);

  useLayoutEffect(() => {
    const observer = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        resizeGridItem(entry.target.parentElement as HTMLDivElement);
      });
    });
    items.current.forEach((item) => {
      const content = item.querySelector('div.content');
      if (content) {
        observer.observe(content);
      }
    });
    resizeAll();
    return () => observer.disconnect();
  }, [props.children]);

  const childrenWithProps = React.Children.map(props.children, (child, index) => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    return (
      <FlsGridItem
        resizeGridItem={resizeGridItem}
        ref={(el) => {
          if (el) {
            items.current[index] = el;
          }
        }}
      >
        {child}
      </FlsGridItem>
    );
  });

  return (
    <div className={classes.root} ref={grid}>
      {childrenWithProps}
    </div>
  );
};

export default FlsGrid;
