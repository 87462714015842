import { useRef, useMemo } from 'react';

/**
 * Custom code to avoid using paid material ui functions.
 * Source: https://stackoverflow.com/questions/66726455/material-ui-get-all-rows-from-datagrid
 * Functionality: Replicate apiRef that xGrid use.
 * Needed when attempting to actually read out the data from datagrid
 *  */
export function useApiRef(columns) {
  const apiRef = useRef(null);
  const _columns = useMemo(
    () =>
      columns.concat({
        field: '__HIDDEN__',
        headerName: ' ',
        width: 0,
        minWidth: 0,
        maxWidth: 0,
        flex: 0,
        resizable: false,
        sortable: false,
        menubar: false,
        filterable: false,
        disableColumnMenu: true,
        disableReorder: true,
        hideSortIcons: true,
        renderHeader: (params) => {
          return null;
        },
        renderCell: (params) => {
          apiRef.current = params.api;
          return null;
        },
      }),
    [columns],
  );
  return { apiRef, columns: _columns };
}
