import React from 'react';
import { FormControlLabel, makeStyles, Radio, RadioProps } from '@material-ui/core';
import clsx from 'clsx';
import CheckIcon from '../../assets/icons/ico_check.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: '22px',
    height: '22px',
    border: '2px solid',
    borderColor: theme.palette.button.radioOutline,
    backgroundColor: theme.palette.background.paper,
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: theme.palette.button.radioOutline,
    },
  },
  checkedIcon: {
    display: 'block',
    backgroundColor: theme.palette.primary.main,
    backgroundImage: `url(${CheckIcon})`,
    backgroundPosition: 'center',
    border: '2px solid',
    borderColor: theme.palette.primary.main,
    width: '22px',
    height: '22px',
    'input:hover ~ &': {
      backgroundColor: theme.palette.button.highlight,
    },
  },
}));

interface FlsRadioProps extends RadioProps {
  value: string | number | boolean;
  label: string | number;
  disabled?: boolean;
  labelPlacement?: 'bottom' | 'top' | 'end' | 'start';
}
/**
 * This component is used as a child of the the material-ui/core RadioGroup.
 * The RadioGroup controls the input and value of the Radio, in addition to direction (row/column)
 */
const FlsRadio = ({ value, label, disabled, labelPlacement, ...props }: FlsRadioProps) => {
  const classes = useStyles();
  return (
    <FormControlLabel
      value={value}
      label={label}
      labelPlacement={labelPlacement}
      disabled={disabled}
      control={
        <Radio
          disableRipple
          checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
          icon={<span className={classes.icon} />}
          {...props}
        />
      }
    />
  );
};

export default FlsRadio;
