import React from 'react';
import { FramoButton } from '../../components/buttons';
import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  primaryButtonContainer: {
    padding: (props: SelectVesselProps) =>
      props?.orientation === 'vertical' ? theme.spacing(2, 0, 0, 0) : theme.spacing(0, 0, 0, 2),
  },
  contentContainer: {
    display: 'flex',
    flexDirection: (props: SelectVesselProps) =>
      props?.orientation === 'vertical' ? 'column' : 'row',
    justifyContent: 'space-between',
    width: '550px',
    paddingTop: '10px',
  },
}));

interface SelectVesselProps {
  orientation?: 'horisontal' | 'vertical';
  path?: string;
}
// Will not compile if not formatted as props, path.
const SelectVesselButtons: React.FC<SelectVesselProps> = (props, path) => {
  const classes = useStyles(props);
  const history = useHistory();

  return (
    <div className={classes.contentContainer}>
      <div>
        <FramoButton buttonStyle='styleOutlined' handleClick={() => undefined}>
          Select from other source
        </FramoButton>
      </div>
      <div className={classes.primaryButtonContainer}>
        <FramoButton handleClick={() => history.push(path)}>Add sample from vessel</FramoButton>
      </div>
    </div>
  );
};

export default SelectVesselButtons;
