import React, { useEffect, useState } from 'react';
import AdminMenu from '../../../components/menus/AdminMenu';
import FlsDataGrid from '../../../components/datagrid/FlsDataGrid';
import { DatagridToolbar, Loadingbar } from '../../../components/datagrid';
import { FormFieldValidationRule, Classifier, useApiClient } from '../../../api';
import { makeStyles } from '@material-ui/core/styles';
import { GridColDef } from '@material-ui/data-grid';
import { FlsLabel } from '../../../components/util';
import { FlsSelect } from '../../../components/inputs';
import { MenuItem } from '@material-ui/core';
import FormFieldValidationRuleRowActionButtons from './FormFieldValidationRuleRowActionButtons';

const useStyles = makeStyles((theme) => ({
  classTresholdsGridContainer: {},
  classTresholdsGrid: {
    margin: theme.spacing(1, 4),
  },
}));

const columns: GridColDef[] = [
  {
    field: 'id',
    type: 'string',
    headerName: 'ID',
    flex: 1,
    filterable: false,
    hide: true,
    sortable: false,
  },
  {
    field: 'formFieldId',
    type: 'string',
    headerName: 'Form Field Id',
    flex: 1,
    filterable: false,
    hide: true,
    sortable: false,
  },
  {
    field: 'type',
    type: 'string',
    headerName: 'Type',
    flex: 1,
    filterable: false,
    sortable: false,
  },
  {
    field: 'value',
    type: 'number',
    headerName: 'Value',
    flex: 1,
    filterable: false,
    sortable: false,
  },
  {
    field: 'regex',
    type: 'string',
    headerName: 'Regex',
    flex: 1,
    filterable: false,
    sortable: false,
  },
  {
    field: 'errorText',
    type: 'string',
    headerName: 'Error Text',
    flex: 1,
    filterable: false,
    sortable: false,
  },
  {
    field: 'severity',
    type: 'string',
    headerName: 'Severity',
    flex: 1,
    filterable: false,
    sortable: false,
  },
  {
    field: 'showNotification',
    type: 'boolean',
    headerName: 'Show Notification',
    flex: 1,
    filterable: false,
    sortable: false,
  },
  {
    field: 'classifierId',
    type: 'string',
    headerName: 'Classifier Id',
    flex: 1,
    filterable: false,
    hide: true,
    sortable: false,
  },
  {
    field: '',
    headerName: 'Actions',
    sortable: false,
    renderCell: FormFieldValidationRuleRowActionButtons,
    editable: false,
    width: 115,
    resizable: false,
    filterable: false,
    disableColumnMenu: true,
    disableReorder: true,
    hideSortIcons: true,
  },
];

const AdminFormFieldValidationRulesPage = () => {
  const [classifiers, setClassifiers] = useState<Classifier[]>([]);
  const [formFieldValidationRules, setFormFieldValidationRules] = useState<
    FormFieldValidationRule[]
  >([]);
  const [selectedClassifierId, setSelectedClassifierId] = useState<string | undefined>('All');
  const [filteredFormFieldValidationRules, setFilteredFormFieldValidationRules] = useState<
    FormFieldValidationRule[]
  >([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>();
  const classes = useStyles();
  const client = useApiClient();
  const [columnsButtonEl, setColumnsButtonEl] = React.useState(null);

  useEffect(() => {
    const fetchFormFieldValidationRules = async () => {
      setLoading(true);

      try {
        const FormFieldValidationRules = await client.getFormFieldValidationRules();
        setFormFieldValidationRules(FormFieldValidationRules);
      } catch (error) {
        console.error(error);
        setError('Unable to FormFieldValidationRules');
      }

      setLoading(false);
    };

    fetchFormFieldValidationRules();
  }, []);

  useEffect(() => {
    const fetchClassifiers = async () => {
      setLoading(true);

      try {
        const classifiers = await client.getClassifiers();
        setClassifiers(classifiers);
      } catch (error) {
        console.error(error);
        setError('Unable to fetch classifiers');
      }

      setLoading(false);
    };

    fetchClassifiers();
  }, []);

  useEffect(() => {
    if (selectedClassifierId === undefined || selectedClassifierId === 'All') {
      setFilteredFormFieldValidationRules(formFieldValidationRules);
    } else {
      const filteredData = formFieldValidationRules.filter(
        (threshold) =>
          threshold.classifierId ===
          (selectedClassifierId === 'Shared' ? null : selectedClassifierId),
      );
      setFilteredFormFieldValidationRules(filteredData);
    }
  }, [selectedClassifierId, formFieldValidationRules]);

  const handleClassifierChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedClassifierId(event.target.value as string);
  };

  return (
    <>
      <AdminMenu />
      <div className={classes.classTresholdsGrid}>
        <FlsLabel htmlFor={'selectedClassifierId'} textTransform='none'>
          Classifier
        </FlsLabel>
        <FlsSelect
          isLight
          size='normal'
          id='selectedClassifierId'
          value={selectedClassifierId || ''}
          onChange={handleClassifierChange}
        >
          <MenuItem value='All'>All</MenuItem>
          {Array.from(
            new Set(
              formFieldValidationRules.map(
                (formFieldValidationRule) => formFieldValidationRule.classifierId,
              ),
            ),
          ).map((id) =>
            id == null ? (
              <MenuItem key={'Shared'} value={'Shared'}>
                Shared
              </MenuItem>
            ) : (
              <MenuItem key={id} value={id}>
                {classifiers.find((classifier) => classifier.id === id)?.name}
              </MenuItem>
            ),
          )}
        </FlsSelect>
      </div>
      <div className={classes.classTresholdsGrid}>
        <FlsDataGrid
          rows={filteredFormFieldValidationRules ? filteredFormFieldValidationRules : []}
          getRowId={(row) => row.id}
          columns={columns}
          loading={loading}
          error={error}
          disableSelectionOnClick
          components={{
            Toolbar: DatagridToolbar,
            LoadingOverlay: Loadingbar,
          }}
          componentsProps={{
            toolbar: {
              addNewLink: '/admin/formFieldValidationRules/add',
              setColumnsButtonEl: setColumnsButtonEl,
            },
            panel: {
              anchorEl: columnsButtonEl,
            },
          }}
        />
      </div>
    </>
  );
};

export default AdminFormFieldValidationRulesPage;
