import React from 'react';
import { useHistory } from 'react-router-dom';
import { GridCellParams, GridRowData } from '@material-ui/data-grid';
import { Divider, makeStyles, Popover, Typography } from '@material-ui/core';
import { FramoAction } from '../../../components/buttons';
import { useModalContext, useSampleContext } from '../../../contexts';
import { Sample } from '../../../api';
import SampleLinks from '../../../modals/SampleLinks';
import { IconHandler } from '../../../components/util';
import { revisionNumberToNameConverter } from '../../../utils/revisionUtils';

const useStyles = makeStyles((theme) => ({
  actionButtons: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  searchButton: {
    backgroundColor: 'inherit',
    border: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
    marginTop: 4,
  },
  divider: {
    margin: theme.spacing(1),
  },
  popoverElements: {
    padding: '5px 10px 5px 10px',
    display: 'flex',
  },
  revisionName: {
    padding: '4px',
    flexBasis: '8rem',
  },
  dividerStyle: {
    marginTop: '1px',
  },
  popoverHeading: {
    textAlign: 'center',
    fontSize: '1.25rem',
    margin: '0.5rem',
  },
}));

const RowNavigationButtons = (params: GridCellParams) => {
  const classes = useStyles();
  const history = useHistory();
  const { setContent, setIsOpen, setModalProps } = useModalContext();
  const { setSampleId } = useSampleContext();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigation = (id) => {
    setIsOpen(false);
    setSampleId(id);
    history.push(`/createSample/${id}`);
  };

  const handleLinkModal = (grd: GridRowData) => {
    setModalProps({
      title: 'External Links For ' + grd.shortId,
      titleIcon: undefined,
    });
    setContent(<SampleLinks selectedSample={grd as Sample} />);
    setIsOpen(true);
  };
  return (
    <div className={classes.actionButtons}>
      <FramoAction colorVariant='secondary' handleClick={() => handleNavigation(params.row.id)}>
        Info
      </FramoAction>
      <Divider className={classes.divider} orientation='vertical' flexItem />
      <FramoAction colorVariant='secondary' handleClick={() => handleLinkModal(params.row)}>
        Links
      </FramoAction>
      <Divider className={classes.divider} orientation='vertical' flexItem />
      <FramoAction
        colorVariant='secondary'
        handleClick={() => history.push(`/analyses/${params.row.id}`)}
      >
        Results
      </FramoAction>
      <Divider className={classes.divider} orientation='vertical' flexItem />
      {params.row.revisions.slice(0, -1) && params.row.revisions.slice(0, -1).length > 0 && (
        <button className={classes.searchButton} type='button' onClick={handleClick}>
          <IconHandler iconIdentifier='arrow' />
        </button>
      )}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Typography className={classes.popoverHeading}>Revisions</Typography>
        {params.row.revisions.slice(0, -1).map((sampleRevision, index) => {
          return (
            <div key={sampleRevision.sampleId}>
              <div className={classes.popoverElements}>
                <Typography className={classes.revisionName}>
                  {revisionNumberToNameConverter(index)}
                </Typography>
                <Divider className={classes.divider} orientation='vertical' flexItem />

                <FramoAction
                  colorVariant='secondary'
                  handleClick={() => handleNavigation(sampleRevision.sampleId)}
                >
                  Info
                </FramoAction>
                <Divider className={classes.divider} orientation='vertical' flexItem />
                <FramoAction
                  colorVariant='secondary'
                  handleClick={() => history.push(`/analyses/${sampleRevision.sampleId}`)}
                >
                  Results
                </FramoAction>
              </div>
              <Divider className={classes.dividerStyle} variant='fullWidth' />
            </div>
          );
        })}
      </Popover>
    </div>
  );
};

export default RowNavigationButtons;
