import { CircularProgress, makeStyles, Theme, Typography } from '@material-ui/core';
import React, { PropsWithChildren } from 'react';
import { IconHandler } from '.';
import { SubmissionState } from '../../models';

const useStyles = makeStyles((theme: Theme) => ({
  buttonContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconContainer: {
    width: theme.spacing(2),
    height: theme.spacing(2),

    marginLeft: theme.spacing(2),
    color: (props: SubmissionBlockProps) =>
      props.buttonStyle === 'styleFilled'
        ? theme.palette.button.text
        : theme.palette.button.textAlt,
    '& > *': {
      stroke: (props: SubmissionBlockProps) =>
        props.submissionState === 'error' || props.submissionState === 'submitted'
          ? theme.palette.button.text
          : props.buttonStyle === 'styleFilled'
          ? theme.palette.button.text
          : theme.palette.button.textAlt,
    },
  },
  text: {
    whiteSpace: 'nowrap',
  },
}));

/**Components needed to modify any framo button to become a responsive loading button
 * @SubmissionBlock - Child of button
 * @param submissionstate Three possible states of an action
 * @param text text to put in button
 * @param buttonstyle colour icons and text to fit into general button style
 *
 * @SubmissionProgressIcon - Switches icon out depending on state
 *
 * @SubmissionProgressClass - Needs to be insterted into the classname declaration for the button
 * to enable styling of button to have error - success styling
 */

export interface SubmissionBlockProps {
  submissionState: SubmissionState;
  buttonStyle: 'styleFilled' | 'styleOutlined';
}

export const SubmissionProgressIcon = (props: SubmissionBlockProps) => {
  const classes = useStyles(props);
  switch (props.submissionState) {
    case 'pending':
      return <CircularProgress size={20} className={classes.iconContainer} />;
    case 'submitted':
      return <IconHandler iconIdentifier='check' className={classes.iconContainer} />;
    case 'error':
      return <IconHandler iconIdentifier='close' className={classes.iconContainer} />;
    default:
      return <></>;
  }
};

export const SubmissionBlock = (props: PropsWithChildren<SubmissionBlockProps>) => {
  const classes = useStyles(props);

  return (
    <div className={classes.buttonContent}>
      {props.children && (
        <Typography variant='body2' className={classes.text}>
          {props.children}
        </Typography>
      )}
      {SubmissionProgressIcon(props)}
    </div>
  );
};
