import React, { useState } from 'react';
import { createSuperContext } from 'react-super-context';

export interface ModalProps {
  title?: string;
  titleIcon?: React.ReactNode;
}

const [formContext, useFormContext] = createSuperContext(() => {
  const [usingForms, setUsingForms] = useState<string[]>();

  return { usingForms, setUsingForms };
});

export { formContext, useFormContext };
