import React, { ChangeEvent, useState, useCallback, useEffect } from 'react';
import { makeStyles, MenuItem, RadioGroup } from '@material-ui/core';
import { FlsInputField, FlsRadio, FlsSelect } from '../../../components/inputs';
import { FlsLabel, HelperText, IconHandler } from '../../../components/util';
import { validateField, getNumberValueFromRecord } from '../../../utils/formUtils';
import { FieldErrorType, isValidIconIdentifier } from '../../../models/components';
import { FormField } from '../../../api';
import { useDebouncedCallback } from 'use-debounce';
import { SelectComment } from '../../../modals';
import { FramoAction } from '../../../components/buttons';
import { useModalContext } from '../../../contexts';
import { allValuesNotEqual, mapToFieldSize } from '../../../utils';

const useStyles = makeStyles((theme) => ({
  fieldContainer: {
    paddingRight: theme.spacing(1.5),
    width: '100%',
    maxWidth: '100%',
    marginBottom: theme.spacing(1),
    '& > label': {
      textTransForm: 'none',
    },
  },
  computedFieldContainer: {
    paddingRight: theme.spacing(1.5),
    width: '60%',
    maxWidth: '100%',
    marginBottom: theme.spacing(1),
    '& > label': {
      textTransForm: 'none',
    },
  },
  multipleFieldsContainer: {
    display: 'flex',
  },
  particleFieldContainer: {
    paddingRight: theme.spacing(2),
  },
  readOnly: {
    padding: '8px 12px',
    display: 'block',
  },
  label: {
    color: theme.palette.primary.main,
  },
}));

interface FieldProps {
  formField: FormField;
  fieldChanged(
    discrimintator: string,
    formField: FormField,
    value: any,
    existingValue: boolean,
  ): void;
  value: any | undefined;
  isDirty: boolean;
  currentClassifierId?: string;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
  disabled?: boolean;
  important?: boolean;
}

const Field = ({
  formField,
  fieldChanged,
  value,
  isDirty,
  setIsDirty,
  currentClassifierId,
  disabled,
  important,
}: FieldProps) => {
  const formFieldInputTypeAlias = formField?.formFieldType?.formFieldInputType?.alias;
  const discriminator = formField?.formFieldType.formFieldDataType.discriminator;
  const formFieldLabel = formField?.label;
  const formFieldId = formField.id;
  const rules = formField.validationRules;
  const prevalues = formField.preValues;
  const classifiers = formField.classifiers;
  const isClassifierSelector = formField.isClassifierSelector;
  const icon = isValidIconIdentifier(formField.formFieldType.icon)
    ? formField.formFieldType.icon
    : undefined;
  const suffix = formField.formFieldType.suffix;
  const fieldSize = mapToFieldSize(formField.formFieldType.fieldSize);
  const showPlaceholder = formField.formFieldType.showPlaceholder;
  const [currentFieldError, setCurrentFieldError] = useState<FieldErrorType>({
    errorText: '',
    severity: 'Normal',
    showNotification: false,
  });
  const classes = useStyles();
  const { setModalProps, setContent, setIsOpen } = useModalContext();

  const handlePrevalueModal = (action: (value: string) => void) => {
    setModalProps({
      title: 'Predefined Commets',
      titleIcon: undefined,
    });
    setContent(<SelectComment selectValue={action} prevalues={prevalues} />);
    setIsOpen(true);
  };

  const debouncedValidationCheck = useDebouncedCallback(
    (value) => {
      for (let index = 0; index < rules.length; index++) {
        const rule = rules[index];

        if (rule.classifierId && rule.classifierId !== currentClassifierId) {
          continue;
        }
        const fieldError = validateField(rule, value);
        if (fieldError) {
          setCurrentFieldError(fieldError);
          setIsDirty(false);
          return;
        }
      }
      setCurrentFieldError({ errorText: '', severity: 'Normal', showNotification: false });
      setIsDirty(false);
    },
    // delay in ms
    300,
  );

  // Call the debounced validation function with the proper value
  const validateFields = useCallback(() => {
    switch (formFieldInputTypeAlias) {
      case 'numberField':
        debouncedValidationCheck(value?.DecimalValue || value?.IntValue);
        break;
      case 'textField':
        debouncedValidationCheck(value?.StringValue);
        break;
      default:
        break;
    }
  }, [debouncedValidationCheck, formFieldInputTypeAlias, value]);

  // Must use useEffect to call validation since the value of observerfields may change without onChange
  useEffect(() => {
    if (isDirty) {
      validateFields();
    }
  }, [isDirty, validateFields]);

  //Initial rendering check for fields with value from server
  useEffect(() => {
    if (
      !isDirty &&
      (value?.DecimalValue || value?.IntValue || value?.StringValue || value?.LongStringValue)
    ) {
      validateFields();
    }
  }, [value]);

  //To make sure the placeholder value is actually selected
  useEffect(() => {
    if (
      (formFieldInputTypeAlias === 'dropdown' || formFieldInputTypeAlias === 'radioButtons') &&
      (isClassifierSelector ? classifiers[0]?.classifier.name : prevalues[0]?.value)
    ) {
      //TODO: This needs to change. We can't post this when no other value is added.
      fieldChanged(
        discriminator,
        formField,
        isClassifierSelector ? classifiers[0]?.classifier.name : prevalues[0]?.value,
        true,
      );
    }
  }, [discriminator, fieldChanged, formField, formFieldInputTypeAlias, prevalues, classifiers]);

  if (formField.computed) {
    return (
      <div className={classes.computedFieldContainer}>
        <FlsLabel important={important} htmlFor={formFieldId} textTransform='none'>
          {formFieldLabel}
        </FlsLabel>
        <span className={classes.readOnly}>
          {value?.DecimalValue ||
            value?.IntValue ||
            value?.StringValue ||
            value?.LongStringValue ||
            '-'}
        </span>
        {currentFieldError.errorText && (
          <HelperText
            id={formFieldId}
            helperText={currentFieldError.errorText}
            severity={currentFieldError.severity}
          />
        )}
      </div>
    );
  } else {
    switch (formFieldInputTypeAlias) {
      case 'numberField':
        return (
          <div className={classes.fieldContainer}>
            <FlsLabel important={important} htmlFor={formFieldId} textTransform='none'>
              {formFieldLabel}
            </FlsLabel>
            <FlsInputField
              id={formFieldId}
              placeholder={showPlaceholder ? formFieldLabel : ''}
              icon={<IconHandler iconIdentifier={icon} />}
              fieldSize={fieldSize}
              suffix={suffix}
              type='number'
              fullWidth
              severity={currentFieldError.severity}
              showNotificationIcon={currentFieldError.showNotification}
              value={getNumberValueFromRecord(value, discriminator)}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                if (!isDirty) {
                  setIsDirty(true);
                }
                fieldChanged(discriminator, formField, e.target.valueAsNumber, false);
              }}
              lang='nb-NO'
              disabled={disabled}
            />
            {currentFieldError.errorText && (
              <HelperText
                id={formFieldId}
                helperText={currentFieldError.errorText}
                severity={currentFieldError.severity}
              />
            )}
          </div>
        );
      case 'textField':
        return (
          <div className={classes.fieldContainer}>
            <FlsLabel htmlFor={formFieldId} textTransform='none'>
              {formFieldLabel}
            </FlsLabel>
            <FlsInputField
              id={formFieldId}
              placeholder={showPlaceholder ? formFieldLabel : ''}
              icon={<IconHandler iconIdentifier={icon} />}
              fieldSize={fieldSize}
              suffix={suffix}
              severity={currentFieldError.severity}
              showNotificationIcon={currentFieldError.showNotification}
              type='text'
              fullWidth
              value={value?.StringValue || ''}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                if (!isDirty) {
                  setIsDirty(true);
                }
                fieldChanged(discriminator, formField, e.target.value, false);
              }}
              disabled={disabled}
            />
            {currentFieldError.errorText && (
              <HelperText
                id={formFieldId}
                helperText={currentFieldError.errorText}
                severity={currentFieldError.severity}
              />
            )}
          </div>
        );
      case 'dropdown':
        return (
          <div className={classes.fieldContainer}>
            <FlsLabel htmlFor={formFieldId} textTransform='none'>
              {formFieldLabel}
            </FlsLabel>
            <FlsSelect
              id={formFieldId}
              value={
                value?.StringValue ||
                (isClassifierSelector ? classifiers[0]?.classifier.name : prevalues[0]?.value) ||
                ''
              }
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                fieldChanged(discriminator, formField, e.target.value, false);
              }}
              fullWidth
              disabled={disabled}
            >
              {prevalues.length === 0 && classifiers.length === 0 ? (
                <MenuItem disabled>No options</MenuItem>
              ) : isClassifierSelector ? (
                classifiers.map((mapClassifier) => (
                  <MenuItem key={mapClassifier.classifierId} value={mapClassifier.classifier.name}>
                    {mapClassifier.classifier.name}
                  </MenuItem>
                ))
              ) : (
                prevalues.map((prevalue) => (
                  <MenuItem key={prevalue.id} value={prevalue.value}>
                    {prevalue.label || prevalue.value}
                  </MenuItem>
                ))
              )}
            </FlsSelect>
          </div>
        );
      case 'textArea':
        return (
          <div className={classes.fieldContainer}>
            <FlsInputField
              id={formFieldId}
              multiline={true}
              placeholder={showPlaceholder ? formFieldLabel : ''}
              icon={<IconHandler iconIdentifier={icon} />}
              fieldSize={fieldSize}
              suffix={suffix}
              type='text'
              severity={currentFieldError.severity}
              showNotificationIcon={currentFieldError.showNotification}
              rows={5}
              fullWidth
              value={value?.LongStringValue || ''}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                if (!isDirty) {
                  setIsDirty(true);
                }
                fieldChanged(discriminator, formField, e.target.value, false);
              }}
              disabled={disabled}
            />
            {currentFieldError.errorText && (
              <HelperText
                id={formFieldId}
                helperText={currentFieldError.errorText}
                severity={currentFieldError.severity}
              />
            )}
            {formFieldLabel === 'Certificate Comment' && prevalues && !disabled && (
              <FramoAction
                iconIdentifier='add'
                type='button'
                handleClick={() =>
                  handlePrevalueModal((value: string) => {
                    if (!isDirty) {
                      setIsDirty(true);
                    }
                    fieldChanged(discriminator, formField, value, false);
                  })
                }
              >
                Add predefined comment
              </FramoAction>
            )}
          </div>
        );
      case 'radioButtons':
        return (
          <div className={classes.fieldContainer}>
            <FlsLabel htmlFor={formFieldId} textTransform='none'>
              {formFieldLabel}
            </FlsLabel>
            <RadioGroup
              row
              id={'sampleBottleType'}
              value={value?.StringValue || prevalues[0]?.value || ''}
              onChange={(e) => {
                if (!isDirty) {
                  setIsDirty(true);
                }
                fieldChanged(discriminator, formField, e.target.value, false);
              }}
            >
              {prevalues.map((fieldValue, index) => {
                return (
                  <FlsRadio
                    label={fieldValue.label || fieldValue.value}
                    value={fieldValue.value}
                    key={index}
                    disabled={
                      allValuesNotEqual(
                        fieldValue.value,
                        value?.StringValue,
                        prevalues[0]?.value,
                        '',
                      ) && disabled
                    }
                  />
                );
              })}
            </RadioGroup>
          </div>
        );
      default:
        return <p>Unsupported Formfield, {formFieldInputTypeAlias}</p>;
    }
  }
};

export default Field;
