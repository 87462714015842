import React, { PropsWithChildren } from 'react';
import { Link, Typography, makeStyles, Theme } from '@material-ui/core';
import { IconHandler } from '../util';
import { IconIdentifier } from '../../models/components';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    height: theme.spacing(4),
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: (props: FramoActionProps) => (props.iconRight ? 'row-reverse' : 'row'),
    cursor: (props: FramoActionProps) => (props.disabled ? 'default' : 'pointer'),
    color: (props: FramoActionProps) =>
      props.disabled
        ? theme.palette.text.disabled
        : props.colorVariant === 'secondary'
        ? theme.palette.primary.main
        : props.colorVariant === 'hazard'
        ? theme.palette.error.main
        : theme.palette.text.primary,
    transition: '0.2s',

    '&:hover, &:focus': {
      textDecoration: (props: FramoActionProps) => (props.disabled ? 'none' : 'underline'),
      color: (props: FramoActionProps) =>
        props.disabled ? theme.palette.text.disabled : theme.palette.button.highlight,
      '& > *': {
        '& > *': {
          stroke: (props: FramoActionProps) =>
            props.disabled ? theme.palette.text.disabled : theme.palette.button.highlight,
        },
      },
    },
    '&:active': {
      color: (props: FramoActionProps) =>
        props.disabled ? theme.palette.text.disabled : theme.palette.button.active,
      '& > *': {
        '& > *': {
          stroke: (props: FramoActionProps) =>
            props.disabled ? theme.palette.text.disabled : theme.palette.button.active,
        },
      },
    },
  },

  iconContainer: {
    transition: '0.2s',
    marginRight: (props: FramoActionProps) => (props.iconRight ? '0px' : '4px'),
    marginLeft: (props: FramoActionProps) => (props.iconRight ? '4px' : '0px'),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

interface FramoActionProps extends IconIdentifier {
  handleClick?: () => void;
  colorVariant?: 'primary' | 'secondary' | 'hazard';
  type?: 'button' | 'reset' | 'submit';
  form?: string;
  disabled?: boolean;
  iconRight?: boolean;
}

const FramoAction = (props: PropsWithChildren<FramoActionProps>) => {
  const { iconIdentifier, handleClick, form, type, disabled, children } = props;
  const classes = useStyles(props);

  return (
    <Link
      component='button'
      disabled={disabled}
      className={classes.root}
      onClick={handleClick}
      form={form}
      type={type}
    >
      {iconIdentifier && (
        <div className={classes.iconContainer}>
          <IconHandler iconIdentifier={iconIdentifier} />
        </div>
      )}
      <Typography variant='body2'>{children}</Typography>
    </Link>
  );
};

export default FramoAction;
