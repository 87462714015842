import { createSuperContext } from 'react-super-context';
import { useMsal } from '@azure/msal-react';
import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';
import { useCallback, useMemo, useState } from 'react';
import { FlsAuthenticationResult, MsalIdToken, MsalIdTokenGroup } from '../../src/models';
import { msalScopes } from '../MsalConfiguration';
import { UserLevel } from '../api';

// "cache" current token refresh promise to prevent multiple token refresh requests
let acquireTokenPromise: Promise<FlsAuthenticationResult> | undefined = undefined;

const [authContext, useAuthContext] = createSuperContext(() => {
  const { instance, accounts } = useMsal();

  const account: AccountInfo | null =
    instance.getActiveAccount() ?? instance.getAllAccounts()[0] ?? accounts[0];

  const [authResult, setAuthResult] = useState<FlsAuthenticationResult>();

  // msal's internal caching mechanism does not seem to work, so we implement our own
  const getOrRefreshToken = useCallback(async () => {
    if (authResult) {
      const currentTimeSec = new Date().getTime() / 1000;
      if (authResult.idTokenClaims.exp > currentTimeSec) return authResult;
    }
    if (!account) throw new Error('Found no accounts');

    if (acquireTokenPromise) {
      return acquireTokenPromise;
    }

    try {
      acquireTokenPromise = acquireToken(instance, account);
      const result = await acquireTokenPromise;
      acquireTokenPromise = undefined;

      setAuthResult(result);
      return result;
    } catch (e) {
      await instance.logout();
      throw e;
    }
  }, [authResult, setAuthResult, instance, account]);

  const logout = () => {
    instance.logout();
  };

  const hasGroup = useMemo(() => {
    const groups = authResult?.idTokenClaims?.groups;
    return (group: MsalIdTokenGroup) => groups?.includes(group) ?? false;
  }, [authResult]);

  const userLevel: UserLevel = useMemo(() => {
    const userLevelString = authResult?.idTokenClaims['extension_UserLevel'];
    const userLevel: UserLevel = UserLevel[userLevelString as keyof typeof UserLevel];

    return userLevel;
  }, [authResult]);

  const isLocalOrGlobalAdmin = () => {
    return userLevel === UserLevel.LocalAdmin || userLevel === UserLevel.GlobalAdmin;
  };

  const isGlobalAdmin = () => {
    return userLevel === UserLevel.GlobalAdmin;
  };

  return { getOrRefreshToken, logout, authResult, hasGroup, isLocalOrGlobalAdmin, isGlobalAdmin };
});

const acquireToken = async (instance: IPublicClientApplication, account: AccountInfo) => {
  try {
    const result = await instance
      .acquireTokenSilent({ scopes: msalScopes, account })
      .then((response) =>
        !response.accessToken
          ? instance.ssoSilent({
              scopes: msalScopes,
              loginHint: account.username,
            })
          : response,
      );

    const flsResult: FlsAuthenticationResult = {
      ...result,
      idTokenClaims: <MsalIdToken>{
        ...result.idTokenClaims,
        groups: result.idTokenClaims?.['groups']?.map((group) => group.toLowerCase()) ?? [],
      },
    };

    if (!flsResult.idTokenClaims.groups?.length) {
      console.warn('IdToken has no groups');
    }

    return flsResult;
  } catch (error) {
    console.error('Failed to acquire token', error);
    throw error;
  }
};

export { authContext, useAuthContext };
