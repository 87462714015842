import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import { SetTests } from '.';
import { FlsCard } from '../../../components/card';
import { VesselBox } from '../../../components/vessel';
import { useModalContext, useSampleContext } from '../../../contexts';
import { VesselModal } from '../../../modals';

const useStyles = makeStyles((theme) => ({
  sidebar: {
    marginRight: theme.spacing(2),
    minWidth: '350px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    [theme.breakpoints.up('md')]: {
      '& > *': {
        '&:last-child': {
          marginTop: theme.spacing(2),
        },
      },
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
      marginRight: 0,
      marginBottom: theme.spacing(2),
      '& > *': {
        '&:last-child': {
          marginLeft: theme.spacing(2),
          marginTop: '0px',
        },
      },
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      '& > *': {
        '&:last-child': {
          marginLeft: '0px',
          marginTop: theme.spacing(2),
        },
      },
    },
  },

  overrideChip: {
    flexWrap: 'nowrap',
    flexDirection: 'column',
    textAlign: 'left',
    boxShadow: 'none',
    alignItems: 'center',
    '& > *': {
      '& > *': {
        width: '240px',
        '& > *': {
          width: '100%',
          '& > *': {
            dispaly: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          },
        },
      },
    },
  },

  overrideCard: {
    height: '100%',
  },
}));

interface CreateSampleSidebarProps {
  formik: any;
  setVesselOverwrite: React.Dispatch<React.SetStateAction<boolean>>;
  disabled?: boolean;
}

const CreateSampleSidebar = (props: CreateSampleSidebarProps) => {
  const { formik, setVesselOverwrite, disabled } = props;
  const classes = useStyles();
  const { setIsOpen, setModalProps, setContent } = useModalContext();
  const { sample } = useSampleContext();

  const currentForms = useMemo(() => {
    if (formik.values.forms.length > 0) {
      return formik.values.forms.map((x) => x.id);
    }
    return (
      sample?.forms
        ?.sort((form1, form2) => form1.sortOrder - form2.sortOrder)
        .map((form) => form.id) ?? []
    );
  }, [sample?.forms]);

  const handleModalVessel = () => {
    setModalProps({
      title: 'Vessel Information',
      titleIcon: undefined,
    });
    setContent(<VesselModal />);
    setIsOpen(true);
  };
  return (
    <div className={classes.sidebar}>
      <VesselBox
        openModal={handleModalVessel}
        setVesselOverwrite={setVesselOverwrite}
        disabled={disabled}
      />
      <FlsCard header='Planned Tests' showDivider variant='normal' className={classes.overrideCard}>
        <SetTests
          updateInvoiceType={(testType: string) =>
            formik.setFieldValue('internalInvoiceType', testType)
          }
          className={classes.overrideChip}
          currentForms={currentForms}
          showPresets
          handleChipChange={(chips) =>
            formik.setFieldValue(
              'forms',
              chips
                ?.filter((chip) => chip.clicked)
                .map((chip) => {
                  return { id: chip.id };
                }),
            )
          }
          disabled={disabled}
        />
      </FlsCard>
    </div>
  );
};

export default CreateSampleSidebar;
