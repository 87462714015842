import React from 'react';
import { CircularProgress, makeStyles, TableCell, TableRow, Typography } from '@material-ui/core';
import { useApiClient } from '../../api';
import { useSampleContext, useVesselContext } from '../../contexts';
import { useLoading } from '../../hooks';
import { isEmpty } from 'ramda';
import FlsTable from '../tables/FlsTable';
import dateFormat from '../../utils/dateFormat';

const useStyles = makeStyles((theme) => ({
  keyContainer: {
    padding: '8px 8px 8px 16px',
    color: theme.palette.text.secondary,
  },
  valueContainer: {
    padding: '8px 8px 8px 8px',
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
  tableContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '400px',
    [theme.breakpoints.down('md')]: { maxHeight: 'none', maxWidth: 'none' },
    maxHeight: '560px',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      backgroundColor: theme.palette.secondary.main,
      width: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.main,
      margin: '1px',
      borderRadius: '6px',
    },
  },
  tableHeader: {
    '& > *': {
      '&:last-child': {
        textAlign: 'center',
      },
    },
  },
}));

const VesselInformation = () => {
  const classes = useStyles();
  const { vessel, setVessel } = useVesselContext();
  const { sample } = useSampleContext();
  const { state, dispatch } = useLoading();
  const client = useApiClient();

  React.useEffect(() => {
    async function getVesselOnSample() {
      if (sample?.installationId) {
        dispatch({ type: 'REQUEST' });
        try {
          const sampleVessel = await client.getVessel(sample.installationId);
          setVessel(sampleVessel);
          dispatch({ type: 'SUCCESS' });
        } catch (error) {
          dispatch({
            type: 'ERROR',
            toast: 'Unable to retrieve previously registered vessel.',
          });
          console.log(error);
        }
      } else {
        dispatch({
          type: 'ERROR',
          toast: 'Vessel retrieval error: Missing Installation ID',
        });
      }
    }
    if (!vessel || isEmpty(vessel)) {
      getVesselOnSample();
    }
  }, [sample]);

  const PROPER_VESSEL_LABEL = {
    originalOilType: 'Original Oil Type',
    oilType: 'Oil Type',
    imo: 'Imo Number',
    owner: 'Owner',
    manager: 'Manager',
    customer: 'Customer',
    partOfOmp: 'Part Of Omp',
    internalInvoiceOffice: 'I. Invoice Office',
    lastFilterChange: 'Last Filter Change',
    orderNo: 'Order Number',
    name: 'Vessel Name',
    ownerCode: 'Owner Code',
  };
  return (
    <FlsTable
      headers={['Field', 'Value']}
      title='Vessel Data'
      styles={{ root: classes.tableContainer, tableHeader: classes.tableHeader }}
    >
      {state.state === 'pending' ? (
        <TableRow>
          <TableCell colSpan={2} align='center'>
            <CircularProgress size={24} />
          </TableCell>
        </TableRow>
      ) : !vessel || isEmpty(vessel) ? (
        <TableRow>
          <TableCell colSpan={2} align='center'>
            <Typography>No Entries...</Typography>
          </TableCell>
        </TableRow>
      ) : (
        Object.entries(vessel).map(([key, value], index) => (
          <>
            {key in PROPER_VESSEL_LABEL && (
              <TableRow key={index}>
                <TableCell className={classes.keyContainer}>{PROPER_VESSEL_LABEL[key]}</TableCell>
                <TableCell className={classes.valueContainer}>
                  {value
                    ? key === 'lastFilterChange?'
                      ? dateFormat.formatDateShortFormat(value.toString())
                      : value.toString()
                    : ' - '}
                </TableCell>
              </TableRow>
            )}
          </>
        ))
      )}
    </FlsTable>
  );
};

export default VesselInformation;
