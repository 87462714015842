import { LinearProgress } from '@material-ui/core';
import { GridOverlay } from '@material-ui/data-grid';
import React from 'react';

function Loadingbar() {
  return (
    <GridOverlay>
      <div style={{ position: 'absolute', top: 0, width: '100%', zIndex: 5 }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
}

export default Loadingbar;
