import React, { useState, useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import { ChipData } from '../models/components';
import { useModalContext, useSampleContext, useVesselContext } from '../contexts';
import { useApiClient } from '../api';
import { Calendar, VesselInformation } from '../components/vessel';
import { SetTests } from '../pages/create-sample-page/pageComponents';
import { useLoading } from '../hooks';
import { FramoButtonWithLoading } from '../components/buttons';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
  overrideChip: {
    textAlign: 'left',
    boxShadow: 'none',
    paddingTop: 0,
    justifyContent: 'center',
  },
  contentContainer: {
    display: 'flex',
    [theme.breakpoints.down('md')]: { flexDirection: 'column' },
  },
  testContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  header: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
}));

interface VesselModalProps {
  showTests?: boolean;
}

const VesselModal = (props: VesselModalProps) => {
  const { showTests } = props;
  const classes = useStyles();
  const { sample, fetchSample } = useSampleContext();
  const [chipData, setChipData] = useState<ChipData[]>();
  const { setIsOpen } = useModalContext();
  const client = useApiClient();
  const { state, dispatch } = useLoading<undefined>();
  const { vessel } = useVesselContext();

  const currentForms = useMemo(
    () =>
      sample?.forms
        ?.sort((form1, form2) => form1.sortOrder - form2.sortOrder)
        .map((form) => form.id) ?? [],
    [sample?.forms],
  );

  const confirmChanges = async () => {
    try {
      if (!sample) {
        return;
      }
      dispatch({ type: 'REQUEST' });
      await client.updateSampleForms({
        id: sample.id,
        forms: chipData?.filter((chip) => chip.clicked).map((chip) => chip.id) || [],
      });
      await fetchSample();
      dispatch({ type: 'SUCCESS' });
      setTimeout(() => setIsOpen(false), 1500);
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'ERROR',
        error: error,
        toast: 'Unable to save testconfiguration. Check console for more info',
      });
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.contentContainer}>
        <VesselInformation />
        <Calendar installationId={vessel?.orderNo ? vessel?.orderNo : sample?.installationId} />
      </div>
      {showTests && (
        <div className={classes.testContainer}>
          <SetTests
            className={classes.overrideChip}
            handleChipChange={(chips: ChipData[]) => setChipData(chips)}
            currentForms={currentForms}
          />
          <FramoButtonWithLoading
            buttonStyle='styleOutlined'
            buttonSize='sizeMini'
            handleClick={() => confirmChanges()}
            submissionState={state.state}
          >
            Register tests and close
          </FramoButtonWithLoading>
        </div>
      )}
    </div>
  );
};

export default VesselModal;
