import React, { useState } from 'react';
import { Typography, makeStyles, Divider } from '@material-ui/core';
import { FormFieldPreValues } from '../api';
import { FramoAction } from '../components/buttons';
import { useModalContext } from '../contexts';
import DebouncedFlsInputField from '../components/inputs/DebouncedFlsInputField';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '600px',
    maxHeight: '700px',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      backgroundColor: theme.palette.secondary.main,
      width: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.main,
      margin: '1px',
      borderRadius: '6px',
    },
  },
  entry: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  errorCodes: {
    width: '100%',
    textAlign: 'center',
  },
  searchContainer: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

interface SelectCommentProps {
  prevalues: FormFieldPreValues[];
  selectValue: (value: string) => void;
}

interface FilterHighlightProps {
  value: string;
  searchTerm?: string;
}

const FilterHiglight = (props: FilterHighlightProps) => {
  const { value, searchTerm } = props;
  const parts = value.split(new RegExp(`(${searchTerm})`, 'gi'));
  if (searchTerm) {
    return (
      <Typography>
        <span>
          {parts.map((part, i) => (
            <span
              key={i}
              style={
                part.toLowerCase() === searchTerm.toLowerCase()
                  ? { backgroundColor: '#3399FF' }
                  : {}
              }
            >
              {part}
            </span>
          ))}
        </span>
      </Typography>
    );
  } else {
    return <Typography>{value}</Typography>;
  }
};

const SelectComment = (props: SelectCommentProps) => {
  const { prevalues, selectValue } = props;
  const [options, setOptions] = useState<FormFieldPreValues[]>();
  const [searchTerm, setSearchTerm] = useState<string>();
  const classes = useStyles();
  const { setIsOpen } = useModalContext();

  React.useEffect(() => {
    if (prevalues) {
      setOptions(prevalues);
    }
  }, [prevalues]);

  React.useEffect(() => {
    if (searchTerm) {
      setOptions(
        prevalues.filter(
          (x) =>
            x.value.toLowerCase().includes(searchTerm.toLowerCase()) ||
            x.label?.toLowerCase().includes(searchTerm.toLowerCase()),
        ),
      );
    } else {
      setOptions(prevalues);
    }
  }, [searchTerm]);

  const onSelect = (value: string) => {
    selectValue(value);
    setIsOpen(false);
  };

  return (
    <div className={classes.root}>
      <div className={classes.searchContainer}>
        <Typography variant='body2'>Filter</Typography>
        <DebouncedFlsInputField
          value={searchTerm}
          placeholder='Term to filter by...'
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      {options && options.length > 0 ? (
        options
          .sort((item1, item2) =>
            item1.sortOrder && item2.sortOrder ? (item1.sortOrder > item2.sortOrder ? 1 : -1) : -1,
          )
          .map((entry) => {
            return (
              <>
                <Divider variant='middle' />
                <div key={entry.id} className={classes.entry}>
                  <FramoAction
                    iconIdentifier='add'
                    colorVariant='secondary'
                    handleClick={() => onSelect(entry.value)}
                  >
                    {entry.label}
                  </FramoAction>
                  <FilterHiglight value={entry.value} searchTerm={searchTerm} />
                </div>
              </>
            );
          })
      ) : searchTerm ? (
        <div className={classes.entry}>
          <Typography className={classes.errorCodes}>No matches...</Typography>
        </div>
      ) : (
        <div className={classes.entry}>
          <Typography className={classes.errorCodes}>
            Error: Could not retrieve predefined comments
          </Typography>
        </div>
      )}
    </div>
  );
};

export default SelectComment;
