import React, { PropsWithChildren } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { IconIdentifier } from '../../models/components';
import { IconHandler } from '../util';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginLeft: theme.spacing(0.2),
  },
  iconText: {
    paddingTop: theme.spacing(2),
  },
  inputCardsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
  },
  panelButton: {
    background: 'none',
    border: 'none',
    color: (props: PanelButtonProps) =>
      props.disabled ? theme.palette.button.disabled : theme.palette.primary.main,
    cursor: 'pointer',
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
    maxWidth: '350px',
    height: '160px',
  },
}));

interface PanelButtonProps extends IconIdentifier {
  type?: 'button' | 'reset' | 'submit';
  disabled?: boolean;
  handleClick?: () => void;
}

const PanelButton = (props: PropsWithChildren<PanelButtonProps>) => {
  const { iconIdentifier, children, disabled, handleClick, type } = props;
  const classes = useStyles(props);
  return (
    <button type={type} className={classes.panelButton} disabled={disabled} onClick={handleClick}>
      <IconHandler iconIdentifier={iconIdentifier} className={classes.icon} />
      <Typography variant='body2' className={classes.iconText}>
        {children}
      </Typography>
    </button>
  );
};

export default PanelButton;
