import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Severity } from '../../models';
import HelperIcon from './HelperIcon';

const useStyles = makeStyles((theme) => ({
  icon: {
    height: '20px',
    width: '20px',
  },
  errorContainer: {
    boxSizing: 'content-box',
    alignItems: 'center',
    border: 'none',
    display: 'flex',
    visibility: (props: HelperTextProps) => (props.helperText ? 'visible' : 'hidden'),
    minHeight: theme.spacing(3),
    padding: theme.spacing(1, 1, 0, 0),
  },
  helperText: {
    color: theme.palette.text.primary,
    ...theme.typography.body1,
  },
}));

interface HelperTextProps {
  severity: Severity;
  helperText?: string | false;
  id?: string;
}

const HelperText = (props: HelperTextProps) => {
  const { helperText, id, severity } = props;
  const classes = useStyles(props);

  return (
    <>
      <div className={classes.errorContainer}>
        <HelperIcon severity={severity} />
        <span id={`${id}-text`} className={classes.helperText}>
          {helperText ? helperText : ' '}
        </span>
      </div>
    </>
  );
};

export default HelperText;
