import { ReactElement, useEffect, useReducer } from 'react';
import { flsToast } from '../components/util/FlsToast';
import { SubmissionState } from '../models';

export type LoadingActions<T> =
  | { type: 'REQUEST' }
  | { type: 'RESET' }
  | { type: 'SUCCESS'; result?: T; toast?: string }
  | { type: 'ERROR'; error?: string; toast?: string | ReactElement };

export interface State<T> {
  state: SubmissionState;
  data?: T;
  error?: string;
  toast?: string | ReactElement;
}

const createLoadingReducer =
  <T>() =>
  (state: State<T>, action: LoadingActions<T>): State<T> => {
    switch (action.type) {
      case 'REQUEST':
        return {
          state: 'pending',
        };
      case 'SUCCESS':
        return {
          state: 'submitted',
          data: action.result,
          toast: action.toast,
        };
      case 'ERROR':
        return {
          state: 'error',
          error: action.error,
          toast: action.toast,
        };
      case 'RESET':
        return {
          state: '',
          data: state.data,
          error: state.error,
        };
      default:
        return state;
    }
  };

function useLoading<T>(resetTimeout = 1500) {
  const loadingReducer = createLoadingReducer<T>();
  const [state, dispatch] = useReducer(loadingReducer, { state: '', toast: '' });

  useEffect(() => {
    let timer;
    if ((state.state === 'error' || state.state === 'submitted') && resetTimeout) {
      timer = setTimeout(() => dispatch({ type: 'RESET' }), resetTimeout);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [state.state]);

  useEffect(() => {
    if (state.state === 'submitted' && state.toast) {
      flsToast.success(state.toast);
    } else if (state.state === 'error' && state.toast) {
      flsToast.error(state.toast);
    }
  }, [state.state]);

  return {
    state,
    dispatch,
  };
}
export default useLoading;
