import React, { useState } from 'react';
import { createSuperContext } from 'react-super-context';

export interface ModalProps {
  title?: string;
  titleIcon?: React.ReactNode;
  onClose?: () => void;
}

const defaultProps = {
  title: undefined,
  titleIcon: undefined,
  onClose: () => {},
};
/**
 * Context handler for allowing multiple modals per page with global reach.
 * @param isOpen defines if the modal should be open or closed.
 * @param content defines the content of the children and needs to be set before the modal is opened
 * @param modalProps defines the props to be assigned to the modal
 * Additionally, a cleanup process is run everytime the modal is closed.
 */
const [modalContext, useModalContext] = createSuperContext(() => {
  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState<React.ReactNode>();
  const [modalProps, setModalProps] = useState<ModalProps>(defaultProps);

  React.useEffect(() => {
    if (!isOpen) {
      setContent(undefined);
      setModalProps(defaultProps);
    }
  }, [isOpen]);

  return { isOpen, setIsOpen, content, setContent, modalProps, setModalProps };
});

export { modalContext, useModalContext };
