import {
  FormFieldValueStrategy,
  FormFieldClassifierType,
  Classifier,
  FormField,
  FormFieldValidationRule,
  FormFieldValueBuilder,
  FormGroup,
  Record,
  RecordValue,
  RecordValueDecimal,
  RecordValueInteger,
  RecordValueLongString,
  RecordValueString,
  RetrievedRecordValue,
  FormFieldSize,
} from '../api';
import { FieldErrorType, FieldSize } from '../models/components';

/**
 * Classifies a value based on the current classify selector.
 * @param field The field which to apply the classification to
 * @param value The value to be classified
 * @param classifyWith The name of the classification standard
 * @returns Returns the classified value, if no classifier match any standard related to that field the original value is returned
 */
export const classifyInputValue = (field: FormField, value: any, classifyWith: string) => {
  const formFieldClassifier = field.classifiers.find(
    (classifiers) => classifiers.classifier.name === classifyWith,
  );

  if (formFieldClassifier) {
    const classifiedValue = calculateClass(formFieldClassifier.classifier, value, field.id);
    if (classifiedValue) {
      return classifiedValue;
    }
  }

  if (field.classifiers.length > 0) {
    return;
  }

  return value;
};

/**
 * Find a FormField within a FormGroup
 * @param id The id of the field to find
 * @param formGroup The formgroup which to search through
 * @returns The found field or undefined
 */
export const findFormField = (id: string, formGroup: FormGroup) => {
  return formGroup.formFields.find((field) => field.id === id);
};

/**
 * Creates a record based on discriminator, fieldId and value
 * @param discrimintator
 * @param fieldId
 * @param value
 * @returns
 */
export const createRecord = (
  discriminator: string,
  fieldId: string,
  value: any,
  retrievedValue: boolean,
) => {
  switch (discriminator) {
    case 'RecordValueString':
      const recordValueString: RecordValueString = {
        Discriminator: discriminator,
        FormFieldId: fieldId,
        StringValue: value,
        RetrievedValue: retrievedValue,
      };
      return recordValueString;
    case 'RecordValueDecimal':
      const recordValueDecimal: RecordValueDecimal = {
        Discriminator: discriminator,
        FormFieldId: fieldId,
        DecimalValue: value ? Number(value) : value,
        RetrievedValue: retrievedValue,
      };
      return recordValueDecimal;
    case 'RecordValueInteger':
      const recordValueInteger: RecordValueInteger = {
        Discriminator: discriminator,
        FormFieldId: fieldId,
        IntValue: value ? Number.parseInt(value) : value,
        RetrievedValue: retrievedValue,
      };
      return recordValueInteger;
    case 'RecordValueLongString':
      const recordValueLongString: RecordValueLongString = {
        Discriminator: discriminator,
        FormFieldId: fieldId,
        LongStringValue: value,
        RetrievedValue: retrievedValue,
      };
      return recordValueLongString;
  }
};

/**
 * Extracts the value from a record
 * @param recordValue
 * @returns
 */
export const getValueFromRecord = (recordValue: RecordValue) => {
  switch (recordValue.Discriminator) {
    case 'RecordValueString':
      return (recordValue as any)?.StringValue;
    case 'RecordValueDecimal':
      return (recordValue as any)?.DecimalValue;
    case 'RecordValueLongString':
      return (recordValue as any)?.LongStringValue;
    case 'RecordValueInteger':
      return (recordValue as any)?.IntValue;
  }
};

/**
 * Extracts the value from a retrieved record
 * @param recordValue
 * @returns
 */
export const getValueFromRetrievedRecord = (recordValue: RetrievedRecordValue) => {
  switch (recordValue.discriminator) {
    case 'RecordValueString':
      return (recordValue as any)?.stringValue;
    case 'RecordValueDecimal':
      return (recordValue as any)?.decimalValue;
    case 'RecordValueLongString':
      return (recordValue as any)?.longStringValue;
    case 'RecordValueInteger':
      return (recordValue as any)?.intValue;
  }
};

/**
 * Classify the input value according to the rules associated with the classifier
 * @param classifier The classifier which to use
 * @param value The value to classify
 * @returns Return a empty string if classification was not possible, otherwise returns the replaceValue
 */
export const calculateClass = (
  classifier: Classifier | undefined,
  value: any,
  formFieldId: string,
) => {
  if (!classifier || value == null) {
    return '';
  }

  let classThresholds = classifier.classThresholds.filter(
    (thresholdValue) => thresholdValue.formFieldId === formFieldId,
  );

  if (classThresholds === null || classThresholds.length === 0) {
    classThresholds = classifier.classThresholds;
  }

  switch (classifier.comparisonType) {
    case FormFieldClassifierType.Interval:
      for (let index = 0; index < classThresholds.length; index++) {
        const thresholdValue = classThresholds[index];
        if (
          Number(thresholdValue.firstCompareValue) <= value &&
          value <= Number(thresholdValue.secondCompareValue)
        ) {
          return thresholdValue.replaceValue;
        }
      }
      return '';
    default:
      return '';
  }
};

/**
 * Validates a value based on the supplied rule
 * @param rule The rule to validate the field against
 * @param value The value to validate
 * @returns If the value dont pass validation a FieldErrorType is returned, otherwise returns null
 */
export const validateField = (rule: FormFieldValidationRule, value: any) => {
  const errorObject: FieldErrorType = {
    errorText: rule.severity === 'Error' ? rule.errorText : '',
    severity: rule.severity,
    showNotification: rule.severity !== 'Normal' && rule.showNotification,
  };

  switch (rule.type) {
    case 'Regex':
      const regex = new RegExp(rule.regex);
      if (!regex.test(value)) {
        return errorObject;
      }
      return null;
    case 'Max':
      if (rule.value != null && Number(value) > rule.value) {
        return errorObject;
      }
      return null;
    case 'Min':
      if (rule.value != null && Number(value) < rule.value) {
        return errorObject;
      }
      return null;
    default:
      return null;
  }
};

/**
 * Merges records according to their form id
 * @param records List of records where the entries are grouped according to the formgroup id
 * @returns List of records where the entries are grouped according to the form id
 */
export const mergeRecords = (records: Record[]) => {
  return Object.entries(records).reduce((acc, [key, obj]) => {
    let currentRecordValues = new Array<Record[]>();
    if (acc[obj.formId]) {
      currentRecordValues = acc[obj.formId]['recordValues'];
    }

    const newO = Object.assign([], {
      ...acc,
      [obj.formId]: {
        formId: obj.formId,
        recordValues: [...currentRecordValues, ...obj.recordValues],
        flsSampleId: obj.flsSampleId,
      },
    });
    return newO;
  }, []);
};

export const getNumberValueFromRecord = (recordValue: RecordValue, discriminator: string) => {
  if (!recordValue) {
    return '';
  }

  const decimalValueIsNumber = isValidNumber(recordValue?.DecimalValue);
  const intValueIsNumber = isValidNumber(recordValue?.IntValue);

  switch (discriminator) {
    case 'RecordValueDecimal':
      return decimalValueIsNumber
        ? recordValue?.DecimalValue
        : intValueIsNumber
        ? recordValue?.IntValue
        : '';
    case 'RecordValueInteger':
      return intValueIsNumber
        ? recordValue?.IntValue
        : decimalValueIsNumber
        ? recordValue?.DecimalValue
        : '';
    default:
      return '';
  }
};

export const isValidNumber = (value: any) => {
  if (value === 0) {
    return true;
  }

  return !!value;
};

export const getFormulaSplitValues = (formula: string, delimiter: string): string[] => {
  return formula.split(delimiter).map((x) => x.replace('{', '').replace('}', '').toLowerCase());
};

export const calculateFieldValue = (
  recordValues: any,
  formFieldValueBuilder: FormFieldValueBuilder,
): any => {
  if (formFieldValueBuilder.strategy === FormFieldValueStrategy.SubtractFirstByLast) {
    const splitFieldIds = getFormulaSplitValues(
      formFieldValueBuilder.formula,
      formFieldValueBuilder.delimiter,
    );
    const filledValuesIds = {};

    Object.keys(recordValues).forEach((key) => {
      if (
        recordValues[key] != null &&
        !Number.isNaN(recordValues[key]) &&
        recordValues[key] !== ''
      ) {
        filledValuesIds[key] = recordValues[key];
      }
    });

    const filledValuesObjectKeys = Object.keys(filledValuesIds);

    if (
      !filledValuesObjectKeys.some((x) => x === splitFieldIds[0]) ||
      !filledValuesObjectKeys.some((x) => x === splitFieldIds[1])
    ) {
      return null;
    }

    const valueFrom = recordValues[splitFieldIds[0]];
    const valueTo = recordValues[splitFieldIds[1]];

    return (valueFrom - valueTo).toFixed(2);
  }

  return null;
};

export const shouldHideGroup = (arr: string[], classifierId?: string): boolean => {
  if (arr.length === 0) {
    return true;
  }

  if (classifierId) {
    return !arr.includes(classifierId.toUpperCase());
  }

  return true;
};

export const mapToFieldSize = (size: FormFieldSize): FieldSize['fieldSize'] => {
  switch (size) {
    case FormFieldSize.Xs:
      return 'xs';
    case FormFieldSize.Sm:
      return 'sm';
    case FormFieldSize.Md:
      return 'md';
    case FormFieldSize.Lg:
      return 'lg';
    case FormFieldSize.FullWidth:
      return 'fullWidth';
  }
};
