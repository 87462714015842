import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode';

interface QrCodeProps {
  value: string;
  margin: number;
  scale: number;
}

const QrCode = (props: QrCodeProps) => {
  const { value, margin, scale } = props;
  const [src, setSrc] = useState('');

  useEffect(() => {
    QRCode.toDataURL(value, { margin: margin, scale: scale }).then(setSrc);
  }, [value, margin, scale]);

  return <img src={src} alt={value} />;
};

export default QrCode;
