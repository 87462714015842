import { Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { ExternalTests, useApiClient } from '../api';
import { FramoButton, FramoButtonWithLoading } from '../components/buttons';
import { FlsChip, CheckboxCard, FlsTextField } from '../components/inputs';
import { ExternalLabLabel } from '../components/printing';
import { useModalContext, useSampleContext } from '../contexts';
import { useLoading } from '../hooks';
import { ChipData } from '../models';
import { intersection, isSuperset, determineExternalTestGroup } from '../utils';

const shortcuts = {
  standard: {
    label: 'Standard',
    description: 'Standard test package',
    isChecked: (checkedTests, tests) => {
      return isSuperset(
        checkedTests,
        tests.standard.map((t) => t.value),
      );
    },
    onChecked: (checkedTests, tests) => {
      return new Set([...checkedTests, ...tests.standard.map((t) => t.value)]);
    },
    onCheckedOff: (checkedTests, tests) => {
      return new Set(
        [...checkedTests].filter((test) => !tests.standard.some((t) => t.value === test)),
      );
    },
  },
  chlorides: {
    label: 'Chlorides',
    description: 'Chloride test only',
    isChecked: (checkedTests, tests) => {
      return isSuperset(
        checkedTests,
        tests.chlorides.map((t) => t.value),
      );
    },
    onChecked: (checkedTests, tests) => {
      return new Set([...checkedTests, ...tests.chlorides.map((t) => t.value)]);
    },
    onCheckedOff: (checkedTests, tests) => {
      return new Set(
        [...checkedTests].filter((test) => !tests.chlorides.some((t) => t.value === test)),
      );
    },
  },
  partialTests: {
    label: 'Partial tests',
    description: 'Choose tests to be included',
    isChecked: (checkedTests, tests) => {
      const testGroups = Object.keys(tests)
        .filter((x) => x !== 'all')
        .map((key) => tests[key].map((t) => t.value));

      const checkedTestsCopy = new Set([...checkedTests]);

      testGroups.forEach((group) => {
        const contains = isSuperset(checkedTests, group);
        if (contains) {
          for (const elem of group) {
            checkedTestsCopy.delete(elem);
          }
        }
      });
      return checkedTestsCopy.size > 0;
    },
    onChecked: (checkedTests, tests) => {
      return new Set([...checkedTests]); //No changes here
    },
    onCheckedOff: (checkedTests, tests) => {
      return intersection(
        new Set([
          ...Object.keys(tests)
            .filter((x) => x !== 'all')
            .map((key) => tests[key].map((t) => t.value))
            .flat(),
        ]),
        checkedTests,
      );
    },
  },
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '528px',
  },
  checkboxWrapper: {
    marginBottom: theme.spacing(4),
  },
  intro: {
    marginBottom: theme.spacing(2),
  },
  chips: {
    marginBottom: theme.spacing(4),
  },
  buttons: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
  firstButton: {
    marginRight: theme.spacing(2),
  },
  inputContainer: {
    marginBottom: theme.spacing(4),
  },
}));

export default function ExternalLab() {
  const { sample, fetchSample, state } = useSampleContext();
  const [tests, setTests] = useState<ExternalTests>({
    all: [],
    standard: [],
    chlorides: [],
  } as ExternalTests);

  const { setIsOpen } = useModalContext();
  const [checkedTests, setCheckedTests] = useState<Set<string>>(new Set());
  const [checkedShortCuts, setCheckedShortCuts] = useState<Set<string>>(new Set());
  const { getExternalTests, registerExternalTests } = useApiClient();
  const { state: registerTestsState, dispatch } = useLoading<null>();
  const [comment, setComment] = useState('');
  const printRef = useRef<HTMLBaseElement>(null);

  const print = useReactToPrint({
    content: () => printRef.current,
  });

  const classes = useStyles();

  const handleShortcutChecked = (shortcut: string) => {
    if (checkedShortCuts.has(shortcut)) {
      setCheckedShortCuts((prev) => new Set([...prev].filter((x) => x !== shortcut)));
      setCheckedTests((prev) => shortcuts[shortcut].onCheckedOff(prev, tests));
    } else {
      setCheckedShortCuts((prev) => new Set([...prev, shortcut]));
      setCheckedTests((prev) => shortcuts[shortcut].onChecked(prev, tests));
    }
  };

  const handleCheckedTest = (chipData: ChipData[]) => {
    const newChecked = new Set(chipData.filter((t) => t.clicked).map((t) => t.id));
    setCheckedTests(newChecked);
    setCheckedShortCuts(
      new Set(
        Object.keys(shortcuts).filter((shortcut) =>
          shortcuts[shortcut].isChecked(newChecked, tests),
        ),
      ),
    );
  };

  //Fetch all external tests
  useEffect(() => {
    getExternalTests()
      .then((res) => {
        setTests(res);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  //Prefill if we are editing
  useEffect(() => {
    if (sample?.externalLabSample) {
      const newTests = new Set([...sample.externalLabSample.externalLabTests]);
      setCheckedTests(newTests);
      setCheckedShortCuts(
        new Set(
          Object.keys(shortcuts).filter((shortcut) =>
            shortcuts[shortcut].isChecked(newTests, tests),
          ),
        ),
      );
      setComment(sample.externalLabSample.comment || '');
    }
  }, [sample, tests]);

  //Handle sending external tests to the API
  const register = async () => {
    if (!sample) {
      return;
    }
    dispatch({ type: 'REQUEST' });
    try {
      await registerExternalTests(sample.id, {
        sampleId: sample.id,
        analysisTypes: [...checkedTests],
        comment: comment,
      });
      await fetchSample();
      dispatch({ type: 'SUCCESS' });
      setIsOpen(false);
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'ERROR',
        error: error,
        toast: 'Unable to register external tests. Check console for more info',
      });
    }
  };

  return (
    <div className={classes.root}>
      <Typography variant='body1' className={classes.intro}>
        Select tests to be excecuted at external lab
      </Typography>
      <div className={classes.checkboxWrapper}>
        {Object.keys(shortcuts).map((shortcutKey, index) => {
          const shortcut = shortcuts[shortcutKey];
          return (
            <CheckboxCard
              checked={checkedShortCuts.has(shortcutKey)}
              label={shortcut.label}
              description={shortcut.description}
              onChange={() => {
                handleShortcutChecked(shortcutKey);
              }}
              key={shortcutKey}
            />
          );
        })}
      </div>
      <div className={classes.chips}>
        <FlsChip
          loading={state === 'pending'}
          chipData={
            tests?.all?.map((test) => {
              return {
                clicked: checkedTests.has(test.value),
                id: test.value,
                label: test.label,
              } as ChipData;
            }) || []
          }
          setChipData={handleCheckedTest}
          size='small'
          color='secondary'
        />
      </div>
      <div className={classes.inputContainer}>
        <FlsTextField
          placeholder='Additional Information'
          multiline={true}
          fullWidth={true}
          rows={3}
          value={comment}
          onChange={(e) => {
            setComment(e.target.value);
          }}
          id={'comment'}
        />
      </div>
      <div className={classes.buttons}>
        <div className={classes.firstButton}>
          <FramoButton buttonStyle='styleOutlined' buttonSize='sizeMini' handleClick={print}>
            Print label
          </FramoButton>
          <div style={{ display: 'none' }}>
            <ExternalLabLabel
              ref={printRef}
              title={sample?.shortId || 'Sample ID'}
              qrContent={sample?.shortId || 'Sample ID'}
              comment={comment}
              testProfile={determineExternalTestGroup(
                new Set(sample?.externalLabSample?.externalLabTests || []),
                tests,
              )}
            />
          </div>
        </div>
        <div>
          <FramoButtonWithLoading
            buttonStyle='styleFilled'
            buttonSize='sizeMini'
            handleClick={register}
            submissionState={registerTestsState.state}
          >
            Register
          </FramoButtonWithLoading>
        </div>
      </div>
    </div>
  );
}
