import { useState } from 'react';
import { createSuperContext } from 'react-super-context';
import { useApiClient, VesselSampleInfo, VesselSummary } from '../api';

const [vesselContext, useVesselContext] = createSuperContext(() => {
  const [vessel, setVessel] = useState<VesselSampleInfo>({} as VesselSampleInfo);
  const client = useApiClient();

  const handleSetVessel = async (vesselSummary: VesselSummary) => {
    if (vesselSummary?.orderNo) {
      const vessel = await client.getVessel(vesselSummary.orderNo);
      setVessel(vessel);
    }
  };

  const removeSelectedVessel = () => {
    setVessel({} as VesselSampleInfo);
  };

  return {
    vessel,
    setVessel: handleSetVessel,
    removeSelectedVessel,
  };
});

export { vesselContext, useVesselContext };
