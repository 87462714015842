import React, { useState, useEffect } from 'react';
import { Tab, TabProps } from '@material-ui/core';
import { useGetSampleCountByTab, useLab } from '../../hooks';
import { DashboardTabId } from '../../constants/labels';

interface TabWithCounterProps extends TabProps {
  tabLabel: string;
  tabId: DashboardTabId;
}

const TabWithCounter = (props: TabWithCounterProps) => {
  const { tabLabel, tabId, ...rest } = props;
  const [labelWithCount, setLabelWithCount] = useState<string>(tabLabel);
  const { labId } = useLab();
  const { sampleCountResponse, sampleCountState } = useGetSampleCountByTab(labId, tabId);

  useEffect(() => {
    if (sampleCountResponse) {
      setLabelWithCount(`${tabLabel} (${sampleCountResponse.totalCount})`);
    } else if (sampleCountState) {
      setLabelWithCount(`${tabLabel} (?)`);
    } else {
      setLabelWithCount(`${tabLabel} (0)`);
    }
  }, [sampleCountResponse]);

  return <Tab {...rest} label={labelWithCount} />;
};

export default TabWithCounter;
