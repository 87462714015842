import React, { useEffect, useState } from 'react';
import AdminMenu from '../../../components/menus/AdminMenu';
import { Classifier, FormFieldClassifierType, useApiClient } from '../../../api';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import { Formik, setNestedObjectValues, useFormikContext } from 'formik';
import { DebouncedTextField } from '../../../components/inputs';
import * as Yup from 'yup';
import { FramoButtonWithLoading } from '../../../components/buttons';
import { isEmpty } from 'ramda';
import { useLoading } from '../../../hooks';
import { LoadingActions, State } from '../../../hooks/useLoading';
import { FlsCard } from '../../../components/card';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  actionBar: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(2),
  },
  childOverride: {
    height: '100%',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    paddingTop: theme.spacing(1),
  },
  dividerStyle: {
    margin: theme.spacing(0, 2),
  },
  fieldContainers: {
    padding: theme.spacing(1, 2),
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    width: '100%',

    '& > *': {
      flex: '1 0 21%',
      marginRight: theme.spacing(4),
    },
  },
}));

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required('Required').label('Name'),
  comparisonType: Yup.string()
    .oneOf(Object.values(FormFieldClassifierType))
    .required('Required')
    .label('Comparison Type'),
  fieldsComputed: Yup.boolean().required('Required').label('Fields Computed'),
  sortOrder: Yup.number().required('Required').label('Sort Order'),
});

const AdminClassifiersAddPage = () => {
  const client = useApiClient();
  const { state: stateOnSave, dispatch: dispatchOnSave } = useLoading();
  const classes = useStyles();

  return (
    <>
      <AdminMenu />
      <div className={classes.root}>
        <Formik
          initialValues={{
            id: '',
            name: '',
            comparisonType: FormFieldClassifierType.Interval,
            fieldsComputed: false,
            classThresholds: [],
            sortOrder: 0,
          }}
          validationSchema={ValidationSchema}
          onSubmit={async (values: Classifier) => {
            if (values.id) {
              try {
                dispatchOnSave({ type: 'REQUEST' });
                await client.updateClassifier(values);
                dispatchOnSave({ type: 'SUCCESS', toast: 'Values updated' });
              } catch (error) {
                dispatchOnSave({
                  type: 'ERROR',
                  error: error?.message || 'Something went wrong',
                  toast: 'Failed to insert values to database.',
                });
              }
            } else {
              return;
            }
          }}
        >
          <InnerAddClassifier stateOnSave={stateOnSave} dispatchOnSave={dispatchOnSave} />
        </Formik>
      </div>
    </>
  );
};

interface InnerAddClassifierProps {
  stateOnSave: State<unknown>;
  dispatchOnSave: React.Dispatch<LoadingActions<unknown>>;
}

const InnerAddClassifier = (props: InnerAddClassifierProps) => {
  const formik = useFormikContext<Classifier>();
  const [classifier, setClassifiers] = useState<Classifier>();
  const { urlClassifierId } = useParams<{ urlClassifierId: string }>();
  const client = useApiClient();
  const classes = useStyles();

  useEffect(() => {
    const fetchClassifier = async () => {
      if (urlClassifierId) {
        const classifier = await client.getClassifierById(urlClassifierId);
        setClassifiers(classifier);
      }
    };

    fetchClassifier();
  }, [urlClassifierId]);

  const handleSubmit = async (ev: any) => {
    props.dispatchOnSave({ type: 'REQUEST' });
    const errors = await formik.validateForm();
    if (isEmpty(errors)) {
      formik.handleSubmit(ev);
    } else {
      console.log(errors);
      formik.setErrors(errors);
      formik.setTouched(setNestedObjectValues(errors, true));
      props.dispatchOnSave({
        type: 'ERROR',
        error: 'Validation Error',
        toast: 'Some fields have failed validation',
      });
    }
  };

  React.useEffect(() => {
    if (classifier) {
      Object.entries(classifier).forEach(([key, value]) => {
        if (formik.values.hasOwnProperty(key)) {
          formik.setFieldValue(key, value);
        }
      });
    }
  }, [classifier]);

  return (
    <div>
      <FlsCard overrideChildStyle={classes.childOverride} showDivider header='Classifier'>
        <form onSubmit={handleSubmit}>
          <div id='firstbox' className={classes.fieldContainers}>
            <div className={classes.inputContainer}>
              <DebouncedTextField
                fullWidth
                displayHelper
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                id={'name'}
                placeholder='Name'
                helperText={formik.touched.name && formik.errors.name}
              />
            </div>
            <div className={classes.inputContainer}>
              <DebouncedTextField
                fullWidth
                displayHelper
                value={formik.values.fieldsComputed.toString()}
                onChange={(areFieldsComputed) => {
                  formik.setFieldValue(
                    'fieldsComputed',
                    areFieldsComputed.target.value.toLowerCase() === 'true',
                  );
                }}
                onBlur={formik.handleBlur}
                id={'fieldsComputed'}
                placeholder='Fields Computed'
                helperText={formik.touched.fieldsComputed && formik.errors.fieldsComputed}
              />
            </div>
            <div className={classes.inputContainer}>
              <DebouncedTextField
                fullWidth
                displayHelper
                value={formik.values.comparisonType}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                id={'comparisonType'}
                placeholder='Comparison Type'
                helperText={formik.touched.comparisonType && formik.errors.comparisonType}
              />
            </div>
            <div className={classes.inputContainer}>
              <DebouncedTextField
                fullWidth
                displayHelper
                value={formik.values.sortOrder}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                id={'sortOrder'}
                placeholder='Sort Order'
                helperText={formik.touched.sortOrder && formik.errors.sortOrder}
              />
            </div>
          </div>
        </form>
      </FlsCard>
      <div className={classes.actionBar}>
        <FramoButtonWithLoading
          buttonSize='sizeNormal'
          buttonStyle='styleFilled'
          type='button'
          handleClick={handleSubmit}
          submissionState={props.stateOnSave?.state}
        >
          {formik.values.id ? 'Save' : 'Register'}
        </FramoButtonWithLoading>
      </div>
    </div>
  );
};

export default AdminClassifiersAddPage;
