import React from 'react';
import { Typography, Modal, makeStyles, Divider } from '@material-ui/core';
import { CloseIcon } from '../../assets/icons';
import { useModalContext } from '../../contexts';

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  headerContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  iconContainer: {
    display: 'flex',
    paddingRight: theme.spacing(1),
  },
  modal: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '6px',
    boxShadow: '4px 4px 12px rgba(0, 0, 0, 0.32)',
    minWidth: 400,
    minHeight: 250,
    maxWidth: '100vw',
    maxHeight: '100vh',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      backgroundColor: theme.palette.secondary.main,
      width: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.main,
      margin: '1px',
      borderRadius: '6px',
    },
    padding: theme.spacing(3),
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  divider: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    height: '2px',
  },
}));

/**
 * Styled modal which presents the child component in an elevated card.
 * To open and close, use the ModalContext function setIsOpen.
 */
const FlsModal = () => {
  const classes = useStyles();
  const { isOpen, setIsOpen, content, modalProps } = useModalContext();
  const { title, titleIcon } = modalProps;

  const onClose = modalProps.onClose;
  return (
    <Modal
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
        if (onClose) {
          onClose();
        }
      }}
      aria-labelledby='modal-title'
      aria-describedby='modal-description'
      className={classes.modal}
    >
      <div className={classes.paper}>
        <div className={classes.headerContainer}>
          <div className={classes.titleContainer}>
            {titleIcon && <div className={classes.iconContainer}>{titleIcon}</div>}
            {title && <Typography variant='body2'>{title}</Typography>}
          </div>
          <button
            className={classes.button}
            onClick={() => {
              setIsOpen(false);
              if (onClose) {
                onClose();
              }
            }}
          >
            <CloseIcon />
          </button>
        </div>
        <Divider variant='fullWidth' className={classes.divider} />
        {content ? content : <Typography variant='h1'> Error: Content not found.</Typography>}
      </div>
    </Modal>
  );
};

export default FlsModal;
