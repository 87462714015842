import { Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { FramoButton } from '../components/buttons';
import { useLoading } from '../hooks';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '600px',
    alignItems: 'center',
    '& > *': {
      padding: theme.spacing(2),
    },
    '& > :last-child': {
      padding: 0,
    },
  },
  buttonContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-around',
  },
}));

interface DeletionProps {
  header: string;
  yesAction: () => void;
  quitModal: () => void;
}

const YesNoModal = (props: DeletionProps) => {
  const { header, yesAction, quitModal } = props;
  const classes = useStyles();
  const { state: finalizeState } = useLoading();

  return (
    <div className={classes.root}>
      <Typography>{header}</Typography>
      <div className={classes.buttonContainer}>
        <FramoButton
          disabled={finalizeState.state === 'pending'}
          submissionState={finalizeState.state}
          handleClick={quitModal}
        >
          Cancel
        </FramoButton>
        <FramoButton
          disabled={finalizeState.state === 'pending'}
          submissionState={finalizeState.state}
          handleClick={yesAction}
        >
          Delete
        </FramoButton>
      </div>
    </div>
  );
};

export default YesNoModal;
