import React, { useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import LabelSelector, { LabelOptions } from '../components/printing/LabelSelector';
import { FramoButton } from '../components/buttons';
import { CircularProgress, makeStyles, RadioGroup, Typography } from '@material-ui/core';
import { FlsRadio } from '../components/inputs';
import { useModalContext, useSampleContext } from '../contexts';
import { ExternalTests, useApiClient } from '../api';
import { useLoading } from '../hooks';
import { determineExternalTestGroup } from '../utils';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minWidth: '610px',
    minHeight: '655px',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    alignItems: 'center',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  radioContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  labelSizeContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
  },
}));

const PrintPreview = () => {
  const classes = useStyles();
  const { setIsOpen } = useModalContext();
  const { sample, sampleId, sampleLoading } = useSampleContext();
  const { dispatch: externalTestDispatch, state: externalTestState } = useLoading<ExternalTests>();
  const { getExternalTests } = useApiClient();
  const options: LabelOptions[] = ['External Lab Label', 'Big Label', 'Small Label'];
  const componentRef = useRef<HTMLBaseElement>(null);
  const [selected, setSelected] = useState<LabelOptions>('External Lab Label');

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const printActivation = () => {
    if (handlePrint) {
      handlePrint();
    }
    setIsOpen(false);
  };

  useEffect(() => {
    async function fetchExternalTests() {
      try {
        externalTestDispatch({ type: 'REQUEST' });
        const tests = await getExternalTests();
        externalTestDispatch({ type: 'SUCCESS', result: tests });
      } catch (error) {
        externalTestDispatch({ type: 'ERROR', error: 'Unable to fetch external tests' });
      }
    }
    fetchExternalTests();
  }, [sampleId]);

  const getOrientation = (selected: LabelOptions) => {
    switch (selected) {
      case 'Small Label': {
        return 'Portrait';
      }
      case 'Big Label': {
        return 'Landscape';
      }
      case 'External Lab Label': {
        return 'Portrait';
      }
      default: {
        return 'Unknown';
      }
    }
  };
  return (
    <>
      {sampleLoading ? (
        <CircularProgress size={24} />
      ) : sample ? (
        <div className={classes.root}>
          <div className={classes.labelSizeContainer}>
            <Typography>Select label size:</Typography>
            <RadioGroup
              row
              value={selected}
              onChange={(_e, value: LabelOptions) => setSelected(value)}
            >
              {options.map((option) => {
                return <FlsRadio key={option} label={option} value={option} />;
              })}
            </RadioGroup>
          </div>
          <div>
            <LabelSelector
              selector={selected}
              labelContent={{
                title: sample.shortId,
                qrContent: sample.shortId,
                comment: sample.externalLabSample?.comment,
                testProfile: sample.externalLabSample?.externalLabTests
                  ? determineExternalTestGroup(
                      new Set(sample.externalLabSample?.externalLabTests),
                      externalTestState.data,
                    )
                  : '-',
              }}
              ref={componentRef}
            />
          </div>
          <div className={classes.buttonContainer}>
            {selected && (
              <Typography variant='h6'>Orientation: {getOrientation(selected)}</Typography>
            )}
            <FramoButton
              handleClick={printActivation}
              buttonSize='sizeMini'
              buttonStyle='styleFilled'
            >
              Print label
            </FramoButton>
          </div>
        </div>
      ) : (
        <div>
          <Typography>No sample found..</Typography>
        </div>
      )}
    </>
  );
};

export default PrintPreview;
