import usePromise from 'react-use-promise';
import { useApiClient } from '../api';
import { DashboardTabId } from '../constants/labels';

const useGetSampleCountByPanel = (labId: string, currentPanel: DashboardTabId) => {
  const client = useApiClient();
  const [sampleCountResponse, sampleCountError, state] = usePromise(
    async () => await client.listSampleByLab(labId, 1, 1, currentPanel),
    [labId, currentPanel],
  );
  return {
    sampleCountResponse,
    sampleCountError,
    sampleCountState: state === 'pending',
  };
};

export default useGetSampleCountByPanel;
