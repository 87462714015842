import React, { PropsWithChildren } from 'react';
import { FramoButton } from '.';
import { SubmissionBlock } from '../util';
import { FramoButtonProps } from './FramoButton';

export default function FramoButtonWithLoading({
  children,
  disabled,
  ...props
}: PropsWithChildren<FramoButtonProps>) {
  return (
    <FramoButton disabled={props.submissionState === 'pending' || disabled} {...props}>
      <SubmissionBlock
        submissionState={props.submissionState || ''}
        buttonStyle={props.buttonStyle || 'styleOutlined'}
      >
        {children}
      </SubmissionBlock>
    </FramoButton>
  );
}
