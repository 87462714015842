import React, { useCallback, useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { useSampleContext } from '../../contexts';

const SampleRevisionLabel = () => {
  const { sample } = useSampleContext();
  const [revisionNumber, setRevisionNumber] = useState<number>(0);

  const calculateRevisionNumber = useCallback(() => {
    const rev = sample?.revisions.findIndex((x) => x.sampleId === sample?.id);
    if (rev && rev > 0) {
      setRevisionNumber(rev);
    } else {
      setRevisionNumber(0);
    }
  }, [sample]);

  useEffect(() => {
    calculateRevisionNumber();
  }, [sample]);

  return (
    <>
      {revisionNumber > 0 && (
        <Typography style={{ display: 'inline', fontSize: '0.5em' }}>
          revision no. {revisionNumber}
        </Typography>
      )}
    </>
  );
};

export default SampleRevisionLabel;
