import React from 'react';
import {
  ArrowIcon,
  AddIcon,
  CheckIcon,
  ChevronLarge,
  CloseIcon,
  ImportantIcon,
  PrintIcon,
  SearchIcon,
  SendIcon,
  UploadIcon,
  SettingsIcon,
  DashboardIcon,
  DocumentIcon,
  ExternalLinkIcon,
  DropletIcon,
  PdiIcon,
  OmpIcon,
  DeleteIcon,
  LoaderIcon,
  UploadIconSmall,
  BackIcon,
  ForwardIcon,
  LogoutIcon,
} from '../../assets/icons';
import { IconIdentifier } from '../../models/components';

interface IconHandlerProps extends IconIdentifier {
  className?: string;
}

const IconHandler = (props: IconHandlerProps) => {
  switch (props.iconIdentifier) {
    case 'add':
      return <AddIcon className={props.className} />;
    case 'arrow':
      return <ArrowIcon className={props.className} />;
    case 'back':
      return <BackIcon className={props.className} />;
    case 'forward':
      return <ForwardIcon className={props.className} />;
    case 'logout':
      return <LogoutIcon className={props.className} />;
    case 'chevron':
      return <ChevronLarge className={props.className} />;
    case 'search':
      return <SearchIcon className={props.className} />;
    case 'close':
      return <CloseIcon className={props.className} />;
    case 'upload':
      return <UploadIcon className={props.className} />;
    case 'print':
      return <PrintIcon className={props.className} />;
    case 'send':
      return <SendIcon className={props.className} />;
    case 'dashboard':
      return <DashboardIcon className={props.className} />;
    case 'settings':
      return <SettingsIcon className={props.className} />;
    case 'check':
      return <CheckIcon className={props.className} />;
    case 'important':
      return <ImportantIcon className={props.className} />;
    case 'document':
      return <DocumentIcon className={props.className} />;
    case 'externalLink':
      return <ExternalLinkIcon className={props.className} />;
    case 'droplet':
      return <DropletIcon className={props.className} />;
    case 'omp':
      return <OmpIcon className={props.className} />;
    case 'pdi':
      return <PdiIcon className={props.className} />;
    case 'delete':
      return <DeleteIcon className={props.className} />;
    case 'loader':
      return <LoaderIcon className={props.className} />;
    case 'upload-small':
      return <UploadIconSmall className={props.className} />;
    default:
      return null;
  }
};

export default IconHandler;
