import { serialize } from 'object-to-formdata';

export const constructFormData = (content) => {
  const { files, ...contentWithoutFiles } = content;

  const formdata = serialize(contentWithoutFiles, {
    indices: true,

    allowEmptyArrays: true,
  });
  if (files) {
    [...files].forEach((file, index) => {
      formdata.append(`files`, file, file.name);
    });
  }
  console.log(files);
  for (const value of formdata.entries()) {
    console.log(value[0], value[1]);
  }
  return formdata;
};
