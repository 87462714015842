import React, { useEffect, useState } from 'react';
import FlsTable from '../tables/FlsTable';
import { CircularProgress, makeStyles, TableCell, TableRow, Typography } from '@material-ui/core';
import { format, compareDesc, isBefore, compareAsc, addYears } from 'date-fns';
import { CalendarResponse, useApiClient } from '../../api';
import dateFormat from '../../utils/dateFormat';
import InspectionTypeTag, { InspectionType } from '../tags/InspectionTypeTag';
import { StatusCell } from '../datagrid';
import { useLoading } from '../../hooks';
import { FramoLink } from '../buttons';

const useStyles = makeStyles((theme) => ({
  previousInspectionTag: {
    borderBottomWidth: '6px',
    borderTopRightRadius: '0px',
    borderBottomRightRadius: '0px',
  },
  tableContainer: {
    maxHeight: '560px',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      backgroundColor: theme.palette.secondary.main,
      width: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.main,
      margin: '1px',
      borderRadius: '6px',
    },
  },
  calendarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: { flexDirection: 'column' },
  },
  tableHeader: {
    '& > *': {
      textAlign: 'center',
      '&:first-child': {
        textAlign: 'left',
      },
    },
  },
}));

interface CalendarProps {
  installationId?: string;
}

const Calendar = (props: CalendarProps) => {
  const classes = useStyles();
  const [calendar, setCalendar] = useState<CalendarResponse>();
  const client = useApiClient();

  const { dispatch: calendarDispatch, state: calendarState } = useLoading();

  useEffect(() => {
    async function fetchCalendar() {
      const from = addYears(new Date(), -2);
      const to = addYears(new Date(), 1);
      try {
        calendarDispatch({ type: 'REQUEST' });
        const calendar = await client.getCalendar(
          props.installationId || '',
          dateFormat.formatUrlDate(from),
          dateFormat.formatUrlDate(to),
        );

        const distinctEntries = new Set<number>([]);
        const removeDuplicates = calendar.entries.filter((ent) => {
          if (!ent.docRno) {
            return true;
          }
          const exists = distinctEntries.has(ent.docRno);
          distinctEntries.add(ent.docRno);
          return !exists;
        });

        calendar.entries = removeDuplicates;

        setCalendar(calendar);
        calendarDispatch({ type: 'SUCCESS' });
      } catch (error) {
        console.error(error);
        calendarDispatch({
          type: 'ERROR',
          error: 'Unable to fetch calendar',
          toast: 'Error while fetching calendar',
        });
      }
    }
    if (props.installationId) {
      fetchCalendar();
    }
  }, []);
  return (
    <div className={classes.calendarContainer}>
      <FlsTable
        headers={['When', 'Type']}
        title='Planned tests'
        styles={{ root: classes.tableContainer }}
      >
        {calendarState.state === 'pending' ? (
          <TableRow>
            <TableCell colSpan={2} align='center'>
              <CircularProgress size={24} />
            </TableCell>
          </TableRow>
        ) : calendar && calendar.entries ? (
          calendar.entries
            .sort((d1, d2) => compareAsc(new Date(d1.date), new Date(d2.date)))
            .filter((entry) => {
              return (
                isBefore(new Date(), new Date(entry.date)) ||
                entry.status === 'Planned' ||
                entry.status === 'Moved'
              );
            })
            .map((entry, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>
                    {entry.date ? format(new Date(entry.date), 'yyyy - MMMM') : '-'}
                  </TableCell>
                  <TableCell>
                    <InspectionTypeTag type={entry.sampleType as InspectionType} color='primary' />
                  </TableCell>
                </TableRow>
              );
            })
        ) : (
          <TableRow>
            <TableCell colSpan={2} align='center'>
              <Typography>No Entries...</Typography>
            </TableCell>
          </TableRow>
        )}
      </FlsTable>
      <FlsTable
        headers={['Date', 'Doc RNo', 'Type', 'Status']}
        title='Previous reports'
        styles={{ root: classes.tableContainer, tableHeader: classes.tableHeader }}
      >
        {calendarState.state === 'pending' ? (
          <TableRow>
            <TableCell colSpan={4} align='center'>
              <CircularProgress size={24} />
            </TableCell>
          </TableRow>
        ) : calendar && calendar.entries ? (
          calendar.entries
            .sort((d1, d2) => compareDesc(new Date(d1.date), new Date(d2.date)))
            .filter((entry) => {
              return (
                isBefore(new Date(entry.date), new Date()) &&
                entry.status !== 'Planned' &&
                entry.status !== 'Moved'
              );
            })
            .map((entry, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>
                    {entry.date ? format(new Date(entry.date), 'dd.MM.yyyy') : '-'}
                  </TableCell>
                  <TableCell>
                    {entry.docRno && (
                      <FramoLink
                        location={`http://webapp/omp/ProArc/Oilmon.aspx?DocRno=${entry.docRno}`}
                      >
                        {entry.docRno}
                      </FramoLink>
                    )}
                  </TableCell>
                  <TableCell>
                    <InspectionTypeTag
                      type={entry.sampleType as InspectionType}
                      color='secondary'
                      className={classes.previousInspectionTag}
                    />
                  </TableCell>
                  <TableCell>
                    <StatusCell
                      label={entry.sampleStatus || entry.status}
                      status={entry.sampleStatus}
                      showIcon={false}
                    />
                  </TableCell>
                </TableRow>
              );
            })
        ) : (
          <TableRow>
            <TableCell colSpan={4} align='center'>
              <Typography>No Entries...</Typography>
            </TableCell>
          </TableRow>
        )}
      </FlsTable>
    </div>
  );
};
export default Calendar;
