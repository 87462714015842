import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { FramoButton } from '../../components/buttons';
import DebouncedFlsInputField from '../../components/inputs/DebouncedFlsInputField';
import {
  GridSelectedRowCount,
  GridToolbarColumnsButton,
  GridToolbarContainer,
} from '@material-ui/data-grid';
import { IconHandler } from '../util';
import { useModalContext } from '../../contexts';
import { MultiPrintPreview } from '../../modals';
import { useApiClient, GetSamplesRequest } from '../../api';

const useStyles = makeStyles((theme) => ({
  searchButton: {
    backgroundColor: 'inherit',
    border: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
    marginTop: 4,
  },
  actionContainer: {
    display: 'flex',
    marginRight: theme.spacing(1),
  },
  menuContainer: {
    height: theme.spacing(9),
    display: 'flex-start',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 3),
  },
  toolbarButtons: {
    '& > *': {
      '& > *': {
        backgroundColor: theme.palette.button.normalAltBgd,
        marginRight: theme.spacing(1),
        padding: theme.spacing(0, 2, 0, 2),
        marginTop: -3,
        height: theme.spacing(6),
        display: 'flex-start',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
        border: '1px solid',
        transition: '0.3s',
        borderColor: theme.palette.button.normalBgd,
        borderRadius: theme.spacing(0.5),
        color: theme.palette.button.textAlt,
        '&:focus': {
          boxShadow: theme.palette.boxShadows.focus,
          backgroundColor: theme.palette.button.normalAltBgd,
          color: theme.palette.button.highlight,
          borderColor: theme.palette.button.highlight,
        },
        '&:hover': {
          boxShadow: theme.palette.boxShadows.dark,
          backgroundColor: theme.palette.button.highlightAlt,
          color: theme.palette.button.highlight,
          borderColor: theme.palette.button.highlight,
        },
      },
    },
  },
  search: {
    alignItems: 'center',
    marginRight: theme.spacing(2),
  },
}));

interface MenuProps {
  onSearchChange?: (searchString: string) => void;
  searchQuery?: string;
  searchPlaceholder?: string;
  addNewLink: string;
  addDisabled?: boolean;
  selectedSampleIds?: string[];
  setColumnsButtonEl?: React.SetStateAction<null>;
}

const DatagridToolbar = (props: MenuProps) => {
  const classes = useStyles();
  const history = useHistory();
  const textInput = useRef<HTMLInputElement>(null);
  const { setIsOpen, setModalProps, setContent } = useModalContext();
  const client = useApiClient();

  const IsSearchEnabled = (): boolean => {
    return props.onSearchChange != null && props.searchQuery != null;
  };

  const handleModalPrint = async () => {
    if (!props.selectedSampleIds || props.selectedSampleIds.length === 0) {
      return;
    }

    try {
      const request: GetSamplesRequest = {
        sampleIds: props.selectedSampleIds,
      };

      const samples = await client.getSampleByIds(request);
      setModalProps({
        title: `Preview ${props.selectedSampleIds.length === 0 ? 'label' : 'labels'}`,
        titleIcon: undefined,
      });
      setContent(<MultiPrintPreview samples={samples} />);
      setIsOpen(true);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <GridToolbarContainer className={classes.menuContainer}>
      <div className={classes.actionContainer}>
        {props.selectedSampleIds && props.selectedSampleIds.length > 0 && (
          <GridSelectedRowCount
            selectedRowCount={props.selectedSampleIds?.length}
          ></GridSelectedRowCount>
        )}
        {props.selectedSampleIds && props.selectedSampleIds.length > 0 && (
          <FramoButton
            buttonSize='sizeNormal'
            buttonStyle='styleOutlined'
            type='button'
            handleClick={handleModalPrint}
          >
            Print {props.selectedSampleIds?.length === 1 ? 'Label' : 'Labels'}
          </FramoButton>
        )}
      </div>
      <div className={classes.actionContainer}>
        <div className={classes.toolbarButtons}>
          <GridToolbarColumnsButton ref={props.setColumnsButtonEl} />
        </div>
        {IsSearchEnabled() && (
          <DebouncedFlsInputField
            autoFocus
            value={props.searchQuery}
            inputRef={textInput}
            onChange={props.onSearchChange}
            placeholder={props.searchPlaceholder}
            className={classes.search}
            icon={
              <>
                {props.searchQuery ? (
                  <button
                    className={classes.searchButton}
                    onClick={() => {
                      if (props.onSearchChange) props.onSearchChange('');
                    }}
                    type='button'
                  >
                    <IconHandler iconIdentifier='close' />
                  </button>
                ) : (
                  <button
                    className={classes.searchButton}
                    onClick={() => textInput.current?.focus()}
                    type='button'
                  >
                    <IconHandler iconIdentifier='search' />
                  </button>
                )}
              </>
            }
          />
        )}
        {!props.addDisabled && (
          <FramoButton handleClick={() => history.push(props.addNewLink)}>Add new</FramoButton>
        )}
      </div>
    </GridToolbarContainer>
  );
};

export default DatagridToolbar;
