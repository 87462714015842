import { GridSelectionModel, GridSortDirection, GridSortModel } from '@material-ui/data-grid';
import React, { useEffect } from 'react';
import { Sample, useApiClient } from '../api';

export interface SampleResponse {
  data?: Sample[];
  error?: string;
  loading: boolean;
}
export interface SampleDataGrid {
  response: SampleResponse;
  selectionModel: GridSelectionModel;
  setSelectionModel: React.Dispatch<React.SetStateAction<GridSelectionModel>>;
  sortModel: GridSortModel;
  setSortModel: React.Dispatch<React.SetStateAction<GridSortModel>>;
  pageSize: number;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  pageNumber: number;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
  totalEntries: number;
  setTotalEntries: React.Dispatch<React.SetStateAction<number>>;
  clearRows: () => void;
  fetchRows: () => void;
}

export type Panel = 'InProgress' | 'Finalized';
const useListSample = (
  labId: string,
  currentPanel: Panel,
  searchQuery: string,
  trigger: boolean,
): SampleDataGrid => {
  const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>([]);
  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    { field: 'createdOn', sort: 'desc' as GridSortDirection },
  ]);
  const [pageSize, setPageSize] = React.useState<number>(50);
  const [pageNumber, setPageNumber] = React.useState<number>(1);
  const [totalEntries, setTotalEntries] = React.useState<number>(0);
  const [rows, setRows] = React.useState<Sample[]>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>();

  const client = useApiClient();
  const fetchSamples = async () => {
    setLoading(true);
    try {
      const samples = await client.listSampleByLab(
        labId,
        pageNumber,
        pageSize,
        currentPanel,
        sortModel[0]?.field,
        sortModel[0]?.sort,
        searchQuery,
      );
      setLoading(false);

      setRows(samples?.results);
      setTotalEntries(samples?.totalCount ?? 0);
    } catch (error) {
      console.error(error);
      setError('Unable to fetch samples');
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchSamples();
  }, [labId, pageNumber, pageSize, sortModel, currentPanel, searchQuery, trigger]);

  return {
    response: {
      data: rows,
      error,
      loading,
    },
    selectionModel,
    setSelectionModel,
    sortModel,
    setSortModel,
    pageSize,
    setPageSize,
    pageNumber,
    setPageNumber,
    totalEntries,
    setTotalEntries,
    clearRows: () => setRows([]),
    fetchRows: fetchSamples,
  };
};

export default useListSample;
