import React, { useEffect, useState } from 'react';
import AdminMenu from '../../../components/menus/AdminMenu';
import { Lab, useApiClient, User, UserLevel, UserPayload } from '../../../api';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useParams } from 'react-router-dom';
import { Formik, setNestedObjectValues, useFormikContext } from 'formik';
import { isEmpty } from 'ramda';
import { LoadingActions, State } from '../../../hooks/useLoading';
import { FlsCard } from '../../../components/card';
import { DebouncedTextField, FlsSelect } from '../../../components/inputs';
import { FlsLabel } from '../../../components/util';
import { MenuItem, Typography } from '@material-ui/core';
import { FramoButtonWithLoading } from '../../../components/buttons';
import * as Yup from 'yup';
import { useLoading } from '../../../hooks';
import { useAuthContext } from '../../../contexts';

const useStyles = makeStyles((theme) => ({
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  actionBar: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(2),
  },
  childOverride: {
    height: '100%',
  },
  inputFields: {
    padding: theme.spacing(0, 2),
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    width: '100%',

    '& > *': {
      flex: '1 0 21%',
      marginRight: theme.spacing(5),
    },
  },
  form: {
    marginTop: theme.spacing(1),
  },
}));

const ValidationSchema = Yup.object().shape({
  firstName: Yup.string().required('Required').label('First Name'),
  lastName: Yup.string().required('Required').label('Last Name'),
  email: Yup.string().required('Required').email().label('Email'),
  labId: Yup.string().required('Required').label('Lab'),
  userLevel: Yup.string().required('Required').label('User Level'),
});

const AdminUsersAddPage = () => {
  const [password, setPassword] = useState<string>();
  const client = useApiClient();
  const { state: stateOnSave, dispatch: dispatchOnSave } = useLoading();
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      <AdminMenu />
      <div className={classes.center}>
        <Formik
          initialValues={{
            userId: undefined,
            firstName: '',
            lastName: '',
            email: '',
            labId: '',
            userLevel: UserLevel.User,
          }}
          validationSchema={ValidationSchema}
          onSubmit={async (values: UserPayload) => {
            if (values.userId) {
              try {
                dispatchOnSave({ type: 'REQUEST' });
                await client.updateUser(values);
                dispatchOnSave({ type: 'SUCCESS', toast: 'Values updated' });
              } catch (error) {
                const errorJson = await error.json();
                dispatchOnSave({
                  type: 'ERROR',
                  error: errorJson?.message || 'Something went wrong',
                  toast: errorJson?.message || 'Failed to insert values to database.',
                });
              }
            } else {
              try {
                dispatchOnSave({ type: 'REQUEST' });
                const result = await client.addUser(values);
                dispatchOnSave({ type: 'SUCCESS' });
                history.push('/admin/users/add/' + (result as any).userId);
                setPassword((result as any).password);
              } catch (error) {
                const errorJson = await error.json();
                dispatchOnSave({
                  type: 'ERROR',
                  error: errorJson?.message || 'Something went wrong',
                  toast: errorJson?.message || 'Failed to insert values to database.',
                });
              }
            }
          }}
        >
          <InnerAddUser stateOnSave={stateOnSave} dispatchOnSave={dispatchOnSave} />
        </Formik>
      </div>
      <div className={classes.center}>
        {password && <Typography>Generated password: {password}</Typography>}
      </div>
    </>
  );
};

interface InnerAddLabProps {
  stateOnSave: State<unknown>;
  dispatchOnSave: React.Dispatch<LoadingActions<unknown>>;
}

const InnerAddUser = (props: InnerAddLabProps) => {
  const { isGlobalAdmin } = useAuthContext();
  const formik = useFormikContext<UserPayload>();
  const [user, setUser] = useState<User>();
  const [allLabs, setAllLabs] = useState<Lab[]>();
  const { urlUserId } = useParams<{ urlUserId: string }>();
  const client = useApiClient();
  const classes = useStyles();

  useEffect(() => {
    const fetchAllLabs = async () => {
      const labs = await client.getLabs();
      setAllLabs(labs);
    };

    fetchAllLabs();
  }, []);

  useEffect(() => {
    const fetchUser = async () => {
      if (urlUserId) {
        try {
          const user = await client.getUser(urlUserId);
          setUser(user);
        } catch (e) {
          const errorJson = await e.json();
          props.dispatchOnSave({
            type: 'ERROR',
            error: 'Error',
            toast: errorJson.message,
          });
        }
      }
    };

    fetchUser();
  }, [urlUserId]);

  React.useEffect(() => {
    if (user) {
      Object.entries(user).forEach(([key, value]) => {
        if (formik.values.hasOwnProperty(key)) {
          formik.setFieldValue(key, value);
        } else if (key === 'lab') {
          formik.setFieldValue('labId', value?.id);
        }
      });
    }
  }, [user]);

  const GetAvailableUserLevels = () => {
    if (isGlobalAdmin()) {
      return Object.keys(UserLevel);
    }
    return Object.keys(UserLevel).filter((x) => x !== 'GlobalAdmin');
  };

  const handleSubmit = async (ev: any) => {
    props.dispatchOnSave({ type: 'REQUEST' });
    const errors = await formik.validateForm();
    if (isEmpty(errors)) {
      formik.handleSubmit(ev);
    } else {
      console.error(errors);
      formik.setErrors(errors);
      formik.setTouched(setNestedObjectValues(errors, true));
      props.dispatchOnSave({
        type: 'ERROR',
        error: 'Validation Error',
        toast: 'Some fields have failed validation',
      });
    }
  };

  return (
    <div>
      <FlsCard overrideChildStyle={classes.childOverride} showDivider header='Lab'>
        <form className={classes.form} onSubmit={handleSubmit}>
          <div className={classes.inputFields}>
            <DebouncedTextField
              fullWidth
              displayHelper
              value={formik.values.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id={'firstName'}
              placeholder='First Name'
              helperText={formik.touched.firstName && formik.errors.firstName}
            />
          </div>
          <div className={classes.inputFields}>
            <DebouncedTextField
              fullWidth
              displayHelper
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id={'lastName'}
              placeholder='Last Name'
              helperText={formik.touched.lastName && formik.errors.lastName}
            />
          </div>
          <div className={classes.inputFields}>
            <DebouncedTextField
              fullWidth
              displayHelper
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id={'email'}
              placeholder='Email'
              helperText={formik.touched.email && formik.errors.email}
            />
          </div>
          <div className={classes.inputFields}>
            <FlsLabel htmlFor={'labId'} textTransform='none'>
              LAB
            </FlsLabel>
            <FlsSelect
              fullWidth
              value={formik.values.labId || ''}
              onChange={(a) => {
                formik.setFieldValue('labId', a.target.value);
              }}
              id={'labId'}
              helperText={formik.touched.labId && formik.errors.labId}
            >
              {!allLabs ? (
                <MenuItem disabled>No options</MenuItem>
              ) : (
                allLabs?.map((lab) => (
                  <MenuItem key={lab.id} value={lab.id}>
                    {lab.labName}
                  </MenuItem>
                ))
              )}
            </FlsSelect>
          </div>
          <div className={classes.inputFields}>
            <FlsLabel htmlFor={'userLevel'} textTransform='none'>
              USER LEVEL
            </FlsLabel>
            <FlsSelect
              fullWidth
              value={formik.values.userLevel || ''}
              onChange={(a) => {
                formik.setFieldValue('userLevel', a.target.value);
              }}
              id={'userLevel'}
              helperText={formik.touched.userLevel && formik.errors.userLevel}
            >
              {GetAvailableUserLevels().map((level) => (
                <MenuItem key={level} value={level}>
                  {level}
                </MenuItem>
              ))}
            </FlsSelect>
          </div>
        </form>
      </FlsCard>
      <div className={classes.actionBar}>
        <FramoButtonWithLoading
          buttonSize='sizeNormal'
          buttonStyle='styleFilled'
          type='button'
          handleClick={handleSubmit}
          submissionState={props.stateOnSave?.state}
        >
          {formik.values.userId ? 'Save' : 'Register'}
        </FramoButtonWithLoading>
      </div>
    </div>
  );
};

export default AdminUsersAddPage;
