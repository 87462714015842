import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, IconButton, makeStyles } from '@material-ui/core';
import Field from './Field';
import {
  FormField,
  FormFieldValueBuilder,
  FormGroup,
  Record,
  RecordValue,
  RetrievedRecord,
  RetrievedRecordValueDecimal,
} from '../../../api';
import {
  calculateFieldValue,
  classifyInputValue,
  createRecord,
  findFormField,
  getValueFromRecord,
  getValueFromRetrievedRecord,
} from '../../../utils/formUtils';
import { isEmpty } from 'ramda';
import { VisibilityOffOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  formGroup: {
    display: (props: FormGroupStyleProps) => (props.hidden ? 'none' : 'flex'),
    flexWrap: 'nowrap',
    visibility: (props: FormGroupStyleProps) => (props.hidden ? 'hidden' : 'visible'),
    marginBottom: theme.spacing(1),
  },
  closeicon: {
    display: 'block',
    paddingTop: '16px',
    '& > *': {
      '&:hover': {
        color: theme.palette.button.highlight,
      },
    },
  },
}));

interface FormGroupStyleProps {
  hidden?: boolean;
}

interface FormGroupProps {
  formGroup: FormGroup;
  formId: string;
  sampleId: string;
  classifyWith: string;
  currentClassifierId?: string;
  setClassifyWith: React.Dispatch<React.SetStateAction<string>>;
  setRecords: React.Dispatch<React.SetStateAction<Record[]>>;
  retrievedRecord: RetrievedRecord | undefined;
  changeGroupVisibility: (groupName: string, hide?: boolean) => void;
  hidden?: boolean;
  closeable?: boolean;
  setDidFormChange?: (value: boolean) => void;
  disabled?: boolean;
  valueChanged?: (formField: FormField, value: any) => void;
  formFieldValueBuilders?: FormFieldValueBuilder[];
  resultValues?: any;
  important?: boolean;
}

const Group = ({
  formGroup,
  formId,
  sampleId,
  setRecords,
  currentClassifierId,
  classifyWith,
  setClassifyWith,
  retrievedRecord,
  changeGroupVisibility,
  hidden,
  closeable,
  setDidFormChange,
  disabled,
  valueChanged,
  formFieldValueBuilders,
  resultValues,
  important,
}: FormGroupProps) => {
  const [recordValues, setRecordValues] = useState<RecordValue[]>([]);
  //If a field is dirty the validation need to be checked. The whole formgroup is revalidated
  const [isDirty, setIsDirty] = useState(false);
  const classes = useStyles({ hidden });
  const currentClassifier = useRef<string>('');
  const initialValuesSet = useRef<boolean>(false);

  /**
   * Handles changes in a field by updating the fields current record and notifying any observers of the change.
   * The value sent to a observer is first classified if any active classifiers are accossiated with the observer field.
   * If a field is defined as a classifierSelector. This function changes the active classifier for the whole form.
   */
  const fieldChanged = useCallback(
    (discrimintator: string, formField: FormField, value: any, existingValue: boolean) => {
      if (setDidFormChange) {
        setDidFormChange(!existingValue);
      }

      if (valueChanged) {
        valueChanged(formField, value);
      }

      const newRecord = createRecord(discrimintator, formField.id, value, existingValue);
      //Broadcasts the new value to all observers
      if (formField.observers.length > 0) {
        formField.observers
          .sort((a, b) => {
            return a.sortOrder - b.sortOrder;
          })
          .forEach((observer) => {
            const observerField = findFormField(observer.observerFieldId, formGroup);
            if (!observerField || observerField.canHaveValidationRules) return;

            const classifiedValue = classifyInputValue(observerField, value, classifyWith);
            fieldChanged(
              observerField.formFieldType.formFieldDataType.discriminator,
              observerField,
              classifiedValue,
              existingValue,
            );
          });
      }

      // A form may contain a field which decides which classifier a all other formfield shall use.
      if (formField.isClassifierSelector) {
        setClassifyWith(value);
      }

      setRecordValues((currentValues) => {
        if (!newRecord) {
          return currentValues;
        }
        const debug = Object.assign([], {
          ...currentValues,
          [formField.id]: newRecord,
        });
        return debug;
      });
    },
    [formGroup, classifyWith, setClassifyWith, formFieldValueBuilders],
  );

  useEffect(() => {
    formGroup.formFields.forEach((formField) => {
      if (formFieldValueBuilders?.some((builder) => builder.formFieldId === formField.id)) {
        fieldChanged(
          formField.formFieldType.formFieldDataType.discriminator,
          formField,
          null,
          true,
        );
      }
    });
  }, [formGroup, formFieldValueBuilders]);

  const calculateFieldValues = useCallback(
    (resultValues: any) => {
      Object.keys(resultValues).forEach((key) => {
        const formField = findFormField(key, formGroup);

        if (formField) {
          formField.observers
            .sort((a, b) => {
              return a.sortOrder - b.sortOrder;
            })
            .forEach((observer) => {
              const observerField = findFormField(observer.observerFieldId, formGroup);
              if (observerField && observerField.canHaveValidationRules) {
                const formFieldValueBuilder = formFieldValueBuilders?.find(
                  (builder) => builder.formFieldId === observerField.id,
                );

                if (formFieldValueBuilder) {
                  const value = calculateFieldValue(resultValues, formFieldValueBuilder);
                  const discriminator = observerField.formFieldType.formFieldDataType.discriminator;
                  const newRecord = createRecord(discriminator, formField.id, value, false);

                  if (
                    resultValues[observerField.id] === value ||
                    newRecord?.DecimalValue === resultValues[observerField.id]
                  )
                    return;
                  fieldChanged(discriminator, observerField, value, false);
                }
              }
            });
        }
      });
    },
    [formFieldValueBuilders, formGroup, classifyWith],
  );

  useEffect(() => {
    calculateFieldValues(resultValues);
  }, [resultValues]);

  /**
   * Hides the form group, remove any entries, and removes the record from submission
   */
  const closeGroupAndRemoveRecord = () => {
    changeGroupVisibility(formGroup.id, true);
    setRecordValues([]);
    setRecords((records) => {
      delete records[formGroup.id];
      return records;
    });
    setIsDirty(true);
    if (setDidFormChange) {
      setDidFormChange(true);
    }
    formGroup.formFields.forEach((x) => {
      if (valueChanged) {
        valueChanged(x, '');
      }
    });
  };

  /**
   * If a formfield has any previous records associated with it, the initial value is set to the last recordvalue.
   * If the field is suppose to be hidden, this effect will reveal the field.
   */
  useEffect(() => {
    if (retrievedRecord && !isEmpty(retrievedRecord) && !initialValuesSet.current) {
      retrievedRecord.recordValues.forEach((recordValue) => {
        const formField = findFormField(recordValue.formFieldId, formGroup);
        if (!formField) return;
        if (hidden) {
          changeGroupVisibility(formGroup.id, false);
        }

        const value = getValueFromRetrievedRecord(recordValue);
        const filteredRecords = retrievedRecord.recordValues.filter((record) => {
          return formGroup.formFields.some(
            (field) => field.canHaveValidationRules && field.id === record.formFieldId,
          );
        });

        const allDecimalNull =
          filteredRecords.length > 0 &&
          filteredRecords.every((record) => {
            if ((record as RetrievedRecordValueDecimal).decimalValue !== undefined) {
              return (record as RetrievedRecordValueDecimal).decimalValue === null;
            }
            return false;
          });

        if (allDecimalNull) {
          changeGroupVisibility(formGroup.id, true);
        }

        fieldChanged(recordValue.discriminator, formField, value, true);
      });
      initialValuesSet.current = true;
    }
  }, [retrievedRecord, fieldChanged, formGroup, changeGroupVisibility, hidden]);

  /**
   * If the value used of the classifier changes, all fields must be updated.
   */
  useEffect(() => {
    if (currentClassifier.current !== classifyWith) {
      Object.entries(recordValues).forEach(([_, recordValue]) => {
        const value = getValueFromRecord(recordValue);
        const formfield = findFormField(recordValue.FormFieldId, formGroup);
        if (!formfield) return;
        fieldChanged(recordValue.Discriminator, formfield, value, recordValue.RetrievedValue);
      });
      currentClassifier.current = classifyWith;
    }
  }, [classifyWith, fieldChanged, recordValues, formGroup]);

  /**
   * Updates the records which are submitted in the analysesPage. This is done without making
   * a copy of the array to avoid unecessary rerenders.
   */
  useEffect(() => {
    setRecords((records) => {
      records[formGroup.id] = {
        formId,
        flsSampleId: sampleId,
        recordValues: Object.values(recordValues),
      };
      return records;
    });
  }, [recordValues, formId, setRecords, sampleId, formGroup.id]);

  return !formGroup.formFields.find((x) => x.label === 'Class') ||
    formGroup.formFields.find(
      (x) =>
        x.isClassifierSelector || x.classifiers.find((x) => x.classifierId === currentClassifierId),
    ) ? (
    <Box className={classes.formGroup}>
      {formGroup.formFields
        .filter((x) => {
          if (
            x.filterOnlyOnUniqueClassifier &&
            x.uniqueForClassifierId &&
            x.uniqueForClassifierId !== currentClassifierId
          ) {
            return (
              x.isClassifierSelector ||
              !x.classifiers.some((c) => c.classifierId !== currentClassifierId)
            );
          }

          if (x.uniqueForFormGroupId && x.uniqueForFormGroupId) {
            return (
              x.uniqueForFormGroupId === formGroup.id &&
              x.uniqueForClassifierId === currentClassifierId
            );
          }

          return (
            x.isClassifierSelector ||
            !x.classifiers.some((c) => c.classifierId !== currentClassifierId)
          );
        })
        .map((formField) => (
          <Field
            key={formField.id}
            value={recordValues[formField.id]}
            formField={formField}
            fieldChanged={fieldChanged}
            isDirty={isDirty}
            setIsDirty={setIsDirty}
            currentClassifierId={currentClassifierId}
            disabled={disabled}
            important={important}
          />
        ))}
      {closeable && !disabled && (
        <div className={classes.closeicon}>
          <IconButton onClick={() => closeGroupAndRemoveRecord()} tabIndex={-1}>
            <VisibilityOffOutlined />
          </IconButton>
        </div>
      )}
    </Box>
  ) : (
    <></>
  );
};

export default Group;
