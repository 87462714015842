import { CircularProgress, Divider, makeStyles, Theme, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { SelectVessel } from '.';
import { useSampleContext, useVesselContext } from '../../contexts';
import { FramoAction, FramoButton } from '../buttons';
import { isEmpty } from 'ramda';
import { FlsCard } from '../card';
import { useParams } from 'react-router-dom';
import { useApiClient, LatestOilTypeResponse } from '../../api';
import { useLoading } from '../../hooks';
import { addYears } from 'date-fns';
import dateFormat from '../../utils/dateFormat';
import HelperIcon from '../../components/util/HelperIcon';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
    minHeight: '233px',
  },
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& > *': {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      '&:first-child': {
        marginTop: theme.spacing(4),
      },
      '&:last-child': {
        marginBottom: theme.spacing(4),
      },
    },
  },
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(3),
    '& > *': {
      height: '24px',
      '&: first-child': {
        flexBasis: 'start',
      },
    },
  },
  dividerStyle: {
    margin: theme.spacing(0, 2),
  },
  vesselContent: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(1),
    '& > *': {
      '&:first-child': {
        marginTop: theme.spacing(2),
      },
      '&:last-child': {
        marginBottom: theme.spacing(1),
      },
    },
  },
  status: {
    fontSize: 12,
    fontWeight: 300,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: theme.spacing(3),
  },
  button: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& > *': {
      '&:first-child': {
        marginTop: theme.spacing(2),
      },
      '&:last-child': {
        marginBottom: theme.spacing(2),
      },
    },
  },
}));

interface VesselBoxProps {
  openModal: () => void;
  setVesselOverwrite: React.Dispatch<React.SetStateAction<boolean>>;
  disabled?: boolean;
}

const VesselBox = (props: VesselBoxProps) => {
  const { disabled } = props;
  const classes = useStyles();
  const { vessel, removeSelectedVessel, setVessel } = useVesselContext();
  const { sample } = useSampleContext();
  const client = useApiClient();
  const { urlSampleId } = useParams<{ urlSampleId: string }>();
  const { state, dispatch } = useLoading();
  const [oilTestStatus, setOilTestStatus] = useState<LatestOilTypeResponse>();

  React.useEffect(() => {
    async function getVesselOnSample() {
      if (urlSampleId && sample?.installationId) {
        dispatch({ type: 'REQUEST' });
        try {
          const sampleVessel = await client.getVessel(sample.installationId);
          setVessel(sampleVessel);
        } catch (error) {
          dispatch({
            type: 'ERROR',
            error,
            toast: 'Unable to retrieve previously registered vessel.',
          });
          console.error(error);
        }
      }
    }
    getVesselOnSample();
  }, [sample]);

  useEffect(() => {
    async function fetchLatestOilType() {
      const from = addYears(new Date(), -2);
      const to = addYears(new Date(), 1);
      const latestStatus = await client.getLatestOilStatus(
        vessel.orderNo,
        dateFormat.formatUrlDate(from),
        dateFormat.formatUrlDate(to),
      );

      if (latestStatus.orderNo === vessel.orderNo) {
        setOilTestStatus(latestStatus);
      }
    }

    if (!isEmpty(vessel)) {
      dispatch({ type: 'SUCCESS' });
      fetchLatestOilType();
    }
  }, [vessel]);

  const changeVessel = () => {
    removeSelectedVessel();
    props.setVesselOverwrite(true);
  };

  return (
    <FlsCard className={classes.root} variant='normal'>
      <div className={classes.header}>
        <Typography variant='body2'>Ship Record</Typography>
        {!isEmpty(vessel) && !disabled && (
          <FramoAction colorVariant='secondary' handleClick={changeVessel}>
            Clear
          </FramoAction>
        )}
      </div>
      <Divider className={classes.dividerStyle} variant='fullWidth' />
      {state.state === 'pending' ? (
        <div className={classes.content}>
          <CircularProgress size={40} />
          <Typography variant='body2'>Retrieving registered vessel....</Typography>
        </div>
      ) : isEmpty(vessel) ? (
        <div className={classes.content}>
          <SelectVessel colorVariant={'primary'} disabled={disabled} />
        </div>
      ) : (
        <div>
          <div className={classes.vesselContent}>
            <Typography variant='body2'>{vessel.name}</Typography>
          </div>
          <div className={classes.status}>
            {oilTestStatus?.sampleStatus && oilTestStatus.sampleStatus !== 'Normal' && (
              <>
                <HelperIcon severity={oilTestStatus.sampleStatus} />
                Last test results were {oilTestStatus.sampleStatus.toLowerCase()}
              </>
            )}
          </div>
          <div className={classes.button}>
            <FramoButton type='button' buttonStyle='styleOutlined' handleClick={props.openModal}>
              Info and Calendar
            </FramoButton>
          </div>
        </div>
      )}
    </FlsCard>
  );
};

export default VesselBox;
