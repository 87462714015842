import { CircularProgress, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { FramoButton } from '../../../components/buttons';
import { FlsChip } from '../../../components/inputs';
import { useListForms } from '../../../hooks';
import { ChipData } from '../../../models/components';

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

interface SetTestsProps {
  currentForms: string[];
  handleChipChange: (chips: ChipData[]) => void;
  className?: string;
  updateInvoiceType?: (testType: string) => void;
  showPresets?: boolean;
  disabled?: boolean;
}

const SetTests = (props: SetTestsProps) => {
  const { currentForms, handleChipChange, updateInvoiceType, showPresets, disabled } = props;
  const [chipData, setChipData] = useState<ChipData[]>([]);
  const { listResponse } = useListForms();
  const classes = useStyles();

  React.useEffect(() => {
    if (listResponse) {
      const newChipData = listResponse
        .sort((form1, form2) => form1.sortOrder - form2.sortOrder)
        .map((value, index) => {
          return {
            key: Number(index),
            id: (value as any).id,
            label: (value as any).analysisType,
            clicked: currentForms.includes((value as any).id),
            isMandatory: value.isMandatory,
          };
        });
      setChipData(newChipData);
      handleChipChange(newChipData);
    }
  }, [listResponse, currentForms]);

  const pressetSet = (presetValue: string) => {
    let testsToSet: string[] = [];
    if (presetValue === 'L1') {
      testsToSet = ['Water Content', 'Particles'];
    } else if (presetValue === 'L2') {
      testsToSet = ['Water Content', 'Particles', 'Chemical Composition', 'Ruler'];
    }
    const newChips = chipData.map((value, index) => {
      return {
        key: Number(index),
        id: value.id,
        label: value.label,
        clicked: testsToSet.includes(value.label) ? true : false,
        isMandatory: value.isMandatory,
      };
    });
    if (updateInvoiceType) {
      updateInvoiceType(presetValue);
    }
    setChipData(newChips);
    handleChipChange(newChips);
  };

  return (
    <div>
      {showPresets && (
        <div className={classes.buttonContainer}>
          <FramoButton
            disabled={!listResponse || disabled}
            buttonStyle='styleOutlined'
            handleClick={() => pressetSet('L1')}
            buttonSize='sizeMini'
          >
            L1
          </FramoButton>
          <FramoButton
            disabled={!listResponse || disabled}
            buttonStyle='styleOutlined'
            handleClick={() => pressetSet('L2')}
            buttonSize='sizeMini'
          >
            L2
          </FramoButton>
        </div>
      )}
      {listResponse && chipData ? (
        <FlsChip
          className={props.className}
          chipData={chipData.filter((x) => !x.isMandatory)}
          setChipData={(chips) => {
            setChipData(chips);
            handleChipChange(chips);
          }}
          disabled={disabled}
        />
      ) : (
        <CircularProgress size={24} />
      )}
    </div>
  );
};
export default SetTests;
