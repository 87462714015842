import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Theme, makeStyles } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { ReactComponent as FramoLogo } from '../../assets/logo/framo.svg';
import { useAuthContext } from '../../contexts';
import { FramoAction, FramoIcon } from '../buttons';
import { IconHandler } from '../util';
import { useLocation } from 'react-router';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    margin: theme.spacing(2, 2, 0),
  },
  appBar: {
    borderRadius: '6px',
    boxShadow: '4px 4px 12px rgba(0, 0, 0, 0.32)',
  },
  linkWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
  svgWrapper: {
    width: '92px',
  },
  toolbarContent: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  rightButtons: {
    display: 'flex',
    gap: '15px',
  },
  adminPanelButton: {
    marginTop: '4px',
  },
}));

export interface NavigationButton {
  text: string;
  route: string;
}

export default function FlsHeader() {
  const [headerNavigationButton, setHeaderNavigationButton] = useState<NavigationButton>({
    text: '',
    route: '',
  });
  const classes = useStyles();
  const { logout, isLocalOrGlobalAdmin } = useAuthContext();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.startsWith('/admin')) {
      setHeaderNavigationButton({ route: '/', text: 'Dashboard' });
    } else {
      setHeaderNavigationButton({ route: '/admin', text: 'Admin Panel' });
    }
  }, [location.pathname]);

  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar} position='static'>
        <Toolbar>
          <div className={classes.toolbarContent}>
            <Link to={'/'} className={classes.linkWrapper}>
              <FramoLogo className={classes.svgWrapper} />
            </Link>
            <div className={classes.rightButtons}>
              {isLocalOrGlobalAdmin() && (
                <div className={classes.adminPanelButton}>
                  <FramoAction
                    colorVariant='secondary'
                    handleClick={() => history.push(headerNavigationButton.route)}
                  >
                    {headerNavigationButton.text}
                  </FramoAction>
                </div>
              )}
              <FramoIcon handleClick={logout}>
                <IconHandler iconIdentifier='logout' />
              </FramoIcon>
            </div>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}
