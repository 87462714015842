import React, { useEffect, useState } from 'react';
import AdminMenu from '../../../components/menus/AdminMenu';
import FlsDataGrid from '../../../components/datagrid/FlsDataGrid';
import { DatagridToolbar, Loadingbar } from '../../../components/datagrid';
import { ClassThreshold, Classifier, useApiClient } from '../../../api';
import { makeStyles } from '@material-ui/core/styles';
import { GridColDef } from '@material-ui/data-grid';
import { FlsLabel } from '../../../components/util';
import { FlsSelect } from '../../../components/inputs';
import { MenuItem } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  classTresholdsGridContainer: {},
  classTresholdsGrid: {
    margin: theme.spacing(1, 4),
  },
}));

const columns: GridColDef[] = [
  {
    field: 'id',
    type: 'string',
    headerName: 'ID',
    flex: 1,
    filterable: false,
    hide: true,
    sortable: false,
  },
  {
    field: 'firstCompareValue',
    type: 'string',
    headerName: 'First Compare Value',
    flex: 1,
    filterable: false,
    sortable: false,
  },
  {
    field: 'secondCompareValue',
    type: 'string',
    headerName: 'Second Compare Value',
    flex: 1,
    filterable: false,
    sortable: false,
  },
  {
    field: 'replaceValue',
    type: 'string',
    headerName: 'Replace Value',
    flex: 1,
    filterable: false,
    sortable: false,
  },
  {
    field: 'label',
    type: 'string',
    headerName: 'Label',
    flex: 1,
    filterable: false,
    sortable: false,
  },
  {
    field: 'classifierId',
    type: 'string',
    headerName: 'Classifier Id',
    flex: 1,
    filterable: false,
    hide: true,
    sortable: false,
  },
];

const AdminClassThresholdsPage = () => {
  const [classifiers, setClassifiers] = useState<Classifier[]>([]);
  const [classThresholds, setClassThresholds] = useState<ClassThreshold[]>([]);
  const [selectedClassifierId, setSelectedClassifierId] = useState<string | undefined>('All');
  const [filteredClassThresholds, setFilteredClassThresholds] = useState<ClassThreshold[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>();
  const classes = useStyles();
  const client = useApiClient();
  const [columnsButtonEl, setColumnsButtonEl] = React.useState(null);

  useEffect(() => {
    const fetchClassThresholds = async () => {
      setLoading(true);

      try {
        const classThresholds = await client.getClassThresholds();
        setClassThresholds(classThresholds);
      } catch (error) {
        console.error(error);
        setError('Unable to classThresholds');
      }

      setLoading(false);
    };

    fetchClassThresholds();
  }, []);

  useEffect(() => {
    const fetchClassifiers = async () => {
      setLoading(true);

      try {
        const classifiers = await client.getClassifiers();
        setClassifiers(classifiers);
      } catch (error) {
        console.error(error);
        setError('Unable to fetch classifiers');
      }

      setLoading(false);
    };

    fetchClassifiers();
  }, []);

  function customSort(a, b) {
    function getPartBeforeHyphen(str) {
      const match = str.match(/(.*?)-(\d+)/);
      return match !== null ? match[1] : str;
    }

    function getSecondSymbolAsNumber(str) {
      const match = str.match(/-(\d+)/);
      return match ? parseInt(match[1]) : 0;
    }

    const partBeforeHyphenA = getPartBeforeHyphen(a.label);
    const partBeforeHyphenB = getPartBeforeHyphen(b.label);

    const numericValueA = Number(partBeforeHyphenA);
    const numericValueB = Number(partBeforeHyphenB);

    if (
      numericValueA &&
      numericValueB &&
      numericValueA.toString() === a.label &&
      numericValueB.toString() === b.label
    ) {
      if (numericValueA < numericValueB) {
        return -1;
      }
      if (numericValueA > numericValueB) {
        return 1;
      }

      return 0;
    }

    if (partBeforeHyphenA < partBeforeHyphenB) {
      return -1;
    }
    if (partBeforeHyphenA > partBeforeHyphenB) {
      return 1;
    }

    const numericA = getSecondSymbolAsNumber(a.label);
    const numericB = getSecondSymbolAsNumber(b.label);

    if (numericA < numericB) {
      return -1;
    }
    if (numericA > numericB) {
      return 1;
    }

    return 0;
  }

  useEffect(() => {
    if (selectedClassifierId === undefined || selectedClassifierId === 'All') {
      setFilteredClassThresholds(classThresholds);
    } else {
      const filteredData = classThresholds.filter(
        (threshold) => threshold.classifierId === selectedClassifierId,
      );
      setFilteredClassThresholds(filteredData);
    }
  }, [selectedClassifierId, classThresholds]);

  const handleClassifierChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedClassifierId(event.target.value as string);
  };

  return (
    <>
      <AdminMenu />
      <div className={classes.classTresholdsGrid}>
        <FlsLabel htmlFor={'selectedClassifierId'} textTransform='none'>
          Classifier
        </FlsLabel>
        <FlsSelect
          isLight
          size='normal'
          id='selectedClassifierId'
          value={selectedClassifierId || ''}
          onChange={handleClassifierChange}
        >
          <MenuItem value='All'>All</MenuItem>
          {Array.from(new Set(classThresholds.map((threshold) => threshold.classifierId))).map(
            (id) => (
              <MenuItem key={id} value={id}>
                {classifiers.find((classifier) => classifier.id === id)?.name}
              </MenuItem>
            ),
          )}
        </FlsSelect>
      </div>
      <div className={classes.classTresholdsGrid}>
        <FlsDataGrid
          rows={filteredClassThresholds ? [...filteredClassThresholds].sort(customSort) : []}
          getRowId={(row) => row.id}
          columns={columns}
          loading={loading}
          error={error}
          disableSelectionOnClick
          components={{
            Toolbar: DatagridToolbar,
            LoadingOverlay: Loadingbar,
          }}
          componentsProps={{
            toolbar: {
              addDisabled: true,
              setColumnsButtonEl: setColumnsButtonEl,
            },
            panel: {
              anchorEl: columnsButtonEl,
            },
          }}
        />
      </div>
    </>
  );
};

export default AdminClassThresholdsPage;
