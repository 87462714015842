import React, { PropsWithChildren, useEffect, useState } from 'react';
import { MsalAuthenticationResult, MsalAuthenticationTemplate, useMsal } from '@azure/msal-react';
import { MsalEventMessage } from '../models';
import { Environment } from '../utils';
import { EventType, InteractionType } from '@azure/msal-browser';
import { msalScopes } from '../MsalConfiguration';
import SplashScreen from '../SplashScreen';
import ErrorPage from '../Error';
import { ChildrenOnlyProps } from '../models/Utils';

const MsalErrorCodes = Object.freeze({
  ResetPassword: 'AADB2C90118',
  ResetPasswordCanceled: 'AADB2C90091',
});

const isErrorCode = (message: MsalEventMessage, errorCode: string) =>
  message.eventType === EventType.LOGIN_FAILURE &&
  message.interactionType === InteractionType.Redirect &&
  message.error?.errorMessage?.includes(errorCode);

const AuthError = ({ error }: PropsWithChildren<MsalAuthenticationResult>) => (
  <ErrorPage message={error?.message} />
);

// FIX ERROR ->
const AuthenticationGuard = ({ children }: ChildrenOnlyProps) => {
  const { instance } = useMsal();

  const [redirecting, setRedirecting] = useState(false);

  useEffect(() => {
    globalThis.msalInstance = instance;
  }, [instance]);

  useEffect(() => {
    const callbackId = instance.addEventCallback((message: MsalEventMessage) => {
      if (isErrorCode(message, MsalErrorCodes.ResetPassword)) {
        instance.loginRedirect({
          authority: Environment.REACT_APP_PASSWORD_RESET_POLICY,
          scopes: msalScopes,
        });
        setRedirecting(true);
      } else if (isErrorCode(message, MsalErrorCodes.ResetPasswordCanceled)) {
        instance.loginRedirect({ scopes: msalScopes });
        setRedirecting(true);
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [instance]);

  // prevent error message from displaying before navigation is complete
  if (redirecting) return <SplashScreen />;

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={{ scopes: msalScopes }}
      loadingComponent={SplashScreen}
      errorComponent={AuthError}
    >
      {children}
    </MsalAuthenticationTemplate>
  );
};

export default AuthenticationGuard;
