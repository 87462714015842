import React, { PropsWithChildren, useRef } from 'react';
import { Theme, makeStyles, Typography } from '@material-ui/core';
import { FramoButton } from '.';
import { FramoButtonProps } from './FramoButton';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},

  sizeFitLabel: {
    padding: theme.spacing(1),
  },
  sizeFitBox: {
    width: '100%',
  },
  sizeMini: {
    height: theme.spacing(5),
  },
  sizeNormal: {
    height: theme.spacing(6),
  },
  input: {
    display: 'none',
  },
}));

interface FramoUploadButtonProps extends FramoButtonProps {
  fileAccept: string;
  multipleFiles?: boolean;
  handleChange: (e: React.ChangeEvent<any>) => void;
  files: File[];
}

const FramoUploadButton = (props: PropsWithChildren<FramoUploadButtonProps>) => {
  const { fileAccept, multipleFiles, children, files, handleChange, ...rest } = props;
  const classes = useStyles(props);
  const uploadInput = useRef<HTMLInputElement>(null);
  const triggerUpload = (ev) => {
    if (uploadInput && uploadInput.current) {
      uploadInput.current.click();
    }
    if (props.handleClick) {
      props.handleClick(ev);
    }
  };

  return (
    <>
      <input
        className={classes.input}
        id='contained-button-file'
        accept={fileAccept}
        multiple={multipleFiles}
        onChange={props.handleChange}
        type='file'
        ref={uploadInput}
      />
      <FramoButton handleClick={triggerUpload} {...rest}>
        {children}
      </FramoButton>
      <Typography variant='subtitle1'>
        {files && files.map((file) => file.name).join(',')}
      </Typography>
    </>
  );
};

export default FramoUploadButton;
