import React from 'react';
import {
  createStyles,
  Typography,
  makeStyles,
  Theme,
  Chip,
  Paper,
  CircularProgress,
} from '@material-ui/core';
import { CheckIconThin, ZoominIcon } from '../../assets/icons';
import { ChipData } from '../../models/components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
      listStyle: 'none',
      margin: 0,
      padding: theme.spacing(2),
      backgroundColor: (props: FlsChipProps) =>
        props.color === 'secondary'
          ? theme.palette.background.default
          : theme.palette.background.paper,
    },
    chip: {
      paddingTop: (props: FlsChipProps) =>
        theme.spacing(settings[props.size || 'medium'].chipPadding),
      paddingBottom: (props: FlsChipProps) =>
        theme.spacing(settings[props.size || 'medium'].chipPadding),
      margin: (props: FlsChipProps) => theme.spacing(settings[props.size || 'medium'].chipMargin),
      backgroundColor: theme.palette.misc.header,
      borderRadius: '20px',
      height: 'auto',
      '&:focus': {
        backgroundColor: theme.palette.misc.header,
      },
      '&:hover': {
        backgroundColor: theme.palette.button.highlight,
      },
    },
    icon: {
      height: (props: FlsChipProps) => settings[props.size || 'medium'].iconSize,
      width: (props: FlsChipProps) => settings[props.size || 'medium'].iconSize,
    },
    label: {
      marginRight: (props: FlsChipProps) =>
        theme.spacing(settings[props.size || 'medium'].labelSpacing),
      fontSize: (props: FlsChipProps) => settings[props.size || 'medium'].fontSize,
    },
    labelContainer: {
      display: 'flex',
      paddingLeft: theme.spacing(1),
    },
    chipSelected: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.background.paper,
      '&:focus': {
        backgroundColor: theme.palette.primary.main,
      },
      '&:hover': {
        backgroundColor: theme.palette.button.highlight,
      },
    },
    iconContainer: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
    },
  }),
);

const settings = {
  small: {
    labelSpacing: 0.3,
    chipMargin: 0.5,
    chipPadding: 0.5,
    iconSize: '20px',
    fontSize: '0.875rem',
  },
  medium: {
    chipMargin: 0.5,
    labelSpacing: 2,
    chipPadding: 1,
    iconSize: '24px',
    fontSize: '1rem',
  },
};

interface FlsChipProps {
  chipData: ChipData[];
  setChipData: (chips: ChipData[]) => any;
  size?: 'small' | 'medium';
  color?: 'primary' | 'secondary';
  loading?: boolean;
  className?: string;
  disabled?: boolean;
}

export default function FlsChip({
  chipData,
  setChipData,
  size,
  color,
  loading,
  className,
  disabled,
}: FlsChipProps) {
  const classes = useStyles({ size, color } as FlsChipProps);

  const handleClick = (id: string, currentValue: boolean) => {
    const copy = [...chipData];
    const index = copy.findIndex((chip) => chip.id === id);
    copy[index].clicked = !currentValue;
    setChipData(copy);
  };

  return (
    <Paper component='ul' className={`${classes.root} ${className}`}>
      {loading ? (
        <CircularProgress size={24} />
      ) : (
        chipData.map((data, key) => {
          let icon;

          if (data.clicked) {
            icon = <CheckIconThin className={classes.icon} />;
          } else {
            icon = <ZoominIcon className={classes.icon} />;
          }

          return (
            <li key={key}>
              <Chip
                label={
                  <div className={classes.labelContainer}>
                    <Typography variant='body2' className={classes.label}>
                      {data.label}
                    </Typography>{' '}
                    <div className={classes.iconContainer}>{icon}</div>
                  </div>
                }
                className={`${classes.chip} ${data.clicked ? classes.chipSelected : ''}`}
                onClick={() => handleClick(data.id, data.clicked)}
                disabled={disabled}
              />
            </li>
          );
        })
      )}
    </Paper>
  );
}
