import React, { useEffect, useState } from 'react';
import { Container, Grid, makeStyles } from '@material-ui/core';
import {
  useSampleContext,
  useFormContext,
  useModalContext,
  useVesselContext,
} from '../../contexts';
import { FramoIconBox, FramoUploadBox } from '../../components/buttons';
import { FlsCard } from '../../components/card';
import { TabMenu } from '../../components/tabmenu';
import { FlsModal } from '../../components/modal';
import {
  DatagridInProgress,
  DatagridArchive,
  WaterTestTable,
  DataGridExternalLab,
} from './datagrids';
import { DASHBOARD_TABLABELS } from '../../constants/labels';
import OrderExternalTests from '../../modals/OrderExternalTests';
import { useLab, useListSample } from '../../hooks';
import { Panel } from '../../hooks/useListSample';

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    height: '400px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  pageContainer: {
    marginTop: theme.spacing(6),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  tabContainer: {
    width: '100%',
    height: '100%',
  },
  uploadPanel: {
    height: '200px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dashboardActions: {
    height: '130px',
    marginBottom: theme.spacing(2),
    '&:first-child': {
      marginRight: theme.spacing(1),
    },
    '&:last-child': {
      marginLeft: theme.spacing(1),
    },
  },
  navBoxContainer: {
    marginTop: theme.spacing(7),
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
    },
    display: 'flex',
    flexDirection: 'row',
  },
  waterTestContainer: {
    height: '100%',
    maxHeight: '100%',
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const { setSampleId, clearSample } = useSampleContext();
  const { setUsingForms } = useFormContext();
  const { isOpen, setIsOpen, setModalProps, setContent } = useModalContext();
  const { removeSelectedVessel } = useVesselContext();
  const { labId } = useLab();

  const [searchQuery, setSearchQuery] = useState('');
  const [currentTab, setCurrentTab] = useState<Panel>('InProgress');
  const [refreshTrigger, setRefreshTrigger] = useState<boolean>(false);
  const sampleDataGrid = useListSample(labId, currentTab, searchQuery, refreshTrigger);

  useEffect(() => {
    sampleDataGrid.clearRows();
  }, [currentTab]);

  const triggerRefresh = () => {
    setRefreshTrigger(!refreshTrigger);
  };

  //Update sample list when closing modal, assuming external lab info has changed
  React.useEffect(() => {
    if (!isOpen) {
      triggerRefresh();
    }
  }, [isOpen]);

  //Empty context if navigating home
  React.useEffect(() => {
    setUsingForms(undefined);
    setSampleId(undefined);
    clearSample();
    removeSelectedVessel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleExternalOrderModal = () => {
    setModalProps({
      title: 'Prepare shipment to external lab',
      titleIcon: undefined,
    });
    setContent(<OrderExternalTests setIsOpen={setIsOpen} />);
    setIsOpen(true);
  };

  return (
    <Container maxWidth={false} className={classes.pageContainer}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={10}>
          <TabMenu
            tabLabels={DASHBOARD_TABLABELS}
            onTabChange={(tab) => setCurrentTab(tab.id as Panel)}
          >
            <div className={classes.tabContainer}>
              <DatagridInProgress
                datagrid={sampleDataGrid}
                onSearchChange={(searchString) => setSearchQuery(searchString)}
                searchQuery={searchQuery}
              />
            </div>
            <div className={classes.tabContainer}>
              <DataGridExternalLab
                datagrid={sampleDataGrid}
                onSearchChange={(searchString) => setSearchQuery(searchString)}
                searchQuery={searchQuery}
              />
            </div>
            <div className={classes.tabContainer}>
              <DatagridArchive
                datagrid={sampleDataGrid}
                onSearchChange={(searchString) => setSearchQuery(searchString)}
                searchQuery={searchQuery}
              />
            </div>
          </TabMenu>
        </Grid>

        <Grid item xs={12} lg={2}>
          <div className={classes.navBoxContainer}>
            <FlsCard className={classes.dashboardActions}>
              <FramoUploadBox
                onSuccess={triggerRefresh}
                fileAccept='.tsv,.xml,.mes'
                multipleFiles
              />
            </FlsCard>
            <FlsCard className={classes.dashboardActions}>
              <FramoIconBox
                buttonText='External Lab'
                handleClick={handleExternalOrderModal}
                iconIdentifier='send'
                tooltip='View prepared samples to external lab'
              />
            </FlsCard>
          </div>
          <WaterTestTable
            fetchSamples={() => {
              return (
                sampleDataGrid.response.data?.filter((sample) =>
                  sampleDataGrid.selectionModel.some((sel) => sel === sample.id),
                ) || []
              );
            }}
            onClear={(rows) => {
              sampleDataGrid.setSelectionModel((prev) =>
                prev.filter((p) => !rows.some((row) => row === p)),
              );
            }}
            onSubmit={() => sampleDataGrid.fetchRows()}
          />
        </Grid>
      </Grid>
      <FlsModal />
    </Container>
  );
};

export default Dashboard;
