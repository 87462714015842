import React, { PropsWithChildren } from 'react';
import { Link, Typography, Theme, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    color: theme.palette.primary.main,
    '&:hover, &:focus': {
      color: theme.palette.button.highlight,
    },
    '&:active': {
      color: theme.palette.button.active,
    },
  },
}));

interface FramoLinkProps {
  location: string;
}

const FramoLink = (props: PropsWithChildren<FramoLinkProps>) => {
  const { location, children } = props;
  const classes = useStyles();
  return (
    <Link
      component='a'
      variant='body2'
      className={classes.root}
      target='_blank'
      href={location}
      rel='noreferrer'
    >
      <Typography component={'span'} variant='body2'>
        {children}
      </Typography>
    </Link>
  );
};

export default FramoLink;
