import { Environment } from '../utils';
import { useAuthContext } from '../../src/contexts';
import { useCallback, useEffect, useMemo, useRef } from 'react';

type QueryParams = Record<string, any>;
type RequestBody = Record<string, any> | Record<string, any>[] | FormData;
type RequestMethod = 'GET' | 'PUT' | 'POST' | 'DELETE' | 'PATCH';
type RequestOptions = Required<Pick<RequestInit, 'method' | 'mode' | 'headers'>> & RequestInit;

const baseUrl = Environment.REACT_APP_API_BASE;

const deleteUndefined = <T extends Record<string, any>>(obj: T): T => {
  const copy = { ...obj };
  for (const key in copy) {
    if (copy[key] === undefined) delete copy[key];
  }
  return copy;
};

const urlWithParams = (url: string, queryParams?: QueryParams) =>
  queryParams ? `${url}?${new URLSearchParams(deleteUndefined(queryParams)).toString()}` : url;

const useHttpClient = () => {
  const { getOrRefreshToken } = useAuthContext();

  const getBearerToken = useCallback(async () => {
    try {
      const token = await getOrRefreshToken();
      return `Bearer ${token.accessToken}`;
    } catch (error) {
      console.log('An error occurred when fetching token in apiclient:', error);
      throw error;
    }
  }, [getOrRefreshToken]);

  const getOptions = useCallback(
    async (method: RequestMethod, body?: RequestBody, sendAsForm?: boolean) => {
      const options: RequestOptions = {
        method,
        mode: 'cors',
        headers: {},
        body: sendAsForm ? (body as FormData) : body ? JSON.stringify(body) : undefined,
      };

      if (method === 'PATCH') {
        options.headers['Content-Type'] = 'application/json-patch+json';
      } else if (body && !sendAsForm) options.headers['Content-Type'] = 'application/json';
      options.headers['Authorization'] = await getBearerToken();

      return options;
    },
    [getBearerToken],
  );

  const request = useCallback(
    async <T>(
      path: string,
      method: RequestMethod,
      queryParams?: QueryParams,
      body?: RequestBody,
      sendAsForm?: boolean,
      options?: RequestInit,
    ): Promise<T> => {
      const url = urlWithParams(baseUrl + path, queryParams);
      const defaultOptions = await getOptions(method, body, sendAsForm);

      const mergedOptions = Object.assign(defaultOptions, options, {});

      const response = await fetch(url, mergedOptions);

      if (!response.ok) {
        throw response;
      }
      const contentType = response.headers.get('content-type');

      if (contentType === 'application/pdf') {
        return response as any;
      }

      if (response.status !== 204 && !contentType?.includes('application/json')) {
        throw new Error(`Unexpected response type ${contentType}`);
      }
      if (response.status === 204) {
        return {} as T;
      }
      return await response.json();
    },
    [getOptions],
  );

  const requestFile = useCallback(
    async (
      path: string,
      method: RequestMethod,
      queryParams?: QueryParams,
      body?: RequestBody,
      sendAsForm?: boolean,
    ): Promise<Blob | undefined> => {
      const url = urlWithParams(baseUrl + path, queryParams);
      const options = await getOptions(method, body, sendAsForm);

      const response = await fetch(url, options);

      if (!response.ok) throw response;
      if (response.status === 204) {
        return undefined;
      }
      return await response.blob();
    },
    [getOptions],
  );

  const client = useMemo(
    () => ({
      get: <T>(path: string, queryParams?: QueryParams, options?: RequestInit) =>
        request<T>(path, 'GET', queryParams, undefined, false, options),
      post: <T>(
        path: string,
        body: RequestBody,
        queryParams?: QueryParams,
        sendAsForm?: boolean,
        options?: RequestInit,
      ) => request<T>(path, 'POST', queryParams, body, sendAsForm, options),
      put: <T>(path: string, body: RequestBody, queryParams?: QueryParams, options?: RequestInit) =>
        request<T>(path, 'PUT', queryParams, body, false, options),
      patch: <T>(
        path: string,
        body: RequestBody,
        queryParams?: QueryParams,
        options?: RequestInit,
      ) => request<T>(path, 'PATCH', queryParams, body, false, options),
      delete: <T>(path: string, queryParams?: QueryParams, options?: RequestInit) =>
        request<T>(path, 'DELETE', queryParams, undefined, false, options),
      downloadFile: (
        path: string,
        queryParams?: QueryParams,
        body?: RequestBody,
        sendAsForm?: boolean,
        options?: RequestInit,
      ) => requestFile(path, 'GET', queryParams, body, sendAsForm),
    }),
    [request, requestFile],
  );

  // wrap the client in a ref so that dependencies don't have to refresh just because the token refreshed
  const clientRef = useRef(client);

  useEffect(() => {
    clientRef.current = client;
  }, [clientRef, client]);

  return clientRef;
};

export type HttpClient = ReturnType<typeof useHttpClient>;

export default useHttpClient;
