import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Divider, makeStyles, Typography } from '@material-ui/core';
import { useForm, useNewestRecordsByForm } from '../../../hooks';
import {
  FormField,
  FormFieldValueBuilder,
  FormGroup,
  FormResultBuilder,
  Record,
} from '../../../api';
import { FlsAutocomplete } from '../../../components/inputs';
import Group from './Group';
import ParticleResultLine from './ParticleResultLine';
import { shouldHideGroup } from '../../../utils';

const useStyles = makeStyles((theme) => ({
  autocompleteContainer: {
    paddingTop: theme.spacing(2),
  },
  dividerContainer: {
    marginBottom: theme.spacing(2),
  },
  formBox: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '6px',
    boxShadow: '4px 4px 12px rgba(0, 0, 0, 0.32)',
    display: 'inline-block',
    height: 'fit-content',
    padding: theme.spacing(2),
    width: '100%',
  },
  headerContainer: {
    padding: theme.spacing(2, 0),
  },
  replicateableFieldsContainer: {
    display: 'flex',
  },
  spinnerContainer: {
    alignItems: 'center',
    display: 'flex',
    height: '200px',
    justifyContent: 'center',
  },
}));

interface FormGroupStatus {
  hidden: boolean | undefined;
  closeable: boolean | undefined;
  important: boolean | undefined;
  group: FormGroup;
}

interface FormProps {
  formId: string;
  sampleId: string;
  setRecords: React.Dispatch<React.SetStateAction<Record[]>>;
  updateRecordLoading?: (key: string, value: boolean) => void;
  setDidFormChange?: (value: boolean) => void;
  disabled?: boolean;
  formResultBuilders?: FormResultBuilder[];
  formFieldValueBuilders?: FormFieldValueBuilder[];
}

const Form = (props: FormProps) => {
  const {
    formId,
    sampleId,
    setRecords,
    updateRecordLoading,
    setDidFormChange,
    disabled,
    formResultBuilders,
    formFieldValueBuilders,
  } = props;
  const [form, , fetchStatus] = useForm(formId);
  const [formGroups, setFormGroups] = useState<FormGroupStatus[]>([]);
  const [existHiddenFields, setExistHiddenFields] = useState(false);
  const [autoCompleteInputValue, setAutoCompleteInputValue] = useState('');
  const [resultValues, setResultValues] = useState<any>({});
  //In a perfect world we only need 1 of these, using id as the value and name as display.
  const [classifyWith, setClassifyWith] = useState(''); //This is the value from the classifier field. It gets updated when the field changes.
  const [currentClassifierId, setCurrentClassifierId] = useState<string | undefined>(); //This is the actual classifier Id in the database that listens on changes to classifyWith.
  const classes = useStyles();
  const { record: retrievedRecord, loading } = useNewestRecordsByForm(form?.id, sampleId);

  useEffect(() => {
    if (updateRecordLoading) {
      updateRecordLoading(formId, loading);
    }
  }, [loading]);

  useEffect(() => {
    if (updateRecordLoading) {
      updateRecordLoading(`${formId}formLoading`, form === undefined);
    }
  }, [form]);
  /**
   * Reveals or hides a formgroup
   * @param groupName The name of the form group
   * @param hide Whether to hide the field or not
   */
  const changeGroupVisibility = (id: string, hide?: boolean) => {
    for (let index = 0; index < formGroups.length; index++) {
      if (formGroups[index].group.id === id) {
        setFormGroups((groups) => {
          groups[index].hidden = !!hide;
          return [...groups];
        });
        setAutoCompleteInputValue('');
        break;
      }
    }
  };

  /**
   * When recieving a form from the back-end this useEffect runs and sets the status
   * of each formGroup to hidden or visible. These states are later modified by the "showHiddenField"
   * function. If there exist any hidden fields the "existHiddenFields" state is set to true
   * and autcomplete component is shown. Here the user may choose to reveal formgroups
   */
  useEffect(() => {
    if (form) {
      const fieldsWithStatus = new Array<FormGroupStatus>();
      form.formGroups.forEach((formGroup, i) => {
        fieldsWithStatus[i] = {
          group: formGroup,
          hidden:
            formGroup.hidden &&
            shouldHideGroup(formGroup.showOnClassifiers, currentClassifierId) &&
            !resultValues[formGroup.formFields[0].id],
          important: formGroup.importantForClassifiers.includes(
            currentClassifierId?.toUpperCase() ?? '',
          ),
          closeable: formGroup.closeable,
        };
        if (!existHiddenFields && formGroup.hidden) {
          setExistHiddenFields(true);
        }
      });
      setFormGroups(fieldsWithStatus);
    }
  }, [form, existHiddenFields, currentClassifierId]);

  useEffect(() => {
    const formFields = formGroups.map((x) => x.group.formFields).flat(1);

    const currentClassifier = formFields
      .filter((x) => x.classifiers)
      .map((x) => x.classifiers)
      .flat(1)
      .find((x) => x.classifier.name === classifyWith);

    setCurrentClassifierId(currentClassifier?.classifierId);
  }, [formGroups, classifyWith]);

  const onFieldUpdate = (formField: FormField, value: any) => {
    setResultValues((val) => ({ ...val, [formField.id]: value }));
  };

  const getFormResultBuilderForSelectedClassifier = () => {
    return formResultBuilders?.find((x) => x.classifierId === currentClassifierId);
  };

  const formResultBuilder = getFormResultBuilderForSelectedClassifier();

  return (
    <Box className={classes.formBox}>
      {fetchStatus === 'pending' ? (
        <div className={classes.spinnerContainer}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className={classes.headerContainer}>
            <Typography>{form?.analysisType}</Typography>
          </div>
          <Divider className={classes.dividerContainer} variant='fullWidth' />
          {formResultBuilder && (
            <ParticleResultLine resultValues={resultValues} formResultBuilder={formResultBuilder} />
          )}
          {formGroups.map((formGroup) => (
            <Group
              valueChanged={onFieldUpdate}
              setDidFormChange={setDidFormChange}
              key={formGroup.group.id}
              formId={formId}
              sampleId={sampleId}
              formGroup={formGroup.group}
              setRecords={setRecords}
              classifyWith={classifyWith}
              setClassifyWith={setClassifyWith}
              retrievedRecord={retrievedRecord}
              changeGroupVisibility={changeGroupVisibility}
              hidden={formGroup.hidden}
              closeable={formGroup.closeable}
              currentClassifierId={currentClassifierId}
              disabled={disabled}
              formFieldValueBuilders={formFieldValueBuilders}
              resultValues={resultValues}
              important={formGroup.important}
            />
          ))}
          {existHiddenFields && (
            <div className={classes.autocompleteContainer}>
              <FlsAutocomplete
                placeholder={'Add more'}
                value={null}
                fullWidth
                inputValue={autoCompleteInputValue}
                onInputChange={(e, value) => setAutoCompleteInputValue(value)}
                cancelOption={() => undefined}
                options={
                  formGroups
                    .filter(
                      (formField) =>
                        formField.hidden &&
                        formField.group.formFields.some(
                          (x) => x.classifiers[0]?.classifierId === currentClassifierId,
                        ),
                    )
                    .map((formGroup) => ({
                      ...formGroup,
                      group: {
                        ...formGroup.group,
                        formFields: formGroup.group.formFields.filter(
                          (x) => x.classifiers[0]?.classifierId === currentClassifierId,
                        ),
                      },
                    })) || []
                }
                getOptionLabel={(data: FormGroupStatus) => data.group.name}
                onChange={(e, value: FormGroupStatus) => changeGroupVisibility(value.group.id)}
                disabled={disabled}
              />
            </div>
          )}
        </>
      )}
    </Box>
  );
};

export default Form;
