import React from 'react';
import { CircularProgress, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  container: { height: '50%', gap: '10rem' },
});

const SplashScreen = () => (
  <Grid
    container
    alignItems='center'
    justifyContent='center'
    direction='column'
    className={useStyles().container}
  >
    <CircularProgress color='secondary' size={80} />
  </Grid>
);

export default SplashScreen;
