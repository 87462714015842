import React, { useEffect, useState } from 'react';
import AdminMenu from '../../../components/menus/AdminMenu';
import FlsDataGrid from '../../../components/datagrid/FlsDataGrid';
import { DatagridToolbar, Loadingbar } from '../../../components/datagrid';
import { Classifier, useApiClient } from '../../../api';
import { makeStyles } from '@material-ui/core/styles';
import { GridColDef } from '@material-ui/data-grid';
import ClassifiersRowActionButtons from './ClassifiersRowActionButtons';

const useStyles = makeStyles((theme) => ({
  classifiersGridContainer: {},
  classifiersGrid: {
    margin: theme.spacing(1, 4),
  },
}));

const columns: GridColDef[] = [
  {
    field: 'id',
    type: 'string',
    headerName: 'ID',
    flex: 1,
    filterable: false,
    hide: true,
    sortable: false,
  },
  {
    field: 'name',
    type: 'string',
    headerName: 'Name',
    flex: 0.5,
    filterable: false,
    sortable: false,
  },
  {
    field: 'comparisonType',
    type: 'string',
    headerName: 'Comparison Type',
    flex: 0.5,
    filterable: false,
    sortable: false,
  },
  {
    field: 'fieldsComputed',
    type: 'boolean',
    headerName: 'Fields Computed',
    flex: 0.5,
    filterable: false,
    sortable: false,
  },
  {
    field: 'sortOrder',
    type: 'number',
    headerName: 'Sort Order',
    flex: 0.5,
    filterable: false,
    sortable: false,
  },
  {
    field: '',
    headerName: 'Actions',
    sortable: false,
    renderCell: ClassifiersRowActionButtons,
    editable: false,
    width: 115,
    resizable: false,
    filterable: false,
    disableColumnMenu: true,
    disableReorder: true,
    hideSortIcons: true,
  },
];

const AdminClassifiersPage = () => {
  const [classifiers, setClassifiers] = useState<Classifier[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>();
  const client = useApiClient();
  const classes = useStyles();
  const [columnsButtonEl, setColumnsButtonEl] = React.useState(null);

  useEffect(() => {
    const fetchClassifiers = async () => {
      setLoading(true);

      try {
        const classifiers = await client.getClassifiers();
        const sortedClassifiers = classifiers.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1));
        setClassifiers(sortedClassifiers);
      } catch (error) {
        console.error(error);
        setError('Unable to fetch labs');
      }

      setLoading(false);
    };

    fetchClassifiers();
  }, []);

  return (
    <>
      <AdminMenu />
      <div className={classes.classifiersGridContainer}>
        <div className={classes.classifiersGrid}>
          <FlsDataGrid
            rows={classifiers ? classifiers : []}
            getRowId={(row) => row.id}
            columns={columns}
            loading={loading}
            error={error}
            disableSelectionOnClick
            components={{
              Toolbar: DatagridToolbar,
              LoadingOverlay: Loadingbar,
            }}
            componentsProps={{
              toolbar: {
                addDisabled: true,
                setColumnsButtonEl: setColumnsButtonEl,
              },
              panel: {
                anchorEl: columnsButtonEl,
              },
            }}
          />
        </div>
      </div>
    </>
  );
};

export default AdminClassifiersPage;
