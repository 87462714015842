import React, { forwardRef } from 'react';

interface FlsGridItemProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  resizeGridItem: (item: any) => void;
}
const FlsGridItemCore = (props: FlsGridItemProps, ref) => {
  return (
    <div ref={ref} className={props.className}>
      <div className='content'>{props.children}</div>
    </div>
  );
};

const FlsGridItem = forwardRef<HTMLDivElement, FlsGridItemProps>(FlsGridItemCore);
export default FlsGridItem;
