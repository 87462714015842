export const WATER_RECORD = {
  formId: '4F616516-57F0-47DB-A4BE-F206CFDB4B8B',
  formFieldId: 'E8AAA817-EE6A-4E47-803B-120A010EAB37',
  discriminator: 'RecordValueInteger',
};

//TODO: This is now "hardcodet"
//Later we need to remove this and store the correct lab on each user in Azure b2c.
export const FNL_CONSTANT = { id: '5989AF07-882A-41C9-B31B-2B0C25C9323B', name: 'FNL' };

export const INPUT_DELAY = 300;
