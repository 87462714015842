import React, { useState } from 'react';
import { FramoButton, FramoLink } from '../components/buttons';
import { makeStyles } from '@material-ui/core';
import { Sample } from '../api';
import { SampleInformation } from '.';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  navButtons: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
    '& > *': {
      width: '95px',
      display: 'flex',
      justifyContent: 'center',
    },
  },
}));

interface SampleLinksProps {
  selectedSample: Sample;
}
const SampleLinks = (props: SampleLinksProps) => {
  const { selectedSample } = props;
  const classes = useStyles();
  const [hideInfo, setHideInfo] = useState<boolean>(true);
  return (
    <div className={classes.root}>
      <div className={classes.navButtons}>
        <div>
          <FramoLink
            location={`http://webapp/omp/ProArc/Oilmon.aspx${
              selectedSample?.ompDocRno ? '?DocRno=' + selectedSample.ompDocRno : ''
            }`}
          >
            OMP
          </FramoLink>
        </div>
        <div>
          <FramoLink
            location={`http://webapp/referenceList/default3.aspx${
              selectedSample?.installationId ? '?SalesNo=' + selectedSample.installationId : ''
            }`}
          >
            Ship Record
          </FramoLink>
        </div>
        <div>
          <FramoLink
            location={`https://mypage.framo.com/omp${
              selectedSample?.imoNumber ? '/' + selectedSample.imoNumber : ''
            }`}
          >
            MyPage
          </FramoLink>
        </div>
      </div>
      <FramoButton handleClick={() => setHideInfo(!hideInfo)} buttonStyle='styleOutlined'>
        {hideInfo ? 'Show Sample Metadata' : 'Hide Sample Metadata'}{' '}
      </FramoButton>
      {!hideInfo && <SampleInformation selectedSample={selectedSample} />}
    </div>
  );
};

export default SampleLinks;
