import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from '@material-ui/core';
import { ClassNameMap, withStyles } from '@material-ui/styles';
import React, { PropsWithChildren } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  tableHeader: {
    padding: theme.spacing(2),
  },
}));
const StyledHeaderRow = withStyles((theme: Theme) => ({
  head: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
  },
}))(TableRow);

const StyledPaper = withStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.table,
    margin: theme.spacing(2),
  },
}))(Paper);

interface FlsTableProps {
  headers: string[];
  title?: string;
  styles?: ClassNameMap;
}

export default function FlsTable(props: PropsWithChildren<FlsTableProps>) {
  const classes = useStyles();
  return (
    <StyledPaper>
      <div className={classes.tableHeader}>{props.title}</div>
      <TableContainer classes={{ root: props.styles?.root }}>
        <Table className={props.styles?.table} stickyHeader aria-label='sticky table'>
          <TableHead>
            <StyledHeaderRow className={props.styles?.tableHeader}>
              {props.headers.map((header, index) => {
                return <TableCell key={index}>{header}</TableCell>;
              })}
            </StyledHeaderRow>
          </TableHead>
          <TableBody>{props.children}</TableBody>
        </Table>
      </TableContainer>
    </StyledPaper>
  );
}
