import React from 'react';
import { Checkbox, makeStyles, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { iconStyles } from '../../theme/icons';

const useCheckboxStyles = makeStyles((theme: Theme) => ({
  root: {},
  wrapper: {
    width: '100%',
    cursor: 'pointer',
    transition: 'border-color 0.3s 0s linear',
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },

    display: 'flex',
    alignItems: 'flex-start',
    backgroundColor: theme.palette.background.input,
    border: '1px solid transparent',
    padding: theme.spacing(1),
    borderRadius: '6px',
    marginBottom: theme.spacing(1),
    borderColor: (props: any) => (props.checked ? theme.palette.primary.main : 'transparent'),
  },
  icon: {
    borderRadius: '50%',
    backgroundColor: 'transparent',
  },
  checkedIcon: {
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function CheckboxCard({ checked, label, description, onChange }) {
  const classes = useCheckboxStyles({ checked });
  const iconBaseClasses = iconStyles();
  return (
    <div className={classes.wrapper} onClick={onChange}>
      <Checkbox
        className={classes.root}
        checked={checked}
        onChange={onChange}
        name={label}
        checkedIcon={
          <span
            className={clsx(
              iconBaseClasses.checkedIcon,
              iconBaseClasses.icon,
              classes.icon,
              classes.checkedIcon,
            )}
          />
        }
        icon={<span className={clsx(iconBaseClasses.icon, classes.icon)} />}
      />
      <div>
        <Typography variant='body2'>{label}</Typography>
        <Typography variant='subtitle1'>{description}</Typography>
      </div>
    </div>
  );
}
