import { Environment, getOrigin } from '../src/utils';
import { PublicClientApplication, Configuration } from '@azure/msal-browser';

const origin = getOrigin();

const msalConfiguration: Configuration = {
  auth: {
    clientId: Environment.REACT_APP_B2C_CLIENT_ID,
    authority: Environment.REACT_APP_B2C_AUTHORITY,
    redirectUri: origin,
    postLogoutRedirectUri: origin,
    knownAuthorities: [Environment.REACT_APP_B2C_AUTHORITY],
    navigateToLoginRequestUrl: true,
  },
};
const msalInstance = new PublicClientApplication(msalConfiguration);

const msalScopes = ['offline_access', Environment.REACT_APP_B2C_CLIENT_ID];

export { msalConfiguration, msalInstance, msalScopes };
