import React from 'react';
import { GridCellParams } from '@material-ui/data-grid';
import { StatusCell } from '.';
import { FormStatus, ProcessStatus } from '../../api';

export default function ProcessStatusCell(params: GridCellParams) {
  const processStatus = params.value as ProcessStatus;

  const getFormStatus = (): FormStatus => {
    switch (processStatus) {
      case 'SentToExternalTest':
        return 'Marginal';
      default:
        return 'Empty';
    }
  };

  const getFormStatusLabel = (): string => {
    switch (processStatus) {
      case 'SentToExternalTest':
        return 'Sent To External Lab';
      case 'InProgress':
        return 'In progress';
      default:
        return processStatus;
    }
  };
  return <StatusCell label={getFormStatusLabel()} status={getFormStatus()} showIcon={false} />;
}
