import React from 'react';
import { FormControlLabel, Checkbox, makeStyles } from '@material-ui/core/';

import clsx from 'clsx';
import { iconStyles } from '../../theme/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {},
  checkedIcon: {},
}));

interface FlsCheckBoxProps {
  checked?: boolean;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
  name?: string;
  labelPlacement?: string;
  value?: any;
}

/**
 * This component is used as a child of the the material-ui/core FormGroup.
 * To control the input, pass an eventhandler and a checked value to the checkboxes.
 * To target the correct checkbox with handlechange, a value or name should also be added.
 */
const FlsCheckbox = (props: FlsCheckBoxProps) => {
  const { checked, handleChange, name, label, value } = props;
  const classes = useStyles(props);
  const iconBaseClasses = iconStyles();

  return (
    <FormControlLabel
      control={
        <Checkbox
          className={classes.root}
          checked={checked}
          checkedIcon={
            <span
              className={clsx(
                iconBaseClasses.checkedIcon,
                iconBaseClasses.icon,
                classes.icon,
                classes.checkedIcon,
              )}
            />
          }
          icon={<span className={clsx(iconBaseClasses.icon, classes.icon)} />}
          onChange={handleChange}
          name={name}
        />
      }
      label={label}
      value={value}
    />
  );
};

export default FlsCheckbox;
