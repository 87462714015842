import { useHistory } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core';
import { FramoButton } from '../buttons';
import React from 'react';
import { useAuthContext } from '../../contexts';

const useStyles = makeStyles((theme: Theme) => ({
  adminButtons: {
    display: 'flex',
    margin: '20px',
    gap: '5px',
  },
}));

const AdminMenu = () => {
  const classes = useStyles();
  const history = useHistory();
  const { isGlobalAdmin } = useAuthContext();
  return (
    <div className={classes.adminButtons}>
      <FramoButton handleClick={() => history.push(`/admin/users`)}>Users</FramoButton>
      {isGlobalAdmin() && (
        <FramoButton handleClick={() => history.push(`/admin/labs`)}>Labs</FramoButton>
      )}
      {isGlobalAdmin() && (
        <FramoButton handleClick={() => history.push(`/admin/classifiers`)}>
          Classifiers
        </FramoButton>
      )}
      {isGlobalAdmin() && (
        <FramoButton handleClick={() => history.push(`/admin/classThresholds`)}>
          Class Thresholds
        </FramoButton>
      )}
      {isGlobalAdmin() && (
        <FramoButton handleClick={() => history.push(`/admin/formFieldValidationRules`)}>
          Validation Rules
        </FramoButton>
      )}
      {isGlobalAdmin() && (
        <FramoButton handleClick={() => history.push(`/admin/formFieldPreValues`)}>
          Preconfigured Values
        </FramoButton>
      )}
      {isGlobalAdmin() && (
        <FramoButton handleClick={() => history.push(`/admin/externalLabs`)}>
          External Labs
        </FramoButton>
      )}
    </div>
  );
};

export default AdminMenu;
