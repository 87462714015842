import React, { PropsWithChildren } from 'react';
import { Tabs, Theme, makeStyles } from '@material-ui/core';
import { TabPanel, TabWithCounter } from '.';
import { DashboardTabId } from '../../constants/labels';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    height: theme.spacing(7),
  },
  menu: {
    height: theme.spacing(7),
    display: 'flex',
    justifyContent: 'space-between',
  },
  root: {
    backgroundColor: theme.palette.background.default,
    width: '100%',
    height: '100%',
  },
  tab: {
    ...theme.typography.body2,
    backgroundColor: theme.palette.background.paper,
    width: theme.spacing(39),
    borderTopLeftRadius: '6px',
    borderTopRightRadius: '6px',
    marginRight: theme.spacing(2),
    textTransform: 'none',
  },
  tabIndicator: {
    display: 'none',
  },
  tabLabelPlacement: {
    alignItems: 'flex-start',
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
}));

export interface FlsTab {
  label: string;
  id: DashboardTabId;
}

interface Props {
  tabLabels: FlsTab[];
  onTabChange?: (tab: FlsTab) => void;
}

const TabMenu = (props: PropsWithChildren<Props>) => {
  const { tabLabels, children } = props;
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent, newValue: number) => {
    setValue(newValue);
    if (props.onTabChange) {
      props.onTabChange(tabLabels[newValue]);
    }
  };

  if (React.Children.count(children) !== tabLabels.length) {
    throw Error('Number of labels and children must correspond');
  }

  return (
    <div className={classes.root}>
      <div className={classes.menu}>
        <Tabs
          classes={{ indicator: classes.tabIndicator }}
          value={value}
          onChange={handleChange}
          aria-label='full width tabs example'
        >
          {tabLabels.map((tab, index) => (
            <TabWithCounter
              tabLabel={tab.label}
              tabId={tab.id}
              classes={{
                wrapper: classes.tabLabelPlacement,
                root: classes.button,
              }}
              className={classes.tab}
              id={`tab-${tab.id}`}
              key={`tab-${tab.id}-${index}`}
            />
          ))}
        </Tabs>
      </div>
      {React.Children.map(children, (child, index) => {
        if (React.isValidElement(child)) {
          return (
            <TabPanel index={index} currentTab={value}>
              {child}
            </TabPanel>
          );
        }
      })}
    </div>
  );
};

export default TabMenu;
